import React, { useState, useEffect } from 'react';
import MediaEditor from '../../organisms/media-editor/media-editor';
import './_style.scss';

const MediaEditPage = () => {
  const [mediaId, setMediaId] = useState();
  const [returnParameters, setReturnParameters] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMediaId(params.get('id'));
    const returnUrl = params.get('returnUrl');
    const mediaStructureId = params.get('mediaStructureId');
    setReturnParameters({ returnUrl, mediaStructureId });
  }, []);
  return (
    <div className="MediaEditPageContainer" tabIndex={0} aria-label="Edit media page">
      <MediaEditor mediaId={mediaId} returnParameters={returnParameters} />
    </div>
  );
};

export default MediaEditPage;
