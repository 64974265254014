import React from 'react';
import SitesList from '../../organisms/sites-list/sites-list';
import './_styles.scss';

const SitesListPage = () => (
  <div className="SitesListContainer">
    <SitesList />
  </div>
);

export default SitesListPage;
