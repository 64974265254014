import React from 'react';
import { Router } from '../router/router';
import { UserProvider } from '../../services/providers/user-context';
import { MediaUploadProvider } from '../../services/providers/media-upload-context';
import { ViewportProvider } from '../../services/providers/view-port-context';
import { SiteProvider } from '../../services/providers/site-context';
import { EditModeProvider } from '../../services/providers/edit-mode-context';

import '../../App.less';

const Layout = () => (
  <ViewportProvider>
    <SiteProvider>
      <UserProvider>
        <MediaUploadProvider>
          <EditModeProvider>
            <div className="App">
              <Router />
            </div>
          </EditModeProvider>
        </MediaUploadProvider>
      </UserProvider>
    </SiteProvider>
  </ViewportProvider>
);

export default Layout;
