import React from 'react';
import moment from 'moment';
import { FolderOutlined } from '@ant-design/icons';
import { createGenericColumn } from '../../../utils/table-headers-utils';

export const myPlaylistsColumns = [
  {
    ...createGenericColumn('Title'),
    render: (fileName) => (
      <div aria-label={fileName} tabIndex={0}>
        <FolderOutlined />
        {` ${fileName}`}
      </div>
    ),
    width: '50%',
  },
  createGenericColumn('Owner'),
  {
    ...createGenericColumn('Last modified'),
    render: (timestamp) => moment(timestamp).format('MMM D, YYYY'),
    defaultSortOrder: 'ascend',
  },
];

export const sharedWithMeColumns = [
  {
    ...createGenericColumn('Title'),
    render: (fileName) => (
      <div aria-label={fileName} tabIndex={0}>
        <FolderOutlined />
        {` ${fileName}`}
      </div>
    ),
    width: '50%',
  },
  createGenericColumn('Owner'),
  {
    ...createGenericColumn('Last modified'),
    render: (timestamp) => moment(timestamp).format('MMM D, YYYY'),
    defaultSortOrder: 'ascend',
  },
];
