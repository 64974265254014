import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';
import ButtonPrimary from '../../atoms/button-primary/button-primary';

import './_style.scss';

const { Title } = Typography;

const MediaFeedHeader = ({ title, ButtonAction, ButtonText, ButtonIcon }) => (
  <div className="MediaFeedHeaderContainer">
    <div className="Title">
      <Title level={2}>{title}</Title>
    </div>
    <div className="Actions">
      <ButtonPrimary
        type="primary"
        theme="round"
        htmlType="button"
        size="large"
        onClick={ButtonAction}
        iconLeft={ButtonIcon}
        text={ButtonText}
      />
    </div>
  </div>
);

MediaFeedHeader.propTypes = {
  title: PropTypes.string.isRequired,
  ButtonAction: PropTypes.func,
  ButtonText: PropTypes.string,
  ButtonIcon: PropTypes.node,
};

MediaFeedHeader.defaultProps = {
  ButtonAction: () => {},
  ButtonText: undefined,
  ButtonIcon: undefined,
};

export default MediaFeedHeader;
