import React from 'react';
import './_style.scss';
import LoginForm from '../../organisms/login-form/login-form';

const LandingPage = () => {
  const tempSearch = window.location.search;
  console.log(`search: ${tempSearch}`);
  const params = new URLSearchParams(tempSearch);
  const isLti = params.get('isLti');
  const ownerId = params.get('ownerId');
  const idToken = params.get('id_token');
  const isDeepLink = params.get('isDeepLink');
  const parameters = {
    isLti,
    ownerId,
    idToken,
    isDeepLink,
  };
  return (
    <div className="LandingPageContainer">
      <div className="LoginFromContainer">
        <LoginForm params={parameters} />
      </div>
    </div>
  );
};

export default LandingPage;
