import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { Collapse } from 'antd';
import './_style.scss';

const { Panel } = Collapse;

const GenericCollapse = ({ panels }) => {
  const collapsePanels = panels.map(({ title, key, text }) => (
    <Panel header={title} key={key}>
      <ReactMarkdown>{text}</ReactMarkdown>
    </Panel>
  ));
  return (
    <div className="GenericCollapseContainer">
      <Collapse ghost>{collapsePanels}</Collapse>
    </div>
  );
};

GenericCollapse.propTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.number,
      text: PropTypes.string,
    })
  ).isRequired,
};

GenericCollapse.defaultProps = {};

export default GenericCollapse;
