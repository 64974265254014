import axios from 'axios';
import { HEADERS } from './base';

export const axiosClientMultipart = axios.create({
  timeout: 18000000,
  responseType: 'json',
  headers: {
    [HEADERS.CONTENT_TYPE]: HEADERS.MULTIPART,
  },
});
