import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import { MenuOutlined } from '@ant-design/icons';
import { arrayMoveImmutable } from 'array-move';

import './_style.scss';

const DragHandle = sortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);
const extraSortColumn = {
  title: 'Sort',
  dataIndex: 'sort',
  width: 60,
  className: 'drag-visible',
  render: () => <DragHandle />,
};
const SortableItem = sortableElement((props) => <tr {...props} />);
const SortableContainer = sortableContainer((props) => <tbody {...props} />);

const SortableTable = ({
  columns,
  data,
  onClick,
  loading,
  rowSelection,
  modifyDataSourceOrder,
}) => {
  const TableColumns = [extraSortColumn, ...columns.map((item) => ({ ...item, ellipsis: true }))];
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    const newData = data.map((item, index) => ({ ...item, index }));
    setDataSource(newData);
  }, [data]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable([].concat(dataSource), oldIndex, newIndex).filter(
        (el) => !!el
      );
      modifyDataSourceOrder(newData);
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  // eslint-disable-next-line react/prop-types
  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex((x) => x.index === restProps['data-row-key']);
    return <SortableItem index={index} {...restProps} />;
  };

  return (
    <div className="sortableTableContainer">
      <Table
        loading={loading}
        scroll={{ y: 600 }}
        rowSelection={rowSelection}
        className="genericTable"
        columns={TableColumns}
        dataSource={dataSource}
        pagination={false}
        tableLayout="fixed"
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow,
          },
        }}
        onRow={(record, rowIndex) => ({
          onClick: () => onClick(record, rowIndex),
        })}
      />
    </div>
  );
};

SortableTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object),
  modifyDataSourceOrder: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  rowSelection: PropTypes.shape(PropTypes.object),
};

SortableTable.defaultProps = {
  data: undefined,
  onClick: () => {},
  loading: false,
  rowSelection: undefined,
};

export default SortableTable;
