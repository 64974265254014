import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import GenericTabs from '../../atoms/generic-tabs/generic-tabs';
import { MediaUploadContext } from '../../../services/providers/media-upload-context';
import TabContent from '../tab-content/tab-content';
import { UserContext } from '../../../services/providers/user-context';
import { ALL_UPLOADS, MY_UPLOADS, PENDING_UPLOADS, USER } from '../../../utils/constants-utils';
import { generateKeyFromString, errorLogger } from '../../../utils/generic-utils';

import './_style.scss';

const TABLES_WITH_UPDATE = [generateKeyFromString(PENDING_UPLOADS)];
const TABLES_WITH_POSSIBLE_UPDATE = [
  generateKeyFromString(MY_UPLOADS),
  generateKeyFromString(ALL_UPLOADS),
];
const TABLES_WITHOUT_PAGINATION = [generateKeyFromString(PENDING_UPLOADS)];

const MediaFeed = ({ tabs, isLti }) => {
  const { user } = useContext(UserContext);
  const [selectedTab, setSelectedTab] = useState();
  const [offsetKey, setOffsetKey] = useState();
  const [tabIndex, setTabIndex] = useState(0);
  const [dataSource, setDataSource] = useState();
  const [loading, setLoading] = useState();
  const [gridView, setGridView] = useState();
  const [nameFilter, setNameFilter] = useState();
  const [ownerFilter, setOwnerFilter] = useState();
  const [filterForm, setFilterForm] = useState();
  const [uploadingMediaLastLenght, setUploadingMediaLastLenght] = useState();
  const { uploadingMedia } = useContext(MediaUploadContext);

  useEffect(() => {
    if (selectedTab && TABLES_WITH_UPDATE.includes(selectedTab.key)) {
      setSelectedTab(tabs[tabIndex]);
    }
  }, [tabs]);

  useEffect(async () => {
    if (
      selectedTab &&
      TABLES_WITH_POSSIBLE_UPDATE.includes(selectedTab.key) &&
      uploadingMedia.length < uploadingMediaLastLenght
    ) {
      await new Promise((resolve) => setTimeout(resolve, 2000));
      getTableDataSource(selectedTab, nameFilter, ownerFilter, true, true);
    }
    setUploadingMediaLastLenght(uploadingMedia.length);
  }, [uploadingMedia]);

  useEffect(() => {
    if (
      user.userRole === USER ||
      (selectedTab && selectedTab.key !== generateKeyFromString(ALL_UPLOADS))
    ) {
      setOwnerFilter(user.userId);
    }
    if (selectedTab) getTableDataSource(selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    if (tabs) {
      setSelectedTab(tabs[tabIndex]);
    }
  }, [tabIndex]);

  const getTableDataSource = async (
    { endpoint, processEndpointResult, endpointParameters, key },
    newTitleFilter,
    newOwnerFilter,
    force,
    clearOffsetKeys
  ) => {
    if (offsetKey === -1 && !force) {
      return;
    }
    let olderDataSource = dataSource;
    if (key === generateKeyFromString(PENDING_UPLOADS)) {
      setDataSource([]);
      olderDataSource = undefined;
    }
    let newEndpointParameters =
      offsetKey && offsetKey !== -1
        ? {
            ...endpointParameters,
            offsetKey: JSON.stringify(offsetKey),
            name: newTitleFilter,
            ownerId: newOwnerFilter,
          }
        : { ...endpointParameters, newTitleFilter, newOwnerFilter };
    try {
      setLoading(true);
      if (clearOffsetKeys) {
        setDataSource([]);
        olderDataSource = undefined;
      }
      if (newTitleFilter || newOwnerFilter || force) {
        newEndpointParameters =
          offsetKey && offsetKey !== -1 && !clearOffsetKeys
            ? {
                ...endpointParameters,
                offsetKey: JSON.stringify(offsetKey),
                nameFilter: newTitleFilter,
                ownerId: newOwnerFilter,
              }
            : {
                ...endpointParameters,
                nameFilter: newTitleFilter,
                ownerId: newOwnerFilter,
              };
      }

      const result =
        endpoint instanceof Function ? await endpoint(newEndpointParameters) : endpoint;
      const lastkey = processLastKey(result);
      const processedResult = processEndpointResult(result);
      setOffsetKey(lastkey);
      setDataSource(olderDataSource ? [...olderDataSource, ...processedResult] : processedResult);
    } catch (error) {
      errorLogger({ loggedMessage: 'Media-Feed: Error while getting Table DataSource:', error });
    }
    setLoading(false);
  };

  const processLastKey = (result = {}) => {
    if (!result.data) return undefined;
    return result.data.LastEvaluatedKey ? result.data.LastEvaluatedKey : -1;
  };

  const endScrollFunc = () => {
    if (TABLES_WITHOUT_PAGINATION.includes(selectedTab.key)) return;
    getTableDataSource(selectedTab, nameFilter, ownerFilter, false, false);
  };

  const filterByTitleField = (title, form) => {
    setFilterForm(form);
    // eslint-disable-next-line eqeqeq
    if (title == nameFilter) {
      setNewTitleFilter();
      form.resetFields();
    } else setNewTitleFilter(title);
  };

  const filterByOwnerField = (owner, form) => {
    setFilterForm(form);
    // eslint-disable-next-line eqeqeq
    if (owner == ownerFilter) {
      setNewOwnerFilter();
      form.resetFields();
    } else setNewOwnerFilter(owner);
  };

  const setNewTitleFilter = (filter) => {
    if (user.userRole === USER) {
      setOwnerFilter(user.userId);
    }
    setNameFilter(filter);
    getTableDataSource(selectedTab, filter, ownerFilter, true, true);
  };

  const setNewOwnerFilter = (filter) => {
    let newOwnerFilter = filter;
    if (user.userRole === USER) {
      newOwnerFilter = user.userId;
    }
    setOwnerFilter(newOwnerFilter);
    getTableDataSource(selectedTab, nameFilter, newOwnerFilter, true, true);
  };

  return (
    <div className="MediaFeed">
      {selectedTab && (
        <GenericTabs
          tableLoading={loading}
          onChange={(key) => {
            if (filterForm) filterForm.resetFields();
            setNameFilter();
            setOwnerFilter();
            setOffsetKey();
            setDataSource([]);
            setTabIndex(key);
          }}
          panels={tabs.map(({ name }, key) => ({
            name,
            key,
          }))}
        >
          <TabContent
            dataSource={dataSource}
            loading={loading}
            selectedTab={selectedTab}
            gridView={gridView}
            changeView={(value) => setGridView(value)}
            isLti={isLti}
            endScrollFunc={endScrollFunc}
            filterTitlePlaceholder="filter by title"
            filterOwnerPlaceholder="filter by owner"
            filterByTitleField={filterByTitleField}
            filterByOwnerField={filterByOwnerField}
            isTitleFiltered={nameFilter}
            isOwnerFiltered={ownerFilter}
          />
        </GenericTabs>
      )}
    </div>
  );
};

MediaFeed.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      endpoint: PropTypes.func,
      processEndpointResult: PropTypes.func,
      tableColumns: PropTypes.arrayOf(PropTypes.object),
      selectRowFunc: PropTypes.func,
      name: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  isLti: PropTypes.bool,
};

MediaFeed.defaultProps = { isLti: false };

export default MediaFeed;
