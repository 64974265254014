import { atom, selector } from 'recoil';

export const initialAuthState = {
  profile: '',
  name: '',
  jnjMSUsername: '',
  isAuthenticated: false,
  accessToken: 'unauthenticated',
};

export const userAtom = atom({
  key: 'userAtom',
  default: initialAuthState,
});

export const usernameSelector = selector({
  key: 'userNameSelector',
  get: ({ get }) => {
    const user = get(userAtom);
    return user.name;
  },
});

export const accessTokenSelector = selector({
  key: 'accessTokenSelector',
  get: ({ get }) => {
    const user = get(userAtom);
    return user.accessToken;
  },
});

export const loggedInSelector = selector({
  key: 'loggedInSelector',
  get: ({ get }) => {
    const user = get(userAtom);
    return user.isAuthenticated;
  },
});
