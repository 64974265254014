import React, { useEffect, useState, useContext } from 'react';
import { Typography } from 'antd';
import GenericTable from '../../atoms/generic-table/generic-table';
import { SiteContext } from '../../../services/providers/site-context';
import { UserContext } from '../../../services/providers/user-context';
import { useRedirect } from '../../router/redirect';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import { authzColumns } from '../../molecules/table-headers/authz.table-header';
import {
  ENTER,
  HELP_URL,
  HELP_LTI_URL,
  LANDING_URL,
  LANDING_LTI_URL,
  MEDIA_LTI_URL,
} from '../../../utils/constants-utils';
import { errorLogger } from '../../../utils/generic-utils';

const { Title } = Typography;
const AuthzList = () => {
  const [dataSource, setDataSource] = useState([]);
  const { redirect, setUrlToRedirect } = useRedirect();
  const [tableLoading, setTableLoading] = useState(true);
  const { setSite, site } = useContext(SiteContext);
  const { setUser } = useContext(UserContext);
  const [isLti, setIsLti] = useState();
  const [isDeepLink, setIsDeepLink] = useState();
  const [ownerId, setOwnerId] = useState();
  const [siteId, setSiteId] = useState();
  const [idToken, setIdToken] = useState();
  const [originalSiteState, setOriginalSiteState] = useState();

  const handleEnterClick = (record) => {
    setUser({ accessToken: 'accessToken', userId: record.asuriteId, userRole: record.role });
    setSite({ SiteId: record.key, SiteName: record.site_name });
    // setSite([originalSiteState[0][0]]);
    const ogSiteState = originalSiteState;
    console.log(`ogSiteState: ${JSON.stringify(ogSiteState)}`);
    setSiteId(record.key);
    // setUrlToRedirect(HELP_URL);
    if (isDeepLink === 'true') {
      // on the Rich Content Editor in Canvas, deep linking a video
      // setSiteIdSelected(record.key);
      setUrlToRedirect(`${MEDIA_LTI_URL}`);
      console.log('Redirecting to Media Lti Url');
      // redirect({ siteId: record.key, isDeepLink });
    } else if (isLti === 'true') {
      // on the MediaPlus page in Canvas
      console.log('Redirecting to Home page from LTI');
      // setSiteIdSelected(record.key);
      setUrlToRedirect(HELP_LTI_URL);
      // redirect({ siteId: record.key, isDeepLink: false });
    } else {
      // normal Media Plus website access
      console.log('Redirecting to Home page');
      // setSiteIdSelected(record.key);
      setUrlToRedirect(HELP_URL);
      // redirect({ siteId: record.key, isDeepLink: false });
    }
  };

  const actions = [{ key: ENTER, onClick: handleEnterClick }];
  const tableColumns = [...authzColumns, GenericActions({ actions })];

  useEffect(async () => {
    try {
      setTableLoading(true);
      const params = new URLSearchParams(window.location.search);
      const urlIsLti = params.get('isLti');
      const urlOwnerId = params.get('ownerId');
      const urlIdToken = params.get('idToken');
      const urlIsDeepLink = params.get('isDeepLink');
      setIsLti(urlIsLti);
      setIsDeepLink(urlIsDeepLink);
      setOwnerId(urlOwnerId);
      setIdToken(urlIdToken);
      setOriginalSiteState(site); // save off
      const authzList = site[0];
      const processedAuthzList = authzList.map((authz) => ({
        site_name: authz.SiteName.S,
        key: authz.SiteId.S,
        role: authz.PlatformRole.S,
        asuriteId: authz.AsuriteId.S,
      }));
      setDataSource(processedAuthzList);
      setTableLoading(false);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while loading authz. Redirecting to login.', error });
      if (isLti === 'true') {
        setUrlToRedirect(LANDING_LTI_URL);
      } else {
        setUrlToRedirect(LANDING_URL);
      }
    }
  }, []);
  let renderParams = {};
  if (isDeepLink === 'true') {
    console.log('setting renderParams for isLti');
    renderParams = {
      siteId,
      ownerId,
      id_token: idToken,
      isDeepLink,
      isLti,
    };
  } else if (isLti === 'true') {
    console.log('setting renderParams for isDeepLink');
    renderParams = {
      siteId,
      ownerId,
      id_token: idToken,
      isDeepLink: false,
      isLti,
    };
  } else {
    console.log('setting renderParams for Normal website');
    renderParams = {
      siteId,
      isDeepLink: false,
      isLti,
      ownerId,
    };
  }
  return (
    <div>
      {redirect(renderParams)}
      <div>
        <Title>Sites</Title>
      </div>
      <div>
        <GenericTable columns={tableColumns} data={dataSource} loading={tableLoading} />
      </div>
    </div>
  );
};

export default AuthzList;
