import React from 'react';
import { generateKeyFromString } from './generic-utils';

export const createGenericColumn = (name, ariaLabeled = false) => {
  const key = generateKeyFromString(name);
  return ariaLabeled
    ? {
        render: (record) => (
          <b tabIndex={0} aria-label={record}>
            {record}
          </b>
        ),
        dataIndex: key,
        key,
      }
    : {
        title: <b>{name}</b>,
        dataIndex: key,
        key,
      };
};
