import helpers from './helpers';

const envServerlessApi = process.env.REACT_APP_serverlessApi;
const envCreateSiteApi = process.env.REACT_APP_createSiteApi;
const envDeactivateSiteApi = process.env.REACT_APP_deactivateSiteApi;
const envUpdateSiteApi = process.env.REACT_APP_updateSiteApi;
const envReactivateSiteApi = process.env.REACT_APP_reactivateSiteApi;
const envInitializationSiteApi = process.env.REACT_APP_initializeSiteApi;
const envAuthorizationPutApi = process.env.REACT_APP_authorizationPutApi;
const envAuthorizationQueryApi = process.env.REACT_APP_authorizationQueryApi;
const envAuthorizationDeleteApi = process.env.REACT_APP_authorizationDeleteApi;

// TODO for SSO use
// const envSitesTableName = process.env.REACT_APP_sites_table_name;
// const envAuthorizationTableName = process.env.REACT_APP_authorization_table_name;

const login = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/auth/login`, data);

const getMediaList = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/list`, data);

const getS3UrlToUploadByFilename = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/upload`, data);

const getAssetUploadUrl = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/assets/upload`, data);

const editMedia = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/edit`, data);

const getMediaOnS3ById = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/url`, data);

const getPublicMediaOnS3ByShareId = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/url/public`, data);

const getMediaDetailsById = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/details`, data);

const postFileMetaDataOnDynamo = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media`, data);

const startMultiPartUpload = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/multipart/start`, data);

const completeMultiPartUpload = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/multipart/complete`, data);

const listParts = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/multipart/list`, data);

const abortUpload = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/multipart/abort`, data);

const resumeMultiPart = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/multipart/resume`, data);

const getCollectionsList = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/list`, data);

const getCollectionEligibleMedia = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/getEligibleMedia`, data);

const createCollection = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections`, data);

const getCollectionById = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/getById`, data);

const updateCollection = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/edit`, data);

const deleteCollectionById = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/delete`, data);

const getSitesList = (makeGetRequest) => () => makeGetRequest(`${envServerlessApi}/v1/sites`);

const updateSite = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/sites/update`, data);

// SAM API
const activateSite = (makePostRequest) => (data) =>
  makePostRequest(`${envReactivateSiteApi}?site_id=${data}`, data);

// SAM API
const deactivateSite = (makePostRequest) => (data) =>
  makePostRequest(`${envDeactivateSiteApi}?site_id=${data}`, data);

// SAM API
const createSite = (makePostRequest) => (data) =>
  makePostRequest(`${envCreateSiteApi}?site_name=${data}`, data);

// SAM API
const updateSiteSam = (makePostRequest) => (data) =>
  makePostRequest(`${envUpdateSiteApi}?site_id=${data}`, data);

// SAM API
const initializeSites = (makePostRequest) => () => makePostRequest(`${envInitializationSiteApi}`);

const getPlaylistsList = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/list`, data);

const getPlaylistEligibleMedia = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/getEligibleMedia`, data);

const createPlaylist = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists`, data);

const getPlaylistById = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/getById`, data);

const updatePlaylist = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/edit`, data);

const deletePlaylistById = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/delete`, data);

const getUsersSiteList = (makePostRequest) => (data) =>
  makePostRequest(
    `${envAuthorizationQueryApi}?query_attribute=${data.queryAttribute}&query_value=${data.queryValue}`,
    data
  );

const deleteUser = (makePostRequest) => (items) =>
  makePostRequest(`${envAuthorizationDeleteApi}`, items);

const putUser = (makePostRequest) => (data) => makePostRequest(`${envAuthorizationPutApi}`, data);

const getHealthCheck = (makeGetRequest) => () =>
  makeGetRequest(`${envServerlessApi}/v1/health/checkServices`);

const listMediasWithAccess = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/shared`, data);

const listUsersWithMediaAccess = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/shared/with`, data);

const addEditAccessToUser = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/share`, data);

const removeEditAccessFromUser = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/share/revoke`, data);

const listPlaylistsWithAccess = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/shared`, data);

const listUsersWithPlaylistAccess = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/shared/with`, data);

const addEditAccessToUserPlaylist = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/share`, data);

const removeEditAccessFromUserPlaylist = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/playlists/share/revoke`, data);

const listCollectionsWithAccess = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/shared`, data);

const listUsersWithCollectionAccess = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/shared/with`, data);

const addEditAccessToUserCollection = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/share`, data);

const removeEditAccessFromUserCollection = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/collections/share/revoke`, data);

const changeOwnership = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/changeOwnership`, data);

const getSharedLink = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/links/get`, data);

const getPublicSharedLink = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/links/get/public`, data);

const putSharedLink = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/links/share`, data);

const deleteSharedLink = (makePostRequest) => (data) =>
  makePostRequest(`${envServerlessApi}/v1/media/links/delete`, data);

export default (client) => {
  const { makePostRequest, makeGetRequest } = helpers(client);
  return {
    login: login(makePostRequest),
    getMediaList: getMediaList(makePostRequest),
    getS3UrlToUploadByFilename: getS3UrlToUploadByFilename(makePostRequest),
    getAssetUploadUrl: getAssetUploadUrl(makePostRequest),
    editMedia: editMedia(makePostRequest),
    getMediaDetailsById: getMediaDetailsById(makePostRequest),
    getMediaOnS3ById: getMediaOnS3ById(makePostRequest),
    getPublicMediaOnS3ByShareId: getPublicMediaOnS3ByShareId(makePostRequest),
    postFileMetaDataOnDynamo: postFileMetaDataOnDynamo(makePostRequest),
    startMultiPartUpload: startMultiPartUpload(makePostRequest),
    completeMultiPartUpload: completeMultiPartUpload(makePostRequest),
    listParts: listParts(makePostRequest),
    abortUpload: abortUpload(makePostRequest),
    resumeMultiPart: resumeMultiPart(makePostRequest),
    getCollectionsList: getCollectionsList(makePostRequest),
    getCollectionEligibleMedia: getCollectionEligibleMedia(makePostRequest),
    createCollection: createCollection(makePostRequest),
    getCollectionById: getCollectionById(makePostRequest),
    updateCollection: updateCollection(makePostRequest),
    getSitesList: getSitesList(makeGetRequest),
    updateSite: updateSite(makePostRequest),
    activateSite: activateSite(makePostRequest),
    deactivateSite: deactivateSite(makePostRequest),
    updateSiteSam: updateSiteSam(makePostRequest),
    createSite: createSite(makePostRequest),
    initializeSites: initializeSites(makePostRequest),
    getPlaylistById: getPlaylistById(makePostRequest),
    getPlaylistsList: getPlaylistsList(makePostRequest),
    getPlaylistEligibleMedia: getPlaylistEligibleMedia(makePostRequest),
    createPlaylist: createPlaylist(makePostRequest),
    updatePlaylist: updatePlaylist(makePostRequest),
    deleteCollectionById: deleteCollectionById(makePostRequest),
    deletePlaylistById: deletePlaylistById(makePostRequest),
    getUsersSiteList: getUsersSiteList(makePostRequest),
    deleteUser: deleteUser(makePostRequest),
    putUser: putUser(makePostRequest),
    getHealthCheck: getHealthCheck(makeGetRequest),
    listMediasWithAccess: listMediasWithAccess(makePostRequest),
    listUsersWithMediaAccess: listUsersWithMediaAccess(makePostRequest),
    addEditAccessToUser: addEditAccessToUser(makePostRequest),
    removeEditAccessFromUser: removeEditAccessFromUser(makePostRequest),
    listPlaylistsWithAccess: listPlaylistsWithAccess(makePostRequest),
    listUsersWithPlaylistAccess: listUsersWithPlaylistAccess(makePostRequest),
    addEditAccessToUserPlaylist: addEditAccessToUserPlaylist(makePostRequest),
    removeEditAccessFromUserPlaylist: removeEditAccessFromUserPlaylist(makePostRequest),
    listCollectionsWithAccess: listCollectionsWithAccess(makePostRequest),
    listUsersWithCollectionAccess: listUsersWithCollectionAccess(makePostRequest),
    addEditAccessToUserCollection: addEditAccessToUserCollection(makePostRequest),
    removeEditAccessFromUserCollection: removeEditAccessFromUserCollection(makePostRequest),
    changeOwnership: changeOwnership(makePostRequest),
    getSharedLink: getSharedLink(makePostRequest),
    getPublicSharedLink: getPublicSharedLink(makePostRequest),
    putSharedLink: putSharedLink(makePostRequest),
    deleteSharedLink: deleteSharedLink(makePostRequest),
  };
};
