import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';
import GenericTable from '../../atoms/generic-table/generic-table';
import Grid from '../grid/grid';
import {
  ALL_UPLOADS,
  MY_UPLOADS,
  SHARED_WITH_ME,
  ALL_COLLECTIONS,
  ALL_PLAYLISTS,
  MY_PLAYLISTS,
  SHARED_WITH_ME_PLAYLISTS,
  MY_COLLECTIONS,
  SHARED_WITH_ME_COLLECTIONS,
} from '../../../utils/constants-utils';
import { generateKeyFromString } from '../../../utils/generic-utils';
import FilterForm from '../filter-form/filter-form';

import './_style.scss';

const gridViewAllowedTabs = [
  generateKeyFromString(ALL_UPLOADS),
  generateKeyFromString(MY_UPLOADS),
  generateKeyFromString(SHARED_WITH_ME),
];

const ownerFilterAllowedTabs = [generateKeyFromString(ALL_UPLOADS)];

const titleFilterAllowedTabs = [
  generateKeyFromString(ALL_UPLOADS),
  generateKeyFromString(MY_UPLOADS),
  generateKeyFromString(SHARED_WITH_ME),
  generateKeyFromString(MY_PLAYLISTS),
  generateKeyFromString(SHARED_WITH_ME_PLAYLISTS),
  generateKeyFromString(ALL_COLLECTIONS),
  generateKeyFromString(ALL_PLAYLISTS),
  generateKeyFromString(MY_COLLECTIONS),
  generateKeyFromString(SHARED_WITH_ME_COLLECTIONS),
];

const TabContent = ({
  dataSource,
  loading,
  selectedTab,
  gridView,
  changeView,
  isLti,
  endScrollFunc,
  filterByTitleField,
  filterByOwnerField,
  filterTitlePlaceholder,
  filterOwnerPlaceholder,
  isTitleFiltered,
  isOwnerFiltered,
}) => {
  const gridViewAllowed = () => {
    if (selectedTab) {
      const result = gridViewAllowedTabs.includes(selectedTab.key);
      if (!result) changeView(false);
      return result;
    }
    return false;
  };

  const titleFiltersAllowed = () => {
    if (selectedTab) {
      return titleFilterAllowedTabs.includes(selectedTab.key);
    }
    return false;
  };

  const ownerFiltersAllowed = () => {
    if (selectedTab) {
      return ownerFilterAllowedTabs.includes(selectedTab.key);
    }
    return false;
  };

  return (
    <div className="TabContent" aria-label={selectedTab.ariaLabel} tabIndex={0}>
      <div className="optionsContainer">
        {ownerFiltersAllowed() && (
          <FilterForm
            isFiltered={isOwnerFiltered}
            filterFunc={filterByOwnerField}
            filterDisable={loading}
            filterPlaceholder={filterOwnerPlaceholder}
          />
        )}
        {titleFiltersAllowed() && (
          <FilterForm
            isFiltered={isTitleFiltered}
            filterFunc={filterByTitleField}
            filterDisable={loading}
            filterPlaceholder={filterTitlePlaceholder}
          />
        )}
        {gridViewAllowed() && (
          <div className="ViewSwitch">
            <Switch
              onChange={changeView}
              checkedChildren="Grid View"
              unCheckedChildren="List View"
              defaultChecked={gridView}
              checked={gridView}
            />
          </div>
        )}
      </div>
      {gridView && gridViewAllowed() ? (
        <div className="GridContainer">
          <Grid
            data={dataSource}
            loading={loading}
            editFunc={selectedTab.editRowFunc}
            playFunc={selectedTab.playRowFunc}
            isLti={isLti}
          />
        </div>
      ) : (
        <div className="TableContainer">
          <GenericTable
            columns={selectedTab.tableColumns}
            data={dataSource}
            onClick={selectedTab.selectRowFunc}
            loading={loading}
            endScrollFunc={endScrollFunc}
          />
        </div>
      )}
    </div>
  );
};
TabContent.propTypes = {
  dataSource: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  selectedTab: PropTypes.shape({
    editRowFunc: PropTypes.func,
    playRowFunc: PropTypes.func,
    tableColumns: PropTypes.arrayOf(PropTypes.object),
    selectRowFunc: PropTypes.func,
    key: PropTypes.string,
    ariaLabel: PropTypes.string,
  }).isRequired,
  gridView: PropTypes.bool,
  changeView: PropTypes.func,
  isLti: PropTypes.bool,
  endScrollFunc: PropTypes.func,
  filterByTitleField: PropTypes.func,
  filterByOwnerField: PropTypes.func,
  filterTitlePlaceholder: PropTypes.string,
  filterOwnerPlaceholder: PropTypes.string,
  isTitleFiltered: PropTypes.string,
  isOwnerFiltered: PropTypes.string,
};

TabContent.defaultProps = {
  dataSource: [],
  loading: false,
  gridView: false,
  changeView: () => {},
  isLti: false,
  endScrollFunc: () => {},
  filterByTitleField: () => {},
  filterByOwnerField: () => {},
  filterTitlePlaceholder: '',
  filterOwnerPlaceholder: '',
  isTitleFiltered: '',
  isOwnerFiltered: '',
};

export default TabContent;
