export default {
  /**
   * Application authorization details. For more information check "Getting Started" in
   * README.md
   */
  // Specifies the code or token type returned by an authorization request. Options are token,
  // id_token, and code. Default values is "token id_token". This is a required property.
  responseType: 'code',
  // Specifies the application's UUID.
  clientId: 'asu-media-plus-nonprod',
  // Specifies the application's secret key
  // Should be used only for testing purposes and not in production!!!
  clientSecret: 'serviceauth-public-agent',
  challengeMethod: 'plain',
  codeVerifier: 'asu-mediaplus-sso',
  // Specifies the grant type of the token request. Options are authorization_code, implicit,
  // and client_credentials
  grantType: 'authorization_code',
  // Specifies the maximum amount of time allowed since the user last authenticated. If the
  // max_age value is exceeded, the user must re-authenticate.
  maxAge: 3600,
  // Optional parameter that designates whether the authentication request includes steps for
  // a single-factor or multi-factor authentication flow. This parameter maps to the name of
  // a sign-on policy that must be assigned to the application. For more information, see
  // Sign-on policies.
  authURL: 'https://weblogin.asu.edu/serviceauth/oauth2/native/allow',
  // API BasURL
  tokenURL: 'https://weblogin.asu.edu/serviceauth/oauth2/native/token',
  // Refresh Token URI
  refreshTokenURL: 'https://weblogin.asu.edu/serviceauth/oauth2/token',
  enableAuth: true,
  // The public key for ASU's SSO endpoint.  They do not offer an endpoint
  // where this can be retrieved from.
  publicKey:
    '-----BEGIN PUBLIC KEY-----' +
    'MIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEA3qXWEL5b1auP1yhtJGO9' +
    'qNF8kSszEY2Y3uYnOusOrfT5CP1p9AMd8f6jeH3EMFPuTJmYUBPKX03eTtG6vHMU' +
    'TpKuT3alfDfesgWMfOKza/I9J9KUHlXSo2yzFtI8b9S/mq6eyA6SortDEzZq1+y/' +
    'c6dkbr+i8LOSvffaISq4FwSY0TKXV9CvpGZfIKFne/LccFxMd/UVXHSlsA1LDOBe' +
    'BOl2O7RmBzQO47ORcHBam98mVn4b2OhxYUoVul0x2dtcquyhBfMXE0e+e0CsGIVr' +
    'BRayI4jCbo6y5jLtWVeM4xkUvRbOebOeMDucIB8UampkFRYT0yCO3dIKHWLBRQgB' +
    'RA8LdZXBTCelUdgie5nmlktMkGe6mXr0ZX343yP5LLpqRoLsGm4PFO2WQ68jRd5C' +
    '1cxyYKSyDj4/bFMeAnjk4p8eSIye7kkcFoNGpUyp8ifBkRGoUMv6rrQkWvUQHAVt' +
    'zq9zzPT71GWO8J5CmGfM1Ei6JmzTnyX4w/jYKZic9zwxmq2mP4VfJ46CzqfwyqZX' +
    'RSW157K0jfd2wEAF6VZz+bh9fcH27/s6a/rrn15PBUHou+cOTfI9Hfi9zSsisySW' +
    'TJjwC+AWGWJO0s/8VpuYpIBCLS3egjt/UvWIsyLfCxm/j7YCNjMWQcKgaTgPuWBI' +
    '2JOmKyKndXH+DXrlgGBCf3sCAwEAAQ==' +
    '-----END PUBLIC KEY-----',
};
