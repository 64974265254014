import React from 'react';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';
import './_style.scss';

const DeleteActionColumnContainer = ({ onDeleteClick }) => (
  <div className="DeleteActionContainer">
    <button className="DeleteAction" type="button" onClick={onDeleteClick}>
      <div>
        <b>FAILED</b> <DeleteOutlined />
      </div>
    </button>
  </div>
);

DeleteActionColumnContainer.propTypes = {
  onDeleteClick: PropTypes.func,
};

DeleteActionColumnContainer.defaultProps = {
  onDeleteClick: () => {},
};

export default DeleteActionColumnContainer;
