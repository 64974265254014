import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const EditModeContext = createContext();

const EditModeProvider = ({ children }) => {
  const [editMode, setEditMode] = useState();

  return (
    <EditModeContext.Provider value={{ editMode, setEditMode }}>
      {children}
    </EditModeContext.Provider>
  );
};

EditModeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { EditModeContext, EditModeProvider };
