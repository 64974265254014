const beforeUnloadHandler = (event) => {
  event.preventDefault();
  // Chrome requires returnValue to be set.
  // eslint-disable-next-line no-param-reassign
  event.returnValue = '';
};

export const addBeforeUnloadListener = () => {
  window.addEventListener('beforeunload', beforeUnloadHandler);
};

export const removeBeforeUnloadListener = () => {
  window.removeEventListener('beforeunload', beforeUnloadHandler);
};
