import React from 'react';
import SitesAdmin from '../../organisms/sites-admin/sites-admin';
import './_styles.scss';

const SitesAdminPage = () => (
  <div className="SiteAdminContainer">
    <SitesAdmin />
  </div>
);

export default SitesAdminPage;
