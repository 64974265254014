import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import MediaPlayer from '../../organisms/media-player/media-player';
import apiCalls from '../../../services/api-calls/all';
import { errorLogger } from '../../../utils/generic-utils';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const EmbeddedPlayerPage = () => {
  const [mediaId, setMediaId] = useState();
  const [shareId, setShareId] = useState();
  const [siteId, setSiteId] = useState();
  const [loading, setLoading] = useState();
  const axiosPrivate = useAxiosPrivate();
  const { getSharedLink } = apiCalls(axiosPrivate);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setSiteId(newsite);
    const newsite = params.get('siteId');
    const _shareId = params.get('id');
    const isPrivate = params.get('isPrivate') === 'true';
    setSiteId(newsite);
    getMediaIdBySharedlink(_shareId, isPrivate, newsite);
  }, []);

  const getMediaIdBySharedlink = async (sharedLinkId, isPrivate, newSiteId) => {
    setLoading(true);
    if (!isPrivate) {
      // public links use shareId directly for public s3 url endpoint
      setShareId(sharedLinkId); // only set shareId if not private
      setLoading(false);
      return;
    }
    try {
      const {
        data: {
          message: { Items },
        },
      } = await getSharedLink({
        shareId: sharedLinkId,
        siteId: newSiteId,
        isPrivate,
      });
      if (Items.length) {
        setMediaId(Items[0].BundleId);
      }
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while getting users list:', error });
    }
    setLoading(false);
  };

  return (
    <div className="MediaPlayerPageContainer">
      {loading ? <Spin /> : <MediaPlayer id={mediaId} siteId={siteId} publicShareId={shareId} />}
    </div>
  );
};

export default EmbeddedPlayerPage;
