import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

const GenericConfirmModal = ({
  title,
  content,
  isModalVisible,
  closable,
  handleOk,
  handleCancel,
}) => (
  <div className="GenericConfirmModal">
    <Modal
      title={title}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      closable={closable}
    >
      {content}
    </Modal>
  </div>
);

GenericConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  isModalVisible: PropTypes.bool,
  closable: PropTypes.bool,
  handleOk: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

GenericConfirmModal.defaultProps = {
  isModalVisible: false,
  closable: true,
};

export default GenericConfirmModal;
