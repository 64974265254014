import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { generateMediaIFrame } from '../../../../utils/urls-utils';
import { EMBEDDED_PLAYER_URL, EMBEDDED_PLAYER_LTI_URL } from '../../../../utils/constants-utils';
import apiCalls from '../../../../services/api-calls/all';
import { UserContext } from '../../../../services/providers/user-context';
import ButtonPrimary from '../../../atoms/button-primary/button-primary';
import SharedLink from '../../shared-link/shared-link';
import useAxiosPrivate from '../../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const MediaShare = ({ currentMedia, siteId }) => {
  const [mediaIFrame, setMediaIFrame] = useState();
  const { user } = useContext(UserContext);
  const axiosPrivate = useAxiosPrivate();
  const { getSharedLink, putSharedLink, deleteSharedLink } = apiCalls(axiosPrivate);
  useEffect(() => {
    const { origin } = window.location;
    console.log('IFRAME Generate, siteId: ' + siteId);
    setMediaIFrame(
      generateMediaIFrame(origin, EMBEDDED_PLAYER_LTI_URL, currentMedia.MediaId, siteId)
    );
  }, []);
  const copyIFrameToClipboard = () => {
    navigator.clipboard.writeText(mediaIFrame);
  };
  return (
    <div className="MediaShareEditorContainer">
      <div className="MediaShareEditorShareLinksField">
        {/* <SharedLink
          title="Private Link"
          wasShareLinkGeneratedEndpoint={getSharedLink}
          storeGenerateShareLinkEndpoint={putSharedLink}
          deleteShareLinkEndpoint={deleteSharedLink}
          isPrivate
          structureParam="mediaId"
          structureId={currentMedia.MediaId}
          siteId={siteId}
          userId={user.userId}
          embededdURL={EMBEDDED_PLAYER_URL}
        /> */}
        <SharedLink
          title="Public Link"
          wasShareLinkGeneratedEndpoint={getSharedLink}
          storeGenerateShareLinkEndpoint={putSharedLink}
          deleteShareLinkEndpoint={deleteSharedLink}
          structureParam="mediaId"
          structureId={currentMedia.MediaId}
          siteId={siteId}
          userId={user.userId}
          embededdURL={EMBEDDED_PLAYER_URL}
        />
      </div>
      <div className="MediaShareEditorEmbedCodeField">
        <p className="TitleEmbed">Embed code</p>
        <Input.TextArea value={mediaIFrame} disabled />
        <div className="CopyButton">
          <ButtonPrimary
            onClick={copyIFrameToClipboard}
            text="Copy code"
            size="small"
            theme="round"
          />
        </div>
      </div>
    </div>
  );
};

MediaShare.propTypes = {
  currentMedia: PropTypes.shape(PropTypes.object).isRequired,
  siteId: PropTypes.string.isRequired,
};

export default MediaShare;
