import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Button, Checkbox } from 'antd';
import { MediaUploadContext } from '../../../services/providers/media-upload-context';
import DragAndDrop from '../../atoms/drag-and-drop/drag-and-drop';
import apiCalls from '../../../services/api-calls/all';
import { useRedirect } from '../../router/redirect';
import { MEDIA_URL, MEDIA_LTI_URL, CHUNK_SIZE, RETRIES } from '../../../utils/constants-utils';
import { UPLOAD_MEDIA_ERROR_MESSAGE } from '../../../utils/constants-content-messages';
import { errorLogger } from '../../../utils/generic-utils';
import { EditModeContext } from '../../../services/providers/edit-mode-context';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const COPYRIGHT_TEXTS_1 = [
  '1) You represent and warrant that either: (1) ASU or you own the materials being uploaded; or (2) ASU or you have obtained permission of the copyright owner of the materials to upload and use the materials as contemplated by your submission, and that such third-party owned material are clearly identified and acknowledged within the text or content of the submission.',
  '2) You represent and warrant, to the best of your knowledge, that the use of the materials you upload does not: (1) infringe upon any third party’s copyright or other intellectual property rights; (2) contain any libelous or other unlawful content; and (3) violate the right of privacy or constitute an invasion of any other rights of any person or third party.',
];

const COPYRIGHT_TEXTS_2 = [
  '3) You represent that you will hold ASU harmless for any liability arising from any breach of the representations given above.',
  '4) You understand and agree that ASU may delete or disable any content that violates copyright law, violates the terms of use of this service, or for any other reason.',
  'Note: This product is not to be used to store and/or to share regulated data, subject to PCI, FERPA, HIPPA, ITAR, GDPR regulations.',
];

const MediaUploader = ({ userId, siteId, isLTI }) => {
  const [file, setFile] = useState();
  const { setEditMode } = useContext(EditModeContext);
  const { addUploadingMedia, removeUploadedMedia, updateUploadingMedia } =
    useContext(MediaUploadContext);
  const { redirect, setUrlToRedirect } = useRedirect();
  const axiosPrivate = useAxiosPrivate();
  const apiCallsFuncs = apiCalls(axiosPrivate);

  useEffect(() => (file ? setEditMode(true) : setEditMode(false)), [file]);

  /*
   * If file is bigger than 70Mb, then perform a multi-part upload.
   * Else, file will be uploaded in a single upload.
   */

  const uploadFile = async ({ copyright, reuse }) => {
    setEditMode(false);
    const { name, size } = file;
    setUrlToRedirect(isLTI ? MEDIA_LTI_URL : MEDIA_URL);
    try {
      // upload file
      const mediaKey =
        size >= 70000000
          ? await uploadMultiPart(
              file,
              addUploadingMedia,
              updateUploadingMedia,
              removeUploadedMedia,
              siteId,
              userId,
              apiCallsFuncs
            )
          : await uploadSinglePart(
              file,
              addUploadingMedia,
              updateUploadingMedia,
              removeUploadedMedia,
              siteId,
              userId,
              apiCallsFuncs
            );
      // save meta on dynamo
      const body = {
        path: `${mediaKey}`,
        objectKey: name,
        owner: userId,
        ownerId: userId,
        siteId,
        copyright,
        reuse,
      };
      apiCallsFuncs.postFileMetaDataOnDynamo(body);
    } catch (error) {
      errorLogger({
        loggedMessage: 'Unexpected Error uploading metadata to dynamo:',
        error,
        notifyUser: false,
      });
    }
  };

  const uploadCopyrightTextAgreement = (texts) =>
    texts.map((text) => (
      <div>
        <p>{text}</p>
        <br />
      </div>
    ));

  return (
    <div className="mediaUploadFormContianer">
      {redirect()}
      <div className="uploadFormContianer">
        <Form name="uploadform" onFinish={uploadFile} layout="vertical" autoComplete="off">
          <Form.Item
            name="media"
            rules={[{ required: true, message: 'You must select a media to upload' }]}
          >
            <DragAndDrop
              onChange={(data) => setFile(data.file)}
              text="Click or drag media to this area to upload"
              disabled={!!file}
              className="dragAndDrop"
            />
          </Form.Item>
          <div className="copyrightSection">
            <div className="acceptSection">{uploadCopyrightTextAgreement(COPYRIGHT_TEXTS_1)}</div>
            <div className="copyrightReuseSection">
              {uploadCopyrightTextAgreement(COPYRIGHT_TEXTS_2)}
              <div className="formSection">
                <Form.Item
                  name="copyrightFairUse"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value
                          ? Promise.resolve()
                          : Promise.reject(new Error('Should accept copyright fair use')),
                    },
                  ]}
                >
                  <Checkbox>I Accept</Checkbox>
                </Form.Item>
                <div className="buttonSection">
                  <Form.Item>
                    <Button type="primary" htmlType="submit">
                      Start Upload
                    </Button>
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default MediaUploader;

const uploadPart = async (url, data) => {
  try {
    // return await uploadPartToS3(url, data);
    if (process.env.REACT_APP_CUSTOM_ENV !== 'mocked') {
      return fetch(url, {
        method: 'PUT',
        body: data,
      }).then((e) => e.body);
    }
    return 'mocked response';
  } catch (error) {
    errorLogger({
      loggedMessage: 'Error while uploading part:',
      error,
      notifyUser: false,
      throwError: true,
    });
  }
};

// If file is bigger than 50Mb then perform a multi part upload
const uploadMultiPart = async (
  file,
  addUploadingMedia,
  updateUploadingMedia,
  removeUploadedMedia,
  site,
  owner,
  apiCallsFuncs
) => {
  try {
    const { name, size } = file;

    // chunk size determines each part size. This needs to be > 5Mb
    const chunkSize = CHUNK_SIZE;
    let chunkStart = 0;
    const partsQuan = Math.ceil(size / chunkSize);
    // Start multi part upload. This returns both uploadId and signed urls for each part.
    const startResponse = await apiCallsFuncs.startMultiPartUpload({
      fileName: name,
      chunksQuan: partsQuan,
      siteId: site,
    });
    const {
      signedURLs,
      startUploadResponse: { Key, UploadId },
    } = startResponse.data;

    // add uploading media
    const newUploadingMedia = {
      file_name: name,
      owner,
      upload_date: moment(),
      upload_id: UploadId,
      upload_progress: { size, updatedParts: 0 },
    };
    addUploadingMedia(newUploadingMedia);

    try {
      let promises = [];
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < partsQuan; i++) {
        // Split file into parts and upload each one to it's signed url
        const chunk = await file.slice(chunkStart, chunkStart + chunkSize).arrayBuffer();
        chunkStart += chunkSize;
        promises.push(uploadPart(signedURLs[i], chunk));
        await allProgress({ promises, name }, (media) => {
          updateUploadingMedia({ ...media, upload_id: UploadId });
        });
        promises = [];
      }
      /* eslint-enable no-await-in-loop */
      // wait for remaining parts
      await Promise.all(promises);

      // Get parts list to build complete request (each upload does not retrieve ETag)
      const partsList = await apiCallsFuncs.listParts({
        fileKey: Key,
        uploadId: UploadId,
        siteId: site,
      });
      // build parts object for complete upload
      const completeParts = partsList.data.Parts.map(({ PartNumber, ETag }) => ({
        ETag,
        PartNumber,
      }));
      // Complete multi part upload
      await apiCallsFuncs.completeMultiPartUpload({
        fileKey: Key,
        uploadId: UploadId,
        parts: completeParts,
        siteId: site,
      });

      // remove uploading media
      removeUploadedMedia(newUploadingMedia);
      return Key;
    } catch (error) {
      errorLogger({
        loggedMessage: 'Error while uploading file:',
        error,
        notifyUser: false,
      });
      return retryMultiPart(
        name,
        partsQuan,
        UploadId,
        Key,
        file,
        RETRIES,
        updateUploadingMedia,
        removeUploadedMedia,
        site,
        apiCallsFuncs
      );
    }
  } catch (error) {
    errorLogger({
      loggedMessage: 'Error while uploading multipart file:',
      error,
      userMessage: UPLOAD_MEDIA_ERROR_MESSAGE,
    });
  }
};
export const retryMultiPart = async (
  name,
  totalParts,
  uploadId,
  fileKey,
  file,
  retries,
  updateUploadingMedia,
  removeUploadedMedia,
  site,
  apiCallsFuncs
) => {
  console.log('retrying upload...');
  if (retries >= 0) {
    try {
      const {
        data: { NextPartNumberMarker, signedUrls },
      } = await apiCallsFuncs.resumeMultiPart({
        totalParts,
        uploadId,
        fileKey,
        siteId: site,
      });
      let chunkStart = CHUNK_SIZE * (NextPartNumberMarker - 1);
      let promises = [];
      /* eslint-disable no-await-in-loop */
      for (let i = 0; i < signedUrls.length; i++) {
        const chunk =
          process.env.REACT_APP_CUSTOM_ENV === 'mocked'
            ? 'testChunk'
            : file.slice(chunkStart, chunkStart + CHUNK_SIZE).arrayBuffer();
        chunkStart += CHUNK_SIZE;
        promises.push(uploadPart(signedUrls[i], chunk));
        await allProgress({ promises, name }, (media) => {
          updateUploadingMedia({ ...media, upload_id: uploadId });
        });
        promises = [];
      }
      /* eslint-enable no-await-in-loop */
      // Get parts list to build complete request (each upload does not retrieve ETag)
      const partsList = await apiCallsFuncs.listParts({
        fileKey,
        uploadId,
        siteId: site,
      });
      // build parts object for complete upload
      const completeParts = partsList.data.Parts.map(({ PartNumber, ETag }) => ({
        ETag,
        PartNumber,
      }));
      // Complete multi part upload
      await apiCallsFuncs.completeMultiPartUpload({
        fileKey,
        uploadId,
        parts: completeParts,
        siteId: site,
      });

      // remove uploading media
      removeUploadedMedia({ fileName: name, upload_id: uploadId });
      return fileKey;
    } catch (error) {
      errorLogger({
        loggedMessage: 'Error while retrying s3 resume upload:',
        error,
        notifyUser: false,
      });
      return retryMultiPart(
        name,
        totalParts,
        uploadId,
        fileKey,
        file,
        retries - 1,
        updateUploadingMedia,
        removeUploadedMedia,
        site,
        apiCallsFuncs
      );
    }
  } else {
    await apiCallsFuncs.abortUpload({
      fileKey,
      uploadId,
      siteId: site,
    });
    updateUploadingMedia({ fileName: name, failed: true, upload_id: uploadId });
    throw new Error('Retry limit exceeded!');
  }
};
// If file is < 50Mb then we upload it in just one request.
const uploadSinglePart = async (
  file,
  addUploadingMedia,
  updateUploadingMedia,
  removeUploadedMedia,
  site,
  owner,
  apiCallsFuncs
) => {
  const { name, size } = file;
  // get file extension to generate upload url
  const formData = new FormData();
  let fileKey;
  let uploadS3Url;
  // get s3 upload url
  try {
    const { data } = await apiCallsFuncs.getS3UrlToUploadByFilename({
      fileName: name,
      siteId: site,
    });
    // build post request to upload file
    uploadS3Url = data.url;
    fileKey = data.fields.key;
    Object.entries(data.fields).forEach(([k, v]) => {
      formData.append(k, v);
    });
    formData.append('file', file);

    // add uploading media
    const uploadId = moment().format('DD.MM.YYYY HH:mm:ss');
    const newUploadingMedia = {
      file_name: name,
      owner,
      upload_date: moment(),
      upload_id: uploadId,
      upload_progress: { size, updatedParts: 0 },
    };
    addUploadingMedia(newUploadingMedia);

    // upload video to s3
    try {
      await apiCallsFuncs.uploadFilesToS3(uploadS3Url, formData);
      // remove uploading media
      removeUploadedMedia({ fileName: name, upload_id: uploadId });
      return fileKey;
    } catch (error) {
      errorLogger({
        loggedMessage: 'Error while uploading file to s3:',
        error,
        notifyUser: false,
      });
      updateUploadingMedia({ fileName: name, failed: true, upload_id: uploadId });
    }
  } catch (error) {
    errorLogger({
      loggedMessage: 'Error while uploading singlePart file:',
      error,
      userMessage: UPLOAD_MEDIA_ERROR_MESSAGE,
    });
  }
};

/* eslint-disable promise/catch-or-return */
const allProgress = ({ promises, name }, progressCB) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const p of promises) {
    // eslint-disable-next-line no-loop-func
    p.then(() => {
      progressCB({ fileName: name });
    });
  }
  return Promise.all(promises);
};

MediaUploader.propTypes = {
  userId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  isLTI: PropTypes.bool,
};

MediaUploader.defaultProps = { isLTI: false };
