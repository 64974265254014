import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Menu } from 'antd';
import {
  faPhotoVideo,
  faCloudUploadAlt,
  faFolderPlus,
  faList,
  faCog,
  faCogs,
  faCommentAlt,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ViewportContext } from '../../../services/providers/view-port-context';

import { useRedirect } from '../../router/redirect';
import {
  MEDIA_KEY_PAGE,
  MY_UPLOADS_KEY_PAGE,
  COLLECTIONS_KEY_PAGE,
  PLAYLISTS_KEY_PAGE,
  SETTINGS_KEY_PAGE,
  SEND_FEEDBACK_KEY_PAGE,
  HELP_KEY_PAGE,
  SITES_ADMIN_KEY_PAGE,
  SITES_LIST_KEY_PAGE,
  MIN_WINDOW_WIDTH_SIZE_DESKTOP,
} from '../../../utils/constants-utils';
import { PAGES_KEYS_BY_PATH } from '../../../utils/jsons-constants-utils';

import './_style.scss';

const menuIconsByKey = {
  [MEDIA_KEY_PAGE]: faPhotoVideo,
  [MY_UPLOADS_KEY_PAGE]: faCloudUploadAlt,
  [COLLECTIONS_KEY_PAGE]: faFolderPlus,
  [PLAYLISTS_KEY_PAGE]: faList,
  [SETTINGS_KEY_PAGE]: faCog,
  [SEND_FEEDBACK_KEY_PAGE]: faCommentAlt,
  [HELP_KEY_PAGE]: faQuestionCircle,
  [SITES_ADMIN_KEY_PAGE]: faCogs,
  [SITES_LIST_KEY_PAGE]: faList,
};

const NavMenu = ({ keyPage, items }) => {
  const [highlight, setHighlight] = useState();
  // canvas experience state
  const [responsiveView, setResponsiveView] = useState();

  const { redirect, setUrlToRedirect } = useRedirect();
  const { width } = useContext(ViewportContext);

  useEffect(() => {
    if (width <= MIN_WINDOW_WIDTH_SIZE_DESKTOP) {
      setResponsiveView(true);
    }
    if (width > MIN_WINDOW_WIDTH_SIZE_DESKTOP) {
      setResponsiveView(false);
    }
  }, [width]);

  useEffect(() => {
    if (keyPage) setHighlight(keyPage);
  }, [keyPage]);

  const MenuItems = items.map(({ title, key }) => (
    <Menu.Item
      key={key}
      icon={
        <div className="frontawesomeIcon">
          <FontAwesomeIcon icon={menuIconsByKey[key]} />
        </div>
      }
      aria-label={title}
    >
      <b>{title}</b>
    </Menu.Item>
  ));

  const handleClick = ({ key }) => {
    const pathsByPageKeys = _.invert(PAGES_KEYS_BY_PATH);
    setUrlToRedirect(pathsByPageKeys[key]);
  };

  return (
    <div className="NavMenuContainer">
      {redirect()}
      <Menu
        onClick={handleClick}
        className="NavMenu"
        selectedKeys={[highlight]}
        mode={responsiveView ? 'horizontal' : 'inline'}
        aria-label="Navigation menu. Press enter to use"
      >
        {MenuItems}
      </Menu>
    </div>
  );
};

NavMenu.propTypes = {
  keyPage: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
};

NavMenu.defaultProps = {};

export default NavMenu;
