import React, { useState } from 'react';
import { Typography, Input } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Title } = Typography;

const TitleEditor = ({ initialValue, onChange }) => {
  const [title, setTitle] = useState(initialValue);
  const [editing, setEditing] = useState(false);
  const handleBlur = () => {
    onChange(title);
    setEditing(false);
  };
  const handleEditClick = () => {
    setEditing(true);
  };
  return (
    <div>
      {editing ? (
        <Input
          defaultValue={title}
          onBlur={handleBlur}
          onChange={(e) => setTitle(e.target.value)}
        />
      ) : (
        <Title level={4}>
          {title} <EditOutlined onClick={handleEditClick} />
        </Title>
      )}
    </div>
  );
};

TitleEditor.propTypes = {
  initialValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
TitleEditor.defaultProps = {};

export default TitleEditor;
