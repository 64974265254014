import React, { useState, useEffect, useContext } from 'react';
import apiCalls from '../../../services/api-calls/all';
import EditMediaStructure from '../../organisms/edit-media-structure/edit-media-structure';
import { collectionTableColumns } from '../../molecules/table-headers/collection-items.table-header';
import { SiteContext } from '../../../services/providers/site-context';
import { UserContext } from '../../../services/providers/user-context';
import { useRedirect } from '../../router/redirect';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import {
  COLLECTIONS_URL,
  BACK_TO_COLLECTIONS,
  DELETE_COLLECTION,
} from '../../../utils/constants-utils';
import './_style.scss';

const EditCollectionPage = () => {
  const [currentCollection, setCurrentCollection] = useState();
  const { site } = useContext(SiteContext);
  const { user } = useContext(UserContext);
  const { redirect, setUrlToRedirect } = useRedirect();
  const axiosPrivate = useAxiosPrivate();
  const {
    getCollectionById,
    getCollectionEligibleMedia,
    updateCollection,
    deleteCollectionById,
    listUsersWithCollectionAccess,
    addEditAccessToUserCollection,
    removeEditAccessFromUserCollection,
  } = apiCalls(axiosPrivate);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setCurrentCollection(params.get('id'));
  }, []);

  const onMediaStructureSubmit = (collection, collectionItems) =>
    updateCollection({
      siteId: site.SiteId,
      collectionId: currentCollection,
      collectionTitle: collection.title,
      collectionItems: collectionItems.map((item) => item.id),
    });

  const processedCollectionItems = ({ data }) =>
    data.mediaItems.Items.map(({ MediaId, title, owner, created, duration }) => ({
      id: MediaId,
      media_name: title,
      owner,
      created,
      duration,
    }));

  const processFeedEndpointResults = ({ data: { Items } }) =>
    Items.map((item) => ({
      key: item.MediaId,
      media_name: item.title,
      id: item.MediaId,
      ...item,
    }));

  const getCollectionEligibleMediaEndpoint = (mediaItemsIds) =>
    getCollectionEligibleMedia({
      siteId: site.SiteId,
      userId: user.userId,
      ...mediaItemsIds,
    });

  const getCollectionByIdEndpoint = () =>
    getCollectionById({
      collectionId: currentCollection,
      ownerId: user.userId,
      siteId: site.SiteId,
    });

  const deleteThisCollection = async () => {
    await deleteCollectionById({ collectionId: currentCollection, siteId: site.SiteId });
    setUrlToRedirect(COLLECTIONS_URL);
  };

  const processShareAccessEndpoints = {
    get: () =>
      listUsersWithCollectionAccess({ siteId: site.SiteId, collectionId: currentCollection }),
    add: (UserId) =>
      addEditAccessToUserCollection({
        collectionId: currentCollection,
        sharedWith: UserId,
        sharedBy: user.userId,
        siteId: site.SiteId,
      }),
    remove: (shareId) => removeEditAccessFromUserCollection({ siteId: site.SiteId, shareId }),
  };

  return (
    <div className="EditCollectionPageContainer" tabIndex={0} aria-label="Edit collection page">
      {redirect()}
      {currentCollection && (
        <EditMediaStructure
          mediaStructureId={currentCollection}
          endpoint={getCollectionByIdEndpoint}
          processedMediaStuctureItems={processedCollectionItems}
          returnUrl={COLLECTIONS_URL}
          returnText={BACK_TO_COLLECTIONS}
          tableColumns={collectionTableColumns}
          feedEndpoint={getCollectionEligibleMediaEndpoint}
          processFeedEndpointResults={processFeedEndpointResults}
          onMediaStructureSubmit={onMediaStructureSubmit}
          deleteFunc={deleteThisCollection}
          deleteText={DELETE_COLLECTION}
          shareAccessEndpoints={processShareAccessEndpoints}
          userId={user.userId}
          siteId={site.SiteId}
        />
      )}
    </div>
  );
};

export default EditCollectionPage;
