import React, { useEffect } from 'react';
import { Button } from 'antd';
import { logout } from '../../../authn/api';
import './_style.scss';

const LogoutForm = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { badcode } = Object.fromEntries(urlSearchParams.entries());

  const logoutMessage = 'You have been successfully logged out of Media+';
  const badAuthMessage = 'Invalid Authorization Code';
  const message = badcode ? badAuthMessage : logoutMessage;
  const login = () => {
    window.location.replace('/');
  };

  useEffect(async () => {
    // log the user out
    logout();
  }, []);

  return (
    <div className="LogoutForm">
      <div className="font">{message}</div>
      <Button className="center" type="primary" onClick={login}>
        Login to Media+
      </Button>
    </div>
  );
};

export default LogoutForm;
