import React from 'react';
import PropTypes from 'prop-types';
import MediaFeed from '../../molecules/media-feed/media-feed';
import MediaFeedHeader from '../../molecules/media-feed-header/media-feed-header';
import './_style.scss';

const MediaFeedContainer = ({ tabs, headerInfo, isLti }) => (
  <div className="MediaFeedContainer">
    {!headerInfo.withoutHeader && <MediaFeedHeader {...headerInfo} />}
    <MediaFeed tabs={tabs} isLti={isLti} />
  </div>
);

MediaFeedContainer.propTypes = {
  headerInfo: PropTypes.shape({
    title: PropTypes.string,
    ButtonAction: PropTypes.func,
    ButtonText: PropTypes.string,
    ButtonIcon: PropTypes.node,
    SearchText: PropTypes.string,
    onSearch: PropTypes.func,
    withoutHeader: PropTypes.bool,
  }).isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      endpoint: PropTypes.func,
      processEndpointResult: PropTypes.func,
      tableColumns: PropTypes.arrayOf(PropTypes.object),
      selectRowFunc: PropTypes.func,
      name: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  isLti: PropTypes.bool,
};

MediaFeedContainer.defaultProps = { isLti: false };

export default MediaFeedContainer;
