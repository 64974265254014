import React from 'react';
import {
  UNEXPECTED_ERROR_MESSAGE_OOPS,
  UNEXPECTED_ERROR_MESSAGE_MEDIA_SORRY,
  UNEXPECTED_ERROR_MESSAGE_HELP_US,
  UNSAVED_CHANGES_TEXTS,
  CHANGE_MEDIA_OWNERSHIP_TEXTS,
  DELETE_LINK_TEXTS,
} from './constants-utils';

export const UNEXPECTED_ERROR_MESSAGE = `${UNEXPECTED_ERROR_MESSAGE_OOPS} ${UNEXPECTED_ERROR_MESSAGE_HELP_US}`;

export const UPLOAD_MEDIA_ERROR_MESSAGE = UNEXPECTED_ERROR_MESSAGE_MEDIA_SORRY;

export const UNSAVED_CHANGES_WARNING = (
  <div>
    {UNSAVED_CHANGES_TEXTS.map((text) => (
      <p>{text}</p>
    ))}
  </div>
);

export const CHANGE_MEDIA_OWNERSHIP_WARNING = (
  <div>
    {CHANGE_MEDIA_OWNERSHIP_TEXTS.map((text) => (
      <p>{text}</p>
    ))}
  </div>
);

export const DELETE_LINK_WARNING = (
  <div>
    {DELETE_LINK_TEXTS.map((text) => (
      <p>{text}</p>
    ))}
  </div>
);
