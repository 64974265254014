import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import MediaFeedContainer from '../../organisms/media-feed-container/media-feed-container';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import { useRedirect } from '../../router/redirect';
import { myUploadsColumns } from '../../molecules/table-headers/media.table-header';
import {
  MEDIA_EDIT_URL,
  MEDIA_UPLOAD_URL,
  // MEDIA_PLAYER_URL,
  EMBEDDED_PLAYER_LTI_URL,
  MEDIA_URL,
  MY_UPLOADS,
  PLAY,
  EMBED,
  SITES_LIST_URL,
} from '../../../utils/constants-utils';
import apiCalls from '../../../services/api-calls/all';
import { generateKeyFromString } from '../../../utils/generic-utils';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const processMediaInfo = ({ data }) =>
  data.Items.map(({ timeStamp, MediaId, ...rest }) => ({
    key: MediaId,
    created: timeStamp,
    ...rest,
  }));

const MediaLTIPage = () => {
  const [mediaParameters, setMediaParameters] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const ownerId = params.get('ownerId');
    const siteId = params.get('siteId');
    const token = params.get('id_token');
    setMediaParameters({
      ownerId,
      siteId,
      token,
    });
  }, []);
  return (
    <div>{mediaParameters && <MediaLTIPagecomponent mediaParameters={mediaParameters} />}</div>
  );
};

const MediaLTIPagecomponent = ({ mediaParameters }) => {
  const [mediaSelected, setMediaSelected] = useState();
  const { redirect, setUrlToRedirect } = useRedirect();
  const axiosPrivate = useAxiosPrivate();
  const { getMediaList } = apiCalls(axiosPrivate);
  const apiUrl = process.env.REACT_APP_serverlessApi;
  const deeplinkUrl = `${apiUrl}/v1/lti/deeplink`;
  const EmbedVideo = (videoUrl) => {
    axios
      .post(deeplinkUrl, {
        videoItem: videoUrl,
        token: mediaParameters.token,
      })
      .then((response) => {
        const params = new URLSearchParams(window.location.search);
        const hasMultipleSites = params.get('multipleSites');
        console.log(response);
        if (hasMultipleSites) {
          // Show the site selection page
          setUrlToRedirect(SITES_LIST_URL);
          // redirect(`${SITES_LIST_URL}?lti=true`);
          redirect({ returnUrl: MEDIA_URL });
        } else {
          const form = document.createElement('form');
          form.setAttribute('action', response.data.url);
          form.setAttribute('method', 'POST');
          form.setAttribute('style', 'display: none');
          const jwtParam = document.createElement('input');
          jwtParam.setAttribute('name', 'JWT');
          jwtParam.setAttribute('value', response.data.token);
          form.appendChild(jwtParam);
          document.body.appendChild(form);
          form.submit();
        }
      })
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });
  };
  const params = new URLSearchParams(window.location.search);
  const isDeepLink = params.get('isDeepLink');

  const goToMediaEmbed = ({ key }) => {
    // This fires when you click the Embed button
    const baseUrl = window.location.origin;
    setMediaSelected(key);
    EmbedVideo(
      `${baseUrl}${EMBEDDED_PLAYER_LTI_URL}?id=${key}&siteId=${mediaParameters.siteId}&isDeepLink=true`
    );
  };
  const goToMediaPlay = ({ key }) => {
    setMediaSelected(key);
    setUrlToRedirect(EMBEDDED_PLAYER_LTI_URL);
  };
  const goToMediaEdit = ({ key }) => {
    console.log('settingUrlToRedirect: ' + MEDIA_EDIT_URL);
    setMediaSelected(key);
    setUrlToRedirect(MEDIA_EDIT_URL);
  };
  const goToNewMedia = () => {
    setUrlToRedirect(MEDIA_UPLOAD_URL);
  };

  let actions;
  if (isDeepLink === 'true') {
    actions = [{ key: EMBED, onClick: goToMediaEmbed }];
  } else {
    actions = [{ key: PLAY, onClick: goToMediaPlay }];
  }

  console.log('Actions: \n' + JSON.stringify(actions));
  const tabs = [
    {
      name: MY_UPLOADS,
      key: generateKeyFromString(MY_UPLOADS),
      tableColumns: [...myUploadsColumns, GenericActions({ actions })],
      processEndpointResult: processMediaInfo,
      endpoint: getMediaList,
      editRowFunc: goToMediaEdit,
      playRowFunc: goToMediaPlay,
      endpointParameters: mediaParameters,
    },
  ];

  const headerInfo = {
    withoutHeader: false,
    ButtonText: 'Upload Media',
    ButtonAction: goToNewMedia,
  };

  if (isDeepLink === 'true') {
    tabs.playRowFunc = goToMediaEmbed;
    headerInfo.withoutHeader = true;
  }

  return (
    <div className="MediaLTIPageContainer">
      {console.log('mediaSelected: ' + mediaSelected)}
      {mediaSelected
        ? redirect({ id: mediaSelected, siteId: mediaParameters.siteId, returnUrl: MEDIA_URL })
        : redirect({ id: mediaSelected, siteId: mediaParameters.siteId, returnUrl: MEDIA_URL })}
      <MediaFeedContainer tabs={tabs} headerInfo={headerInfo} isLti />
    </div>
  );
};

MediaLTIPagecomponent.propTypes = {
  mediaParameters: PropTypes.shape({
    ownerId: PropTypes.string,
    siteId: PropTypes.string,
    token: PropTypes.string,
  }).isRequired,
};

export default MediaLTIPage;
