import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';

import './_style.scss';

const ButtonPrimary = ({
  text,
  theme,
  onClick,
  iconLeft,
  iconRight,
  disabled,
  type,
  size,
  htmlType,
}) => {
  const classname = `ButtonPrimary ${theme}`;
  return (
    <Button
      type={type}
      size={size}
      htmltype={htmlType}
      className={classname}
      onClick={onClick}
      disabled={disabled}
    >
      <p>
        {iconLeft} {text} {iconRight}
      </p>
    </Button>
  );
};

ButtonPrimary.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string.isRequired,
  theme: PropTypes.string,
  onClick: PropTypes.func,
  iconLeft: PropTypes.node,
  iconRight: PropTypes.node,
  type: PropTypes.string,
  htmlType: PropTypes.string,
  size: PropTypes.string,
};

ButtonPrimary.defaultProps = {
  disabled: undefined,
  iconLeft: undefined,
  iconRight: undefined,
  theme: undefined,
  type: 'primary',
  htmlType: 'button',
  size: 'middle',
  onClick: () => {},
};

export default ButtonPrimary;
