import React, { useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Input } from 'antd';
import { SAVE_CHANGES, CANCEL } from '../../../utils/constants-utils';
import './_style.scss';
import MediaStuctureEditor from '../../molecules/media-structure-editor/media-structure-editor';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import { generateKeyFromString, errorLogger } from '../../../utils/generic-utils';
import { EditModeContext } from '../../../services/providers/edit-mode-context';
import { useRedirect } from '../../router/redirect';
import { RETURN_HELPERS } from '../../../utils/jsons-constants-utils';

const CreateMediaStructure = ({
  title,
  titleLabel,
  returnUrl,
  endpoint,
  tableColumns,
  feedEndpoint,
  processFeedEndpointResults,
}) => {
  const { redirect, setUrlToRedirect } = useRedirect();
  const [mediaStructureItems, setMediaStructureItems] = useState([]);
  const [mediaStructureTitle, setMediaStructureTitle] = useState();
  const { setEditMode } = useContext(EditModeContext);

  useEffect(
    () =>
      mediaStructureItems.length || mediaStructureTitle ? setEditMode(true) : setEditMode(false),
    [mediaStructureTitle, mediaStructureItems]
  );
  const cancel = () => {
    setEditMode(false);
    setUrlToRedirect(returnUrl);
  };

  const submitMediaStructure = async () => {
    const selectedMediaKeys = mediaStructureItems.map((item) => item.key);
    setEditMode(false);
    try {
      await endpoint({ selectedMediaKeys, mediaStructureTitle });
      setUrlToRedirect(returnUrl);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while creating media stucture:', error });
    }
  };

  const removeMediaItem = (item) => {
    const newMediaStructureItems = mediaStructureItems.filter(
      (mediaItem) => mediaItem.id !== item.id
    );
    setMediaStructureItems(newMediaStructureItems);
  };

  return (
    <div>
      {redirect()}
      <div className="Title">{title}</div>
      <div className="CreateMediaStuctureContainer">
        <div className="CreateMediaStuctureFrom">
          <Form
            name={`${titleLabel}form`}
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 16 }}
            autoComplete="off"
          >
            <Form.Item
              label={titleLabel}
              name={generateKeyFromString(titleLabel)}
              rules={[{ required: true, message: `${titleLabel} is required` }]}
              requiredMark={false}
              labelAlign="left"
            >
              <Input
                onChange={(e) => {
                  setMediaStructureTitle(e.target.value);
                }}
              />
            </Form.Item>
          </Form>
        </div>
        <MediaStuctureEditor
          tableColumns={tableColumns}
          MediaItems={mediaStructureItems}
          onMediaAdd={(newItems) => setMediaStructureItems([...mediaStructureItems, ...newItems])}
          feedEndpoint={feedEndpoint}
          removeMediaItem={removeMediaItem}
          processFeedEndpointResults={processFeedEndpointResults}
          modifyDataSourceOrder={(data) => setMediaStructureItems(data)}
          returnUrl={RETURN_HELPERS[returnUrl].url}
        />
      </div>
      <div className="CreateMediaStructureActions">
        <ButtonPrimary
          disabled={!mediaStructureTitle || !mediaStructureItems.length}
          theme="round"
          text={SAVE_CHANGES}
          onClick={submitMediaStructure}
        />
        <ButtonPrimary type="default" theme="round" text={CANCEL} onClick={cancel} />
      </div>
    </div>
  );
};

CreateMediaStructure.propTypes = {
  title: PropTypes.string.isRequired,
  titleLabel: PropTypes.string.isRequired,
  returnUrl: PropTypes.string.isRequired,
  endpoint: PropTypes.func.isRequired,
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  feedEndpoint: PropTypes.func.isRequired,
  processFeedEndpointResults: PropTypes.func,
};

CreateMediaStructure.defaultProps = {
  processFeedEndpointResults: (data) => data,
};

export default CreateMediaStructure;
