import { useSetRecoilState } from 'recoil';
import { userAtom } from '../../authn/userAtom';
import {
  validateAccessToken,
  fetchFromLocalStorage,
  fetchAccessTokenFromRefresh,
  saveUser,
} from '../../authn/api';

const useRefreshToken = () => {
  const setUser = useSetRecoilState(userAtom);

  const refreshJwtToken = async () => {
    const userProfile = await fetchFromLocalStorage('userProfile');
    const { refreshToken } = JSON.parse(userProfile);
    const token = await fetchAccessTokenFromRefresh(refreshToken);
    token.refresh_token = refreshToken;
    // validate the new token
    const validatedUserProfile = await validateAccessToken(token.access_token);
    [validatedUserProfile.sub] = validatedUserProfile.sub.split('@'); // split off @asu.edu
    // save new token into userProfile
    await saveUser({ refreshToken, ...validatedUserProfile });
    setUser({
      isAuthenticated: true,
      ...validatedUserProfile,
      refreshToken,
      accessToken: token.access_token,
    });
    return token.access_token;
  };

  return refreshJwtToken;
};

export default useRefreshToken;
