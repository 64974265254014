import React, { useState, useEffect } from 'react';
import MediaUploader from '../../organisms/media-uploader/media-uploader';

import './_style.scss';

const MediaUploadLTIPage = () => {
  const [userId, setUserId] = useState();
  const [siteId, setSiteId] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setUserId(params.get('userId'));
    setSiteId(params.get('siteId'));
  }, []);

  return (
    <div className="UploadPageLTIContainer">
      <div className="UploadPageTitleContainer">
        <p className="UploadPageTitle">Upload new media</p>
      </div>
      <div className="MediaUploaderContainer">
        <MediaUploader userId={userId} siteId={siteId} isLTI />
      </div>
    </div>
  );
};

export default MediaUploadLTIPage;
