import React, { useContext, useState } from 'react';
import { FolderAddOutlined } from '@ant-design/icons';
import apiCalls from '../../../services/api-calls/all';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import MediaFeedContainer from '../../organisms/media-feed-container/media-feed-container';
import './_style.scss';
import {
  COLLECTIONS,
  ALL_COLLECTIONS,
  MY_COLLECTIONS,
  NEW_COLLECTION,
  SHARED_WITH_ME,
  SHARED_WITH_ME_COLLECTIONS,
  CREATE_COLLECTION_URL,
  EDIT_COLLECTION_URL,
  OPEN_COLLECTION,
  PLATFORM_ADMIN,
  SITE_ADMIN,
} from '../../../utils/constants-utils';
import { generateKeyFromString } from '../../../utils/generic-utils';
import {
  myCollectionsColumns,
  accesibleByMeColumns,
} from '../../molecules/table-headers/collections.table-header';
import { useRedirect } from '../../router/redirect';
import { UserContext } from '../../../services/providers/user-context';
import { SiteContext } from '../../../services/providers/site-context';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';

const CollectionsPage = () => {
  const { user } = useContext(UserContext);
  const { site } = useContext(SiteContext);
  const [selectedCollection, setSelectedCollection] = useState();
  const { redirect, setUrlToRedirect } = useRedirect();
  const axiosPrivate = useAxiosPrivate();
  const { getCollectionsList, listCollectionsWithAccess } = apiCalls(axiosPrivate);
  const processCollectionsInfo = ({ data }) =>
    data.Items.map(({ CollectionId, ownerId, modified, ...rest }) => ({
      id: CollectionId,
      owner: ownerId,
      last_modified: modified,
      ...rest,
    }));
  const getAllCollectionsParameters = {
    siteId: site.SiteId,
  };
  const getCollectionsParameters = {
    userId: user.userId,
    siteId: site.SiteId,
  };
  const getSharedCollectionsParameters = {
    asuriteId: user.userId,
    siteId: site.SiteId,
  };
  const openCollection = ({ id }) => {
    setSelectedCollection(id);
    setUrlToRedirect(EDIT_COLLECTION_URL);
  };
  const goToNewCollection = () => {
    setUrlToRedirect(CREATE_COLLECTION_URL);
  };

  const actions = [{ key: generateKeyFromString(OPEN_COLLECTION), onClick: openCollection }];

  const tabs = [];
  if (user.userRole === PLATFORM_ADMIN || user.userRole === SITE_ADMIN) {
    tabs.push({
      name: ALL_COLLECTIONS,
      key: generateKeyFromString(ALL_COLLECTIONS),
      tableColumns: [...myCollectionsColumns, GenericActions({ actions })],
      processEndpointResult: processCollectionsInfo,
      endpoint: getCollectionsList,
      endpointParameters: getAllCollectionsParameters,
    });
  }
  tabs.push({
    name: MY_COLLECTIONS,
    key: generateKeyFromString(MY_COLLECTIONS),
    tableColumns: [...myCollectionsColumns, GenericActions({ actions })],
    processEndpointResult: processCollectionsInfo,
    endpoint: getCollectionsList,
    endpointParameters: getCollectionsParameters,
  });
  tabs.push({
    name: SHARED_WITH_ME,
    key: generateKeyFromString(SHARED_WITH_ME_COLLECTIONS),
    tableColumns: [...accesibleByMeColumns, GenericActions({ actions })],
    processEndpointResult: processCollectionsInfo,
    endpoint: listCollectionsWithAccess,
    endpointParameters: getSharedCollectionsParameters,
  });

  const headerInfo = {
    title: COLLECTIONS,
    ButtonText: NEW_COLLECTION,
    ButtonIcon: <FolderAddOutlined />,
    ButtonAction: goToNewCollection,
  };
  return (
    <div className="CollectionsPageContainer" tabIndex={0} aria-label="List collections page">
      {selectedCollection ? redirect({ id: selectedCollection }) : redirect()}
      <MediaFeedContainer tabs={tabs} headerInfo={headerInfo} />
    </div>
  );
};

export default CollectionsPage;
