import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import './_style.scss';

const { Title } = Typography;

const BlankSection = ({ title, children, theme }) => (
  <div className={`BlankSectionContainer ${theme}`} aria-label={title} tabIndex={0}>
    {title && (
      <div className="Title">
        <Title level={4}>
          <p>{title}</p>
        </Title>
      </div>
    )}
    <div className="Children">{children}</div>
  </div>
);

BlankSection.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  theme: PropTypes.string,
};

BlankSection.defaultProps = {
  title: undefined,
  theme: undefined,
};

export default BlankSection;
