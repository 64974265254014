import React, { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const ViewportContext = createContext({
  width: window.innerWidth,
});

const ViewportProvider = ({ children }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleResize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return <ViewportContext.Provider value={{ width }}>{children}</ViewportContext.Provider>;
};

ViewportProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { ViewportContext, ViewportProvider };
