/* eslint-disable react/jsx-indent */
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Card } from 'antd';
import { EditOutlined, PlayCircleOutlined } from '@ant-design/icons';
import defaultThumbnail from '../../../images/default-thumbnail.png';

import './_style.scss';

const MediaCard = ({ cardkey, title, owner, created, editFunc, playFunc, isLti }) => (
  <Card
    key={cardkey}
    className="mediaCard"
    cover={<img alt={`${title} thumbnail`} src={defaultThumbnail} />}
    actions={
      isLti
        ? [
            <button key="embed" type="button" onClick={() => playFunc({ key: cardkey })}>
              Embed
            </button>,
          ]
        : [
            <EditOutlined key="edit" onClick={() => editFunc({ key: cardkey })} />,
            <PlayCircleOutlined key="play" onClick={() => playFunc({ key: cardkey })} />,
          ]
    }
  >
    <div className="cardContent">
      <p>Title: {title}</p>
      <p>Owner: {owner}</p>
      <p>Created: {moment(created).format('MMM D, YYYY')}</p>
    </div>
  </Card>
);

MediaCard.propTypes = {
  cardkey: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
  created: PropTypes.number.isRequired,
  editFunc: PropTypes.func.isRequired,
  playFunc: PropTypes.func.isRequired,
  isLti: PropTypes.bool,
};

MediaCard.defaultProps = { isLti: false };

export default MediaCard;
