import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Input, Spin, Form, DatePicker, Button, Switch } from 'antd';
import { errorLogger } from '../../../utils/generic-utils';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import { generateMediaLink } from '../../../utils/urls-utils';
import { WARNING_TITLE, DELETE_LINK } from '../../../utils/constants-utils';
import { DELETE_LINK_WARNING } from '../../../utils/constants-content-messages';
import GenericConfirmModal from '../../atoms/generic-confirm-modal/generic-confirm-modal';

import './_style.scss';

const SharedLink = ({
  title,
  wasShareLinkGeneratedEndpoint,
  storeGenerateShareLinkEndpoint,
  deleteShareLinkEndpoint,
  isPrivate,
  structureId,
  siteId,
  userId,
  embededdURL,
}) => {
  const [sharedLink, setSharedLink] = useState();
  const [sharedLinksId, setSharedLinksId] = useState();
  const [loading, setLoading] = useState();
  const [hasExpirationDate, setHasExpirationDate] = useState();
  const [expirationDateState, setExpirationDateState] = useState();
  const [deleteLinkModalVisible, setDeleteLinkModalVisible] = useState();

  useEffect(() => {
    wasShareLinkGenerated();
  }, []);

  const wasShareLinkGenerated = async () => {
    setLoading(true);
    try {
      const {
        data: {
          message: { Items },
        },
      } = await wasShareLinkGeneratedEndpoint({
        bundleId: structureId,
        siteId,
        isPrivate,
      });
      if (Items.length) {
        const sharedLinkExpirationDate = Items[0].ExpirationDate;
        setSharedLinksId(Items[0].SharedLinksId);
        setSharedLink(Items[0].SharedURI);
        setExpirationDateState(
          sharedLinkExpirationDate ? moment.unix(sharedLinkExpirationDate) : undefined
        );
      }
    } catch (error) {
      errorLogger({
        loggedMessage: 'Error while searching for generated SharedLink:',
        error,
      });
    }
    setLoading(false);
  };

  const generateShareLink = async ({ expirationDate }) => {
    const unixExpirationDate = expirationDate ? moment(expirationDate).unix() : undefined;
    setHasExpirationDate(false);
    setLoading(true);
    const shareId = uuidv4();
    const isLinkPrivate = isPrivate ? 'true' : undefined;
    const sharedURI = generateMediaLink(
      window.location.origin,
      embededdURL,
      shareId,
      siteId,
      isLinkPrivate
    );
    try {
      await storeGenerateShareLinkEndpoint({
        bundleId: structureId,
        sharedBy: userId,
        siteId,
        sharedURI,
        isPrivate,
        shareId,
        expirationDate: unixExpirationDate,
      });
      setSharedLinksId(shareId);
      setSharedLink(sharedURI);
      setExpirationDateState(expirationDate);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while storing generated SharedLink:', error });
    }
    setLoading(false);
  };

  const deleteShareLink = async () => {
    setLoading(true);
    setDeleteLinkModalVisible(false);
    try {
      await deleteShareLinkEndpoint({
        sharedLinksId,
        siteId,
      });
      setSharedLinksId();
      setSharedLink();
      setExpirationDateState();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while deleting SharedLink:', error });
    }
    setLoading(false);
  };

  const copyLinkToClipboard = () => {
    navigator.clipboard.writeText(sharedLink);
  };

  return (
    <div className="SharedLink">
      <GenericConfirmModal
        title={`${WARNING_TITLE}${DELETE_LINK}?`}
        content={DELETE_LINK_WARNING}
        isModalVisible={deleteLinkModalVisible}
        handleOk={deleteShareLink}
        handleCancel={() => setDeleteLinkModalVisible(false)}
      />
      <p className="TitleShared">{title}</p>
      {loading ? (
        <Spin />
      ) : (
        <div className="SharedLinkContainer">
          {sharedLink ? (
            <div className="ExistingSharedLink">
              <Input value={sharedLink} disabled />
              <div className="actionButtonsContainer">
                <div className="expirationText">
                  {expirationDateState ? `Expires at: ${expirationDateState}` : 'Never expires'}
                </div>
                <div className="actionButtons">
                  <ButtonPrimary
                    onClick={copyLinkToClipboard}
                    text="Copy link"
                    size="small"
                    theme="round"
                  />
                  <div className="deleteButton">
                    <ButtonPrimary
                      onClick={() => setDeleteLinkModalVisible(true)}
                      text="Delete link"
                      size="small"
                      theme="round"
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="NonExistingSharedLink">
              <div className="ExpirationDateSection">
                Expiration date: <Switch onChange={setHasExpirationDate} />
              </div>
              {hasExpirationDate ? (
                <Form name="generateLinkForm" onFinish={generateShareLink}>
                  <Form.Item
                    name="expirationDate"
                    rules={[{ type: 'object', required: true, message: 'Please select time!' }]}
                  >
                    <DatePicker
                      showTime
                      format="YYYY-MM-DD HH:mm:ss"
                      disabledDate={(current) => moment().add(-1, 'days') >= current}
                    />
                  </Form.Item>
                  <div className="buttonSection">
                    <Form.Item>
                      <Button type="primary" htmlType="submit">
                        Generate Link
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              ) : (
                <div className="buttonSection">
                  <ButtonPrimary onClick={generateShareLink} text="Generate Link" />
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

SharedLink.propTypes = {
  title: PropTypes.string.isRequired,
  wasShareLinkGeneratedEndpoint: PropTypes.func.isRequired,
  storeGenerateShareLinkEndpoint: PropTypes.func.isRequired,
  deleteShareLinkEndpoint: PropTypes.func.isRequired,
  isPrivate: PropTypes.bool,
  structureId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  embededdURL: PropTypes.string.isRequired,
};

SharedLink.defaultProps = { isPrivate: false };

export default SharedLink;
