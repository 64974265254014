export const faqsJson = [
  {
    title: 'What is difference between Media, Collections and Playlists?',
    key: 'media-collections-playlists-difference',
    text: '*Media* is where you can see the full list of everything you have uploaded or been shared with you.  \n\n*Collections* are the organized groupings of media that can be shared with other faculty or staff to collaborate or share ownership.  \n\n*Playlists* are a media player specific format that can be shared publicly via link or integrated with other platforms such as Canvas, Podcasts, and Library.',
  },
  {
    title: 'What is the difference between transcript and subtitle?',
    key: 'transcript-subtitle-difference',
    // eslint-disable-next-line quotes
    text: "*transcript* is a translation of a video or audio's dialogue into text that is available separately. \n\n*subtitle* is a form of transcription where the text is embedded into the video itself.",
  },
  {
    title: 'What is the max upload size for a media file?',
    key: 'maximum-upload-media-size',
    text: 'The maximum size is based on S3 maximum file size of 5 Terabytes.',
  },
  {
    title: 'What is the maximum number of media per playlist or collection?',
    key: 'maximum-media-number-playlists-collections',
    text: 'There is no anticipated maximum number of items in a playlist or collection from the backend DynamoDB table perspective although there may be human usability factors at some size.',
  },
];
