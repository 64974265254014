import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FolderAddOutlined } from '@ant-design/icons';
import apiCalls from '../../../services/api-calls/all';
import MediaFeedContainer from '../../organisms/media-feed-container/media-feed-container';
import { useRedirect } from '../../router/redirect';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import {
  PLAYLISTS,
  MY_PLAYLISTS,
  NEW_PLAYLIST,
  CREATE_PLAYLIST_URL,
  EMBEDDED_PLAYLIST_PLAYER_LTI_URL,
  EMBED,
} from '../../../utils/constants-utils';
import { generateKeyFromString } from '../../../utils/generic-utils';
import { myPlaylistsColumns } from '../../molecules/table-headers/playlists.table-header';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const PlayListsPage = () => {
  const [playlistParameters, setPlaylistParameters] = useState();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const userId = params.get('userId');
    const siteId = params.get('siteId');
    setPlaylistParameters({
      userId,
      siteId,
    });
  }, []);
  return (
    <div>
      {playlistParameters && <PlayListsPagecomponent playlistParameters={playlistParameters} />}
    </div>
  );
};

const PlayListsPagecomponent = ({ playlistParameters }) => {
  const [selectedPlaylist, setSelectedPlaylist] = useState();
  const { redirect, setUrlToRedirect } = useRedirect();
  const axiosPrivate = useAxiosPrivate();
  const { getPlaylistsList } = apiCalls(axiosPrivate);

  const processPlaylistInfo = ({ data }) =>
    data.Items.map(({ PlaylistId, title, ownerId, created }) => ({
      id: PlaylistId,
      title,
      owner: ownerId,
      created,
    }));
  const playPlaylist = ({ id }) => {
    setSelectedPlaylist(id);
    setUrlToRedirect(EMBEDDED_PLAYLIST_PLAYER_LTI_URL);
  };
  const goToNewPlaylist = () => {
    setUrlToRedirect(CREATE_PLAYLIST_URL);
  };

  const actions = [{ key: generateKeyFromString(EMBED), onClick: playPlaylist }];

  const tabs = [
    {
      name: MY_PLAYLISTS,
      key: generateKeyFromString(MY_PLAYLISTS),
      tableColumns: [...myPlaylistsColumns, GenericActions({ actions })],
      processEndpointResult: processPlaylistInfo,
      endpoint: getPlaylistsList,
      endpointParameters: playlistParameters,
    },
  ];
  const headerInfo = {
    title: PLAYLISTS,
    ButtonText: NEW_PLAYLIST,
    ButtonIcon: <FolderAddOutlined />,
    ButtonAction: goToNewPlaylist,
  };
  return (
    <div className="PlayListsPageContainer">
      {selectedPlaylist ? redirect({ id: selectedPlaylist }) : redirect()}
      <MediaFeedContainer tabs={tabs} headerInfo={headerInfo} isLti />
    </div>
  );
};

PlayListsPagecomponent.propTypes = {
  playlistParameters: PropTypes.shape({ userId: PropTypes.string, siteid: PropTypes.string })
    .isRequired,
};

export default PlayListsPage;
