import React from 'react';
import PropTypes from 'prop-types';
import './_style.scss';

const ProfileInfo = ({ name, siteRole, site }) => (
  <div className="ProfileInfo">
    <div className="nameInfo">
      <b>Hello, {name}</b>
    </div>
    <div className="ExtraInfo">
      <p>Role: {siteRole}</p>
      <p>Site: {site}</p>
    </div>
  </div>
);

ProfileInfo.propTypes = {
  name: PropTypes.string.isRequired,
  siteRole: PropTypes.string.isRequired,
  site: PropTypes.string.isRequired,
};

ProfileInfo.defaultProps = {};

export default ProfileInfo;
