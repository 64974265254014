import _ from 'lodash';
import { message } from 'antd';
import { UNEXPECTED_ERROR_MESSAGE } from './constants-content-messages';

export const generateKeyFromString = (str) => str.toLowerCase().replace(/ /g, '_');

// math functions
export const divisionWithRoundedDecimals = (val1, val2) => _.toNumber((val1 / val2).toFixed());
export const divisionRoundedUp = (val1, val2) => Math.ceil(val1 / val2);
export const Percentage = (val1, val2) => ((val1 * 100) / val2).toFixed();

export const sorter = (a, b, key) => {
  let firstElem = a[key];
  let secondElem = b[key];
  if (typeof firstElem === 'string' || firstElem instanceof String) {
    firstElem = firstElem.toLowerCase();
    secondElem = secondElem.toLowerCase();
  }
  if (firstElem > secondElem) {
    return -1;
  }
  if (secondElem > firstElem) {
    return 1;
  }
  return 0;
};

export const errorLogger = ({
  loggedMessage = 'Unexpected Error: ',
  error,
  userMessage = UNEXPECTED_ERROR_MESSAGE,
  notifyUser = true,
  throwError = false,
  customErrorText,
}) => {
  console.error(loggedMessage, error);
  if (notifyUser) message.error(userMessage);
  if (throwError) throw new Error(customErrorText, error);
};
