import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import _ from 'lodash';
import { DETAILS, SHARE, TRANSCRIPT, ACCESS, MEDIA_URL } from '../../../utils/constants-utils';
import { RETURN_HELPERS } from '../../../utils/jsons-constants-utils';
import apiCalls from '../../../services/api-calls/all';
import { useRedirect } from '../../router/redirect';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import MediaFeedHeader from '../../molecules/media-feed-header/media-feed-header';
import MediaDetails from '../../molecules/media-edit-tabs/media-details/media-details';
import MediaShare from '../../molecules/media-edit-tabs/media-share/media-share';
import MediaAccess from '../../molecules/media-edit-tabs/media-access/media-access';
import MediaTranscript from '../../molecules/media-edit-tabs/media-transcript/media-transcript';
import GenericTabs from '../../atoms/generic-tabs/generic-tabs';
import { generateKeyFromString, errorLogger } from '../../../utils/generic-utils';
import { SiteContext } from '../../../services/providers/site-context';
import { EditModeContext } from '../../../services/providers/edit-mode-context';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const MediaEditor = ({ mediaId, returnParameters }) => {
  const tabs = [
    {
      name: DETAILS,
      key: generateKeyFromString(DETAILS),
      component: MediaDetails,
    },
    {
      name: SHARE,
      key: generateKeyFromString(SHARE),
      component: MediaShare,
    },
    {
      name: ACCESS,
      key: generateKeyFromString(ACCESS),
      component: MediaAccess,
    },
    {
      name: TRANSCRIPT,
      key: generateKeyFromString(TRANSCRIPT),
      component: MediaTranscript,
    },
  ];
  const { site } = useContext(SiteContext);
  const { redirect, setUrlToRedirect } = useRedirect();
  const [selectedTab, setSelectedTab] = useState();
  const [originalMedia, setOriginalMedia] = useState();
  const [currentMedia, setCurrentMedia] = useState();
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const { setEditMode } = useContext(EditModeContext);
  const [submitButtonDisabled, setsubmitButtonDisabled] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const { getMediaDetailsById, editMedia } = apiCalls(axiosPrivate);
  const backToPrevious = () => {
    setUrlToRedirect(RETURN_HELPERS[returnParameters.returnUrl].url);
  };
  useEffect(() => {
    if (mediaId) searchMedia();
  }, [mediaId]);
  useEffect(() => {
    setEditMode(unsavedChanges);
  }, [unsavedChanges]);
  useEffect(() => {
    if (tabs) {
      const tabToSelect = selectedTab || tabs[0];
      setSelectedTab(tabToSelect);
    }
  }, [tabs]);
  // If captions or posters are uploaded, we want to store
  // them on dynamo even if the user does not save changes.
  useEffect(() => {
    let save = false;
    if (currentMedia && originalMedia) {
      if (currentMedia.posters && originalMedia.posters) {
        if (currentMedia.posters.length !== originalMedia.posters.length) {
          save = true;
        }
      }
      if (currentMedia.captions && originalMedia.captions) {
        if (currentMedia.captions.length !== originalMedia.captions.length) {
          save = true;
        }
      }
    }
    if (save) {
      saveUploadedAssets();
    }
  }, [currentMedia]);

  const saveUploadedAssets = async () => {
    try {
      const newCaptions = currentMedia.captions.map((item) => ({
        fileName: item.fileName,
        isDefault: item.isDefault,
      }));
      await editMedia({
        siteId: site.SiteId,
        mediaId: currentMedia.MediaId,
        description: originalMedia.description,
        title: originalMedia.title,
        captions: newCaptions,
        posters: currentMedia.posters,
        copyright: originalMedia.copyright,
        reuse: originalMedia.reuse,
      });
      setOriginalMedia({
        ...originalMedia,
        posters: currentMedia.posters,
        captions: currentMedia.captions,
      });
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while editing media:', error });
    }
  };

  const postEditMedia = async () => {
    // Since currentMedia.captions might have some objects with 'captionsUrl'
    // we need to get rid of those properties in order to match serverless schema
    try {
      const newCaptions = currentMedia.captions.map((item) => ({
        fileName: item.fileName,
        isDefault: item.isDefault,
      }));
      await editMedia({
        siteId: site.SiteId,
        mediaId: currentMedia.MediaId,
        description: currentMedia.description,
        title: currentMedia.title,
        captions: newCaptions,
        posters: currentMedia.posters,
        copyright: currentMedia.copyright,
        reuse: currentMedia.reuse,
      });
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while editing media:', error });
    }
  };
  const searchMedia = async () => {
    try {
      const mediaDetails = await getMediaDetailsById({
        mediaId,
        siteId: site.SiteId,
      });
      setOriginalMedia(mediaDetails.data);
      setCurrentMedia(mediaDetails.data);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while searching media details:', error });
    }
  };
  // Since updated object is already on this component's state,
  // we don't need to get any parameter from childrens.
  const onSubmit = async () => {
    setsubmitButtonDisabled(true);
    await postEditMedia();
    searchMedia();
    setUnsavedChanges(false);
    setsubmitButtonDisabled(false);
  };
  const updateCurrentMedia = (newCurrentMedia) => {
    setCurrentMedia(newCurrentMedia);
    const mediaHasChange = !_.isEqual(newCurrentMedia, originalMedia);
    if (mediaHasChange) setUnsavedChanges(true);
    else setUnsavedChanges(false);
  };
  return currentMedia && selectedTab ? (
    <div className="MediaEditorContainer">
      {returnParameters.mediaStructureId
        ? redirect({ id: returnParameters.mediaStructureId })
        : redirect()}
      <MediaFeedHeader
        title={originalMedia.title}
        ButtonAction={backToPrevious}
        ButtonText={RETURN_HELPERS[returnParameters.returnUrl].buttonText}
      />
      <GenericTabs
        onChange={(key) => setSelectedTab(tabs[key])}
        panels={tabs.map(({ name }, key) => ({
          name,
          key,
        }))}
      >
        <selectedTab.component
          currentMedia={currentMedia}
          siteId={site.SiteId}
          submitChanges={onSubmit}
          updateCurrentMedia={updateCurrentMedia}
        />
        {selectedTab.name !== ACCESS && (
          <div className="SubmitButton">
            <ButtonPrimary
              text="Save changes"
              onClick={onSubmit}
              disabled={submitButtonDisabled}
              theme="round"
            />
          </div>
        )}
      </GenericTabs>
    </div>
  ) : (
    <Spin />
  );
};

MediaEditor.propTypes = {
  mediaId: PropTypes.string.isRequired,
  returnParameters: PropTypes.shape({
    returnUrl: PropTypes.string.isRequired,
    mediaStructureId: PropTypes.string,
  }),
};

MediaEditor.defaultProps = {
  returnParameters: {
    returnUrl: MEDIA_URL,
    mediaStructureId: undefined,
  },
};

export default MediaEditor;
