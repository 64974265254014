import React from 'react';
import moment from 'moment';
import { VideoCameraOutlined } from '@ant-design/icons';
import { createGenericColumn } from '../../../utils/table-headers-utils';

export const allUploadsColumns = [
  {
    ...createGenericColumn('Title'),
    render: (fileName) => (
      <div aria-label={fileName} tabIndex={0}>
        <VideoCameraOutlined />
        {` ${fileName}`}
      </div>
    ),
    width: '50%',
  },
  createGenericColumn('Owner'),
  {
    ...createGenericColumn('Created'),
    render: (timestamp) => moment(timestamp).format('MMM D, YYYY'),
    defaultSortOrder: 'ascend',
  },
];

export const myUploadsColumns = [
  {
    ...createGenericColumn('Title'),
    render: (fileName) => (
      <div aria-label={fileName} tabIndex={0}>
        <VideoCameraOutlined />
        {` ${fileName}`}
      </div>
    ),
    width: '50%',
  },
  createGenericColumn('Owner'),
  {
    ...createGenericColumn('Created'),
    render: (timestamp) => moment(timestamp).format('MMM D, YYYY'),
    defaultSortOrder: 'ascend',
  },
];

export const sharedWithMeColumns = [
  {
    ...createGenericColumn('Title'),
    render: (fileName) => (
      <div>
        <VideoCameraOutlined aria-label={fileName} tabIndex={0} />
        {` ${fileName}`}
      </div>
    ),
    width: '50%',
  },
  createGenericColumn('Owner'),
  {
    ...createGenericColumn('Created'),
    render: (timestamp) => moment(timestamp).format('MMM D, YYYY'),
    defaultSortOrder: 'ascend',
  },
];

export const pendingUploadsColumns = [
  { ...createGenericColumn('File name'), width: '50%' },
  createGenericColumn('Owner'),
  {
    ...createGenericColumn('Upload date'),
    render: (timestamp) => moment(timestamp).format('MMM D, YYYY'),
    defaultSortOrder: 'ascend',
  },
  createGenericColumn('Upload progress'),
];
