import { atom } from 'recoil';

export const AuthnStatus = {
  SignIn: 'signIn',
  Loading: 'loading',
};

export const authnStatusAtom = atom({
  key: 'authnStatusAtom',
  default: AuthnStatus.Loading,
});
