import React, { useState, useEffect, useContext } from 'react';
import ManageUsersContainer from '../../organisms/manage-users-container/manage-users-container';
import { UserContext } from '../../../services/providers/user-context';
import { useRedirect } from '../../router/redirect';
import { SITES_ADMIN_URL } from '../../../utils/constants-utils';
import './_style.scss';

const ManageUsersPage = () => {
  const [currentSite, setCurrentSite] = useState();
  const { user } = useContext(UserContext);
  const { redirect, setUrlToRedirect } = useRedirect();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setCurrentSite({ id: params.get('id'), name: params.get('name') });
  }, []);

  return (
    <div className="ManageUsersPageContainer">
      {redirect()}
      <ManageUsersContainer
        currentSite={currentSite}
        userRole={user.userRole}
        returnFunc={() => setUrlToRedirect(SITES_ADMIN_URL)}
        returnText="Go back to Sites List"
      />
    </div>
  );
};

export default ManageUsersPage;
