import React from 'react';
import PropTypes from 'prop-types';
import {
  PlayCircleOutlined,
  EditOutlined,
  LoginOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined,
  ToTopOutlined,
} from '@ant-design/icons';
import { OPEN_COLLECTION, OPEN_PLAYLIST } from '../../../../utils/constants-utils';
import { createGenericColumn } from '../../../../utils/table-headers-utils';
import './_style.scss';

const buttonContentByKey = (theme = 'primary') => ({
  edit: (
    <div className={theme}>
      <EditOutlined aria-label="" /> <b>Edit</b>
    </div>
  ),
  play: (
    <div className={theme}>
      <PlayCircleOutlined aria-label="" /> <b>Play</b>
    </div>
  ),
  open_collection: (
    <div className={theme}>
      <b>{OPEN_COLLECTION}</b>
    </div>
  ),
  open_playlist: (
    <div className={theme}>
      <b>{OPEN_PLAYLIST}</b>
    </div>
  ),
  delete: (
    <div className={theme}>
      <b>remove</b>
    </div>
  ),
  enter: (
    <div className={theme}>
      <LoginOutlined /> <b>Enter</b>
    </div>
  ),
  manage_users: (
    <div className={theme}>
      <b>Manage Users</b>
    </div>
  ),
  activate: (
    <div className={theme}>
      <PlusCircleOutlined /> <b>Activate</b>
    </div>
  ),
  deactivate: (
    <div className={theme}>
      <MinusCircleOutlined /> <b>Deactivate</b>
    </div>
  ),
  update: (
    <div className={theme}>
      <ToTopOutlined /> <b>Update</b>
    </div>
  ),
  embed: 'Embed',
});

const GenericActions = ({ actions }) => {
  const generateActionableButtons = (record) =>
    actions.map(({ key, theme, onClick, showFunc, showField, showButton }) => {
      const showable = showField && showFunc ? showFunc(record[showField]) : true;
      return showable ? (
        <button
          key={key}
          className={showButton ? key : `${key} notShow`}
          type="button"
          onClick={() => onClick(record)}
        >
          {buttonContentByKey(theme)[key]}
        </button>
      ) : (
        <div hidden />
      );
    });
  const actionsColumn = {
    ...createGenericColumn('Actions'),
    title: undefined,
    sorter: undefined,
    render: (text, record) => (
      <div className="ActionsContainer">{generateActionableButtons(record)}</div>
    ),
  };
  return actionsColumn;
};

GenericActions.propTypes = {
  actions: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default GenericActions;
