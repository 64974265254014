import React, { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { AuthnStatus, authnStatusAtom } from './authnStatus';
import { userAtom } from './userAtom';
import Loading from '../components/Login/Loading';
import LogoutForm from '../components/organisms/logout-form/logout-form';
import {
  validateAccessToken,
  fetchFromLocalStorage,
  fetchAccessTokenFromRefresh,
  saveUser,
  authorize,
  generateRandomValue,
  postUrlEncoded,
  logout,
} from './api';
import { errorLogger } from '../utils/generic-utils';
import config from './sso-config';

export const AuthnCAS = () => {
  const [authnStatus, setAuthnStatus] = useRecoilState(authnStatusAtom);
  const setUser = useSetRecoilState(userAtom);

  const reauthenticate = async (token) => {
    try {
      if (token) {
        // we have access token from sso endpoint.
        // validate it, split the ASUriteID out of the sub field.
        // save token and user info into browser storage
        const authResult = await validateAccessToken(token.access_token);
        [authResult.sub] = authResult.sub.split('@'); // split off @asu.edu

        if (authResult) {
          await saveUser({ refreshToken: token.refresh_token, ...authResult });
          setUser({ isAuthenticated: true, ...authResult, accessToken: token.access_token });
        } else {
          throw new Error('Unable to validate access token, please try to sign in again.');
        }
      } else if (hasAuthParams()) {
        // we have received authorization code in query params back from the endpoint
        // pull it out of the query param and send it back to endpoint to get authn token
        const codeMatch = window.location.href.match('[?#&]code=([^&]*)');
        const newToken = await postUrlEncoded(config.tokenURL, codeMatch[1], 'code');
        if (!newToken) {
          // Handle Bad Authentication code being sent to SSO end point
          // First, perform a logout (which clears local auth token from browser)
          // to ensure logout status
          logout();
          // Redirect user to the logout page and set badcode=true so Logout form
          // displays correct message to user
          window.location.replace('/?logout=true&badcode=true');
        }
        await reauthenticate(newToken);
      } else {
        // start sign in process
        setAuthnStatus(AuthnStatus.SignIn);
      }
    } catch (error) {
      errorLogger({ error, notifyUser: false });
    }
  };

  useEffect(() => {
    async function autoSignIn() {
      try {
        setAuthnStatus(AuthnStatus.Loading);
        // check if we already have authenticated by fetching userProfile.
        const userProfile = await fetchFromLocalStorage('userProfile');

        if (
          userProfile &&
          Object.keys(userProfile).length !== 0 &&
          Object.getPrototypeOf(userProfile) !== Object.prototype
        ) {
          // we have a local userProfile.  Pull the refresh token out of it.
          // send to endpoint to recieve new authn token.
          const { refreshToken } = JSON.parse(userProfile);
          const accessToken = await fetchAccessTokenFromRefresh(refreshToken);
          accessToken.refresh_token = refreshToken;
          // validate the new token
          const validatedUserProfile = await validateAccessToken(accessToken.access_token);
          [validatedUserProfile.sub] = validatedUserProfile.sub.split('@'); // split off @asu.edu
          // save new token into userProfile
          await saveUser({ refreshToken, ...validatedUserProfile });
          setUser({
            isAuthenticated: true,
            ...validatedUserProfile,
            refreshToken,
            accessToken: accessToken.access_token,
          });
        } else {
          // check again
          await reauthenticate();
        }
      } catch (error) {
        errorLogger({ error, notifyUser: false });
        await reauthenticate();
      }
    }
    autoSignIn();
  }, []);

  const hasAuthParams = () => {
    const codeMatch = window.location.href.match('[?#&]code=([^&]*)');

    return codeMatch;
  };

  const hasLogoutParams = () => {
    const codeMatch = window.location.href.match('[?]logout=true');
    if (codeMatch) {
      return true;
    }
    return false;
  };

  let content;
  if (authnStatus === AuthnStatus.SignIn && !hasLogoutParams()) {
    const state = generateRandomValue();
    const nonce = generateRandomValue();
    sessionStorage.setItem('state', state);
    sessionStorage.setItem('nonce', nonce);
    const authUrl = authorize(state, nonce);
    window.location.replace(authUrl);
  } else if (hasLogoutParams()) {
    // user clicked logout, send them to /logout route
    content = <LogoutForm />;
  } else if (authnStatus === AuthnStatus.Loading) {
    content = <Loading />;
  } else {
    throw new Error('unknown AuthnStatus');
  }

  return content;
};
