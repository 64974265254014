import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Spin } from 'antd';
import MediaCard from '../../atoms/media-card/media-card';

import './_style.scss';

const MEDIA_PER_ROW = 3;

const Grid = ({ data, loading, editFunc, playFunc, isLti }) => {
  const [mediaCards, setMediaCards] = useState([]);

  const turnColumnToMediaColumn = (column) =>
    column.map(({ key, title, owner, created }) => (
      <MediaCard
        key={key}
        cardkey={key}
        title={title}
        owner={owner}
        created={created}
        editFunc={editFunc}
        playFunc={playFunc}
        isLti={isLti}
      />
    ));

  useEffect(() => {
    if (data) {
      const shadowData = [...data];
      setData(shadowData);
    }
  }, [data]);

  const setData = (shadowData) => {
    const rows = [];
    const columns = [];
    while (shadowData.length > 0) {
      const row = shadowData.splice(0, MEDIA_PER_ROW);
      rows.push(row);
    }
    for (let i = 0; i < MEDIA_PER_ROW; i++) {
      const column = rows.map((row) => (row.length >= i ? row[i] : undefined));
      const filteredColumn = column.filter((e) => !!e);
      columns.push(filteredColumn);
    }

    const newMediaCards = columns.map((column, index) => (
      <Col key={index.toString()} span={8}>
        <div>{turnColumnToMediaColumn(column)}</div>
      </Col>
    ));
    setMediaCards(newMediaCards);
  };

  if (loading) return <Spin />;

  return (
    <div className="Grid">
      <Row gutter={[16, 24]}>{mediaCards}</Row>
    </div>
  );
};

Grid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  loading: PropTypes.bool,
  editFunc: PropTypes.func.isRequired,
  playFunc: PropTypes.func.isRequired,
  isLti: PropTypes.bool,
};

Grid.defaultProps = { data: [], loading: false, isLti: false };

export default Grid;
