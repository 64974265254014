import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';

import './_style.scss';

const FilterForm = ({ filterFunc, filterDisable, isFiltered, filterPlaceholder }) => {
  const [form] = Form.useForm();
  const [filterValue, setFilterValue] = useState();

  const submitForm = ({ value }) => {
    filterFunc(value, form);
  };

  return (
    <div className="filterForm">
      <Form form={form} name="filterForm" onFinish={submitForm} layout="inline">
        <Form.Item name="value">
          <Input
            placeholder={filterPlaceholder}
            onChange={(event) => setFilterValue(event.target.value)}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={filterDisable}>
            {isFiltered === filterValue ? 'Clear Filter' : 'Filter'}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

FilterForm.propTypes = {
  filterFunc: PropTypes.func,
  filterDisable: PropTypes.bool,
  isFiltered: PropTypes.string,
  filterPlaceholder: PropTypes.string,
};

FilterForm.defaultProps = {
  filterDisable: false,
  filterFunc: () => {},
  isFiltered: false,
  filterPlaceholder: '',
};

export default FilterForm;
