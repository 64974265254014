import {
  LANDING_URL,
  LANDING_LTI_URL,
  HOME_URL,
  MEDIA_URL,
  MEDIA_LTI_URL,
  MEDIA_UPLOAD_URL,
  MEDIA_UPLOAD_LTI_URL,
  MEDIA_EDIT_URL,
  MEDIA_PLAYER_URL,
  COLLECTIONS_URL,
  CREATE_COLLECTION_URL,
  EDIT_COLLECTION_URL,
  PLAYLISTS_URL,
  PLAYLISTS_LTI_URL,
  PLAYLIST_PLAYER_URL,
  EMBEDDED_PLAYLIST_PLAYER_URL,
  CREATE_PLAYLIST_URL,
  CREATE_PLAYLIST_LTI_URL,
  EDIT_PLAYLIST_URL,
  HELP_URL,
  RELEASE_NOTES_URL,
  FAQ_URL,
  SETTINGS_URL,
  EMBEDDED_PLAYER_URL,
  MANAGE_USERS_URL,
  HEALTH_URL,
  VERSION_URL,
  AUTHZ_LIST_URL,
  AUTHZ_LIST_LTI_URL,
  LANDING_KEY_PAGE,
  LANDING_LTI_KEY_PAGE,
  HOME_KEY_PAGE,
  MEDIA_KEY_PAGE,
  MEDIA_LTI_KEY_PAGE,
  MEDIA_UPLOAD_KEY_PAGE,
  MEDIA_UPLOAD_LTI_KEY_PAGE,
  MEDIA_EDIT_KEY_PAGE,
  MEDIA_PLAYER_KEY_PAGE,
  COLLECTIONS_KEY_PAGE,
  CREATE_COLLECTION_KEY_PAGE,
  EDIT_COLLECTION_KEY_PAGE,
  PLAYLISTS_KEY_PAGE,
  PLAYLISTS_LTI_KEY_PAGE,
  PLAYLIST_PLAYER_KEY_PAGE,
  EMBEDDED_PLAYLIST_PLAYER_KEY_PAGE,
  CREATE_PLAYLIST_KEY_PAGE,
  CREATE_PLAYLIST_LTI_KEY_PAGE,
  EDIT_PLAYLIST_KEY_PAGE,
  SITES_ADMIN_URL,
  SITES_ADMIN_KEY_PAGE,
  SITES_LIST_URL,
  SITES_LIST_KEY_PAGE,
  HELP_KEY_PAGE,
  RELEASE_NOTES_KEY_PAGE,
  FAQ_KEY_PAGE,
  SETTINGS_KEY_PAGE,
  EMBEDDED_PLAYER_KEY_PAGE,
  MANAGE_USERS_KEY_PAGE,
  HEALTH_KEY_PAGE,
  VERSION_KEY_PAGE,
  AUTHZ_LIST_KEY_PAGE,
  AUTHZ_LIST_LTI_KEY_PAGE,
  BACK_TO_MEDIA,
  BACK_TO_PLAYLIST,
  BACK_TO_COLLECTION,
  BACK_TO_NEW_PLAYLIST,
  BACK_TO_NEW_COLLECTION,
  EMBEDDED_PLAYLIST_PLAYER_LTI_URL,
  EMBEDDED_PLAYER_LTI_URL,
  EMBEDDED_PLAYLIST_PLAYER_LTI_KEY_PAGE,
  EMBEDDED_PLAYER_LTI_KEY_PAGE,
} from './constants-utils';

export const PAGES_KEYS_BY_PATH = {
  [LANDING_URL]: LANDING_KEY_PAGE,
  [LANDING_LTI_URL]: LANDING_LTI_KEY_PAGE,
  [HOME_URL]: HOME_KEY_PAGE,
  [MEDIA_URL]: MEDIA_KEY_PAGE,
  [MEDIA_LTI_URL]: MEDIA_LTI_KEY_PAGE,
  [MEDIA_UPLOAD_URL]: MEDIA_UPLOAD_KEY_PAGE,
  [MEDIA_UPLOAD_LTI_URL]: MEDIA_UPLOAD_LTI_KEY_PAGE,
  [MEDIA_EDIT_URL]: MEDIA_EDIT_KEY_PAGE,
  [MEDIA_PLAYER_URL]: MEDIA_PLAYER_KEY_PAGE,
  [COLLECTIONS_URL]: COLLECTIONS_KEY_PAGE,
  [CREATE_COLLECTION_URL]: CREATE_COLLECTION_KEY_PAGE,
  [EDIT_COLLECTION_URL]: EDIT_COLLECTION_KEY_PAGE,
  [PLAYLISTS_URL]: PLAYLISTS_KEY_PAGE,
  [PLAYLISTS_LTI_URL]: PLAYLISTS_LTI_KEY_PAGE,
  [PLAYLIST_PLAYER_URL]: PLAYLIST_PLAYER_KEY_PAGE,
  [EMBEDDED_PLAYLIST_PLAYER_URL]: EMBEDDED_PLAYLIST_PLAYER_KEY_PAGE,
  [EMBEDDED_PLAYLIST_PLAYER_LTI_URL]: EMBEDDED_PLAYLIST_PLAYER_LTI_KEY_PAGE,
  [EMBEDDED_PLAYER_LTI_URL]: EMBEDDED_PLAYER_LTI_KEY_PAGE,
  [CREATE_PLAYLIST_URL]: CREATE_PLAYLIST_KEY_PAGE,
  [CREATE_PLAYLIST_LTI_URL]: CREATE_PLAYLIST_LTI_KEY_PAGE,
  [EDIT_PLAYLIST_URL]: EDIT_PLAYLIST_KEY_PAGE,
  [HELP_URL]: HELP_KEY_PAGE,
  [SITES_ADMIN_URL]: SITES_ADMIN_KEY_PAGE,
  [SITES_LIST_URL]: SITES_LIST_KEY_PAGE,
  [RELEASE_NOTES_URL]: RELEASE_NOTES_KEY_PAGE,
  [FAQ_URL]: FAQ_KEY_PAGE,
  [SETTINGS_URL]: SETTINGS_KEY_PAGE,
  [EMBEDDED_PLAYER_URL]: EMBEDDED_PLAYER_KEY_PAGE,
  [MANAGE_USERS_URL]: MANAGE_USERS_KEY_PAGE,
  [HEALTH_URL]: HEALTH_KEY_PAGE,
  [VERSION_URL]: VERSION_KEY_PAGE,
  [AUTHZ_LIST_URL]: AUTHZ_LIST_KEY_PAGE,
  [AUTHZ_LIST_LTI_URL]: AUTHZ_LIST_LTI_KEY_PAGE,
};

export const RETURN_HELPERS = {
  [MEDIA_URL]: { url: MEDIA_URL, buttonText: BACK_TO_MEDIA },
  [PLAYLISTS_URL]: { url: EDIT_PLAYLIST_URL, buttonText: BACK_TO_PLAYLIST },
  [COLLECTIONS_URL]: { url: EDIT_COLLECTION_URL, buttonText: BACK_TO_COLLECTION },
  [EDIT_COLLECTION_URL]: { url: CREATE_COLLECTION_URL, buttonText: BACK_TO_NEW_COLLECTION },
  [EDIT_PLAYLIST_URL]: { url: CREATE_PLAYLIST_URL, buttonText: BACK_TO_NEW_PLAYLIST },
};
