import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import Plyr from 'plyr-react';
import apiCalls from '../../../services/api-calls/all';
import { AUDIO_FOLDER } from '../../../utils/constants-utils';
import { errorLogger } from '../../../utils/generic-utils';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import 'plyr-react/dist/plyr.css';
import './_style.scss';

const VIDEO = 'video';
const AUDIO = 'audio';

const MediaPlayer = ({
  id,
  siteId,
  showTitle,
  onEnded,
  publicShareId,
  publicPlaylistId,
  isLti,
}) => {
  const ref = useRef();
  const [media, setMedia] = useState();
  const [mediaType, setMediaType] = useState(VIDEO);
  const [refSet, setRefSet] = useState();
  const axiosPrivate = useAxiosPrivate();
  const { getMediaOnS3ById, getPublicMediaOnS3ByShareId } = apiCalls(axiosPrivate);

  useEffect(() => {
    if (id || publicShareId) searchMedia();
  }, [id, publicShareId, publicPlaylistId]);

  useEffect(() => {
    if (ref.current && refSet !== id) {
      setRefSet(id);
      ref?.current?.plyr?.on('ended', endFunc);
    }
  });

  const endFunc = () => {
    ref?.current?.plyr?.off('ended', endFunc);
    onEnded(id);
  };

  const searchMedia = async () => {
    try {
      const { data } =
        isLti || publicShareId || (id && publicPlaylistId)
          ? await getPublicMediaOnS3ByShareId({
              mediaId: id,
              isLti,
              playlistId: publicPlaylistId,
              shareId: publicShareId,
              siteId,
            })
          : await getMediaOnS3ById({ fileId: id, siteId });

      const {
        videoInfo: { fileKey },
      } = data;
      const fileType = fileKey.includes(`/${AUDIO_FOLDER}/`) ? AUDIO : VIDEO;
      setMediaType(fileType);
      setMedia(data);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while searching Media url:', error });
    }
  };

  const mediaSource = () => ({
    type: mediaType,
    sources: [
      {
        src: media.url,
      },
    ],
    tracks: media.captionsUrl
      ? [
          {
            kind: 'captions',
            label: 'Captions',
            src: media.captionsUrl,
            default: false,
          },
        ]
      : [],
  });

  return media ? (
    <div className="MediaPlayerContainer">
      {showTitle && (
        <div className="MediaPlayerTitleContainer">
          <p className="Title">{media.videoInfo.title}</p>
        </div>
      )}
      <div className="MediaPlayerFlex">
        <div className="MediaPlayer">
          {id && <Plyr source={mediaSource()} crossOrigin="anonymous" ref={ref} />}
        </div>
      </div>
    </div>
  ) : (
    <Spin />
  );
};

MediaPlayer.propTypes = {
  // the mediaId to play
  id: PropTypes.string,
  // the public SharedLinkId for the media in a public link
  publicShareId: PropTypes.string,
  // the public playlist id used to check media is really in a shared public link
  publicPlaylistId: PropTypes.string,
  // the site id the media belongs to
  siteId: PropTypes.string.isRequired,
  // the title of the show to display in the player
  showTitle: PropTypes.bool,
  // callback to execute on media playback end
  onEnded: PropTypes.func,
  // isLti used for embedded players
  isLti: PropTypes.bool,
};

MediaPlayer.defaultProps = {
  id: undefined,
  publicShareId: undefined,
  publicPlaylistId: undefined,
  showTitle: false,
  isLti: false,
  onEnded: () => {},
};

export default MediaPlayer;
