import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import './_style.scss';

const { Dragger } = Upload;

const AssetDragger = ({ text, multiple, onChange, disabled, icon }) => (
  <Dragger
    onChange={onChange}
    multiple={multiple}
    beforeUpload={() => false}
    disabled={disabled}
    showUploadList={false}
    className="Dragger"
  >
    <span className="ant-upload-drag-icon">{icon}</span>
    <span className="ant-upload-text">{text}</span>
  </Dragger>
);

AssetDragger.propTypes = {
  text: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  icon: PropTypes.shape({}),
};

AssetDragger.defaultProps = {
  text: undefined,
  multiple: false,
  disabled: false,
  icon: <InboxOutlined />,
};

export default AssetDragger;
