import React from 'react';
import { Button } from 'antd';
import { useRedirect } from '../../router/redirect';
import { HELP_URL, LANDING_URL } from '../../../utils/constants-utils';
import CommentAltExcalmation from '../../../images/comment-alt-exclamation.png';

import './_style.scss';

const Error404Page = () => {
  const { redirect, setUrlToRedirect } = useRedirect();

  return (
    <div className="Error404Page">
      {redirect()}
      <div className="Error404PageContainer">
        <div className="ImageContainer">
          <img src={CommentAltExcalmation} alt="" />
        </div>
        <div className="ContentContainer">
          <div className="TitleContainer">Oops! It looks like you can’t be here.</div>
          <div className="DescriptionContainer">
            <p>Unfortunately, you don’t have the permissions required to access this page.</p> Go
            back
            {' to '}
            <Button type="link" onClick={() => setUrlToRedirect(HELP_URL)}>
              asu.edu
            </Button>
            {' or '}
            <Button type="link" onClick={() => setUrlToRedirect(LANDING_URL)}>
              Sign in
            </Button>
            {' .'}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404Page;
