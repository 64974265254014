export const createQueryString = (query) =>
  Object.keys(query)
    .map((key) => `${key}=${query[key] || ''}`)
    .join('&');

export const addQueryString = (url, query) => {
  const queryStringParams = createQueryString(query || {});
  return query ? `${url}?${queryStringParams}` : url;
};

export const generateMediaLink = (origin, mediaPlayerUrl, id, siteId, isPrivate) => {
  const queryString = createQueryString({ id, siteId, isPrivate });
  return `${origin}${mediaPlayerUrl}?${queryString}`;
};

export const generateMediaIFrame = (origin, embeddedPlayerUrl, id, siteId) => {
  const queryString = createQueryString({ id, siteId });
  return `<iframe src="${origin}${embeddedPlayerUrl}?${queryString}" width="640" height="360" frameholder="0" allow="autoplay"></iframe>`;
};
