import React, { useState, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { createQueryString } from '../../utils/urls-utils';
import { EditModeContext } from '../../services/providers/edit-mode-context';
import GenericConfirmModal from '../atoms/generic-confirm-modal/generic-confirm-modal';
import { UNSAVED_CHANGES } from '../../utils/constants-utils';
import { UNSAVED_CHANGES_WARNING } from '../../utils/constants-content-messages';

export const useRedirect = () => {
  const [urlToRedirect, setUrlToRedirect] = useState();
  const [rejectChanges, setRejectChanges] = useState();
  const { editMode, setEditMode } = useContext(EditModeContext);

  const ignoreEditMode = () => {
    setEditMode(false);
    setRejectChanges(true);
  };

  const redirect = (queryParameters = {}, hash) => {
    let processedQueryParameters = `?${createQueryString(queryParameters)}`;
    if (hash) processedQueryParameters = `${processedQueryParameters}#${hash}`;
    if (urlToRedirect) {
      if (editMode && !rejectChanges)
        return (
          <GenericConfirmModal
            title={UNSAVED_CHANGES}
            content={UNSAVED_CHANGES_WARNING}
            isModalVisible
            closable={false}
            handleOk={ignoreEditMode}
            handleCancel={() => setUrlToRedirect(undefined)}
          />
        );
      return (
        <Redirect
          push
          to={{
            pathname: urlToRedirect,
            search: processedQueryParameters,
          }}
        />
      );
    }
  };

  return { redirect, setUrlToRedirect };
};

export default useRedirect;
