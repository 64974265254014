import React from 'react';
import { Spin } from 'antd';
import './_style.scss';

const Loading = () => (
  <div className="LoadingForm">
    <div className="font">Loading...</div>
    <Spin className="center" />
  </div>
);

export default Loading;
