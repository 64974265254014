import React, { useContext, useState, useEffect } from 'react';
import PlaylistPlayer from '../../organisms/playlist-player/playlist-player';
import { SiteContext } from '../../../services/providers/site-context';
import './_style.scss';

const PlaylistPlayerPage = () => {
  const { site } = useContext(SiteContext);
  const [playlistId, setPlaylistId] = useState();
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setPlaylistId(params.get('id'));
  }, []);
  return (
    <div className="PlayListsPlayerPage">
      <PlaylistPlayer playlistId={playlistId} siteId={site.SiteId} />
    </div>
  );
};

export default PlaylistPlayerPage;
