// import { useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import axios from 'axios';
import useRefreshToken from './refreshHook';
import { accessTokenSelector } from '../../authn/userAtom';
import { logout } from '../../authn/api';
import { errorLogger } from '../../utils/generic-utils';

const defaultAxiosProperties = {
  timeout: 18000000,
};

const useAxiosPrivate = (axiosProperties = {}) => {
  // create unique client to prevent component conflicts with mount/unmount
  const axiosClient = axios.create({
    ...defaultAxiosProperties,
    ...axiosProperties,
  });

  const refresh = useRefreshToken();
  const accessToken = useRecoilValue(accessTokenSelector);

  axiosClient.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosClient.interceptors.response.use(
    (response) => response,
    async (error) => {
      const prevRequest = error?.config;
      if (error?.response?.status === 401 && !prevRequest?.sent) {
        // if a 401 error is returned by authorizer, attempt to refresh the token
        errorLogger({
          loggedMessage: '401 error received, refreshing token',
          error,
          notifyUser: false,
        });
        prevRequest.sent = true;
        const newAccessToken = await refresh();
        prevRequest.headers.Authorization = `Bearer ${newAccessToken}`;
        return axiosClient(prevRequest);
      }
      if ([401, 403].includes(error?.response?.status)) {
        // if user is unauthorized after token refresh
        // or if user is forbidden then log the user out
        errorLogger({ loggedMessage: 'logging user out', error, notifyUser: false });
        await logout(); // clear localstorage profile
        window.location.replace('/?logout=true&badcode=true');
      }
      // propagate other errors
      return Promise.reject(error);
    }
  );

  return axiosClient;
};

export default useAxiosPrivate;
