import React from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd';

import './_style.scss';

const { TabPane } = Tabs;

const GenericTabs = ({ panels, onChange, children, tableLoading }) => {
  const TabPanels = panels.map(({ name, key }) => (
    <TabPane tab={<b className="panel">{name}</b>} disabled={tableLoading} key={key}>
      {children}
    </TabPane>
  ));
  return (
    <div className="genericTabsContainer">
      <Tabs defaultActiveKey="0" onChange={onChange} size="large">
        {TabPanels}
      </Tabs>
    </div>
  );
};

GenericTabs.propTypes = {
  panels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      key: PropTypes.number,
    })
  ).isRequired,
  onChange: PropTypes.func,
  children: PropTypes.node.isRequired,
  tableLoading: PropTypes.bool,
};

GenericTabs.defaultProps = {
  onChange: () => {},
  tableLoading: false,
};

export default GenericTabs;
