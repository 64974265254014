import React from 'react';
import PropTypes from 'prop-types';
import { Upload } from 'antd';
import { VideoCameraOutlined } from '@ant-design/icons';
import { SUPPORTED_VIDEO, SUPPORTED_AUDIO } from '../../../utils/constants-utils';
import './_style.scss';

const { Dragger } = Upload;

const DragAndDrop = ({ text, multiple, onChange, disabled }) => (
  <Dragger
    onChange={onChange}
    multiple={multiple}
    accept={[...SUPPORTED_VIDEO, ...SUPPORTED_AUDIO]}
    beforeUpload={() => false}
    disabled={disabled}
  >
    <p className="content ant-upload-text">
      <VideoCameraOutlined className="videoIcon" style={{ fontSize: '20px', color: 'Maroon' }} />
      {text}
    </p>
    <div className="supportedSection">
      <p className="small ant-upload-hint">
        Audio files supported: {SUPPORTED_AUDIO.map((e) => ` ${e}`)}
      </p>
      <p className="small ant-upload-hint">
        Video files supported: {SUPPORTED_VIDEO.map((e) => ` ${e}`)}
      </p>
    </div>
  </Dragger>
);

DragAndDrop.propTypes = {
  text: PropTypes.string,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

DragAndDrop.defaultProps = {
  text: undefined,
  multiple: false,
  disabled: false,
};

export default DragAndDrop;
