import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import GenericTable from '../../atoms/generic-table/generic-table';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import { errorLogger } from '../../../utils/generic-utils';
import FilterForm from '../filter-form/filter-form';
import './_style.scss';

const MediaAdder = ({
  tableColumns,
  MediaItems,
  onAddMedia,
  feedEndpoint,
  processFeedEndpointResults,
}) => {
  const [selectedMedia, setSelectedMedia] = useState();
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState();
  const [offsetKey, setOffsetKey] = useState();
  const [nameFilter, setNameFilter] = useState();

  const getTableDataSource = async (newFilter, force) => {
    if (offsetKey === -1 && !force) return;
    setTableLoading(true);
    let olderDataSource = dataSource;
    try {
      let newMediaItems = offsetKey
        ? { mediaItems: [...MediaItems], offsetKey: JSON.stringify(offsetKey), nameFilter }
        : { mediaItems: MediaItems, nameFilter };
      if (newFilter || force) {
        newMediaItems = { mediaItems: MediaItems, nameFilter: newFilter };
        olderDataSource = undefined;
      }
      const endpointResponse = await feedEndpoint(newMediaItems);
      const processedResults = processFeedEndpointResults(endpointResponse);
      const lastkey = processLastKey(endpointResponse);
      setOffsetKey(lastkey);
      setDataSource(olderDataSource ? [...olderDataSource, ...processedResults] : processedResults);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while getting Table DataSource:', error });
    }
    setTableLoading(false);
  };

  const processLastKey = (result = {}) => {
    if (!result.data) return undefined;
    return result.data.LastEvaluatedKey ? result.data.LastEvaluatedKey : -1;
  };

  useEffect(() => {
    getTableDataSource(nameFilter);
  }, [MediaItems]);

  const addSelectedMedia = () => {
    setOffsetKey();
    setDataSource([]);
    onAddMedia(selectedMedia);
  };
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedMedia(selectedRows);
    },
    getCheckboxProps: (record) => ({
      'aria-label': `Select ${record.media_name} to add`,
    }),
    type: 'checkbox',
  };

  const filterByField = (title, form) => {
    // eslint-disable-next-line eqeqeq
    if (title == nameFilter) {
      setNewFilter();
      form.resetFields();
    } else setNewFilter(title);
  };

  const setNewFilter = (filter) => {
    setNameFilter(filter);
    getTableDataSource(filter, true);
  };

  return (
    <div className="MediaAdderContainer">
      <div>
        <div className="FilterContainer">
          <FilterForm
            filterFunc={filterByField}
            filterDisable={tableLoading}
            isFiltered={nameFilter}
          />
        </div>
        <GenericTable
          columns={tableColumns}
          data={dataSource}
          rowSelection={rowSelection}
          loading={tableLoading}
          endScrollFunc={getTableDataSource}
        />
        <div className="MediaAdderActions">
          <ButtonPrimary text="Add selected media" onClick={addSelectedMedia} theme="round" />
        </div>
      </div>
    </div>
  );
};

MediaAdder.propTypes = {
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  MediaItems: PropTypes.arrayOf(PropTypes.object),
  onAddMedia: PropTypes.func.isRequired,
  feedEndpoint: PropTypes.func.isRequired,
  processFeedEndpointResults: PropTypes.func,
};
MediaAdder.defaultProps = {
  MediaItems: [],
  processFeedEndpointResults: (data) => data,
};

export default MediaAdder;
