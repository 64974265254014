import React from 'react';
import './_style.scss';
import ReactMarkdown from 'react-markdown';
import { Divider } from 'antd';
import { faqsJson } from '../../../faqs/faqs';

const FaqPage = () => {
  const faqs = faqsJson.map(({ title, text, key }) => (
    <section id={key}>
      <Divider orientation="left">{title}</Divider>
      <div className="faqContent">
        <ReactMarkdown>{text}</ReactMarkdown>
      </div>
    </section>
  ));
  return <div className="FaqPageContainer">{faqs}</div>;
};

export default FaqPage;
