import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';
import PrivateRoute from './private-route';
import LandingPage from '../pages/landing-page/landing-page';
import MediaPage from '../pages/media-page/media-page';
import MediaLTIPage from '../pages/media-LTI-page/media-LTI-page';
import MediaUploadPage from '../pages/media-upload-page/media-upload-page';
import MediaUploadLTIPage from '../pages/media-upload-LTI-page/media-upload-LTI-page';
import MediaEditPage from '../pages/media-edit-page/media-edit-page';
import MediaPlayerPage from '../pages/media-player-page/media-player-page';
import CollectionsPage from '../pages/collections-page/collections-page';
import CreateCollectionPage from '../pages/create-collection-page/create-collection-page';
import EditCollectionPage from '../pages/edit-collection-page/edit-collection-page';
import PlaylistsPage from '../pages/playlists-page/playlists-page';
import PlaylistsLtiPage from '../pages/playlists-lti-page/playlists-lti-page';
import PlaylistPlayerPage from '../pages/playlist-player-page/playlist-player-page';
import EmbeddedPlaylistPlayerPage from '../pages/embedded-playlist-player-page/embedded-playlist-player-page';
import EmbeddedPlaylistPlayerLtiPage from '../pages/embedded-playlist-player-lti-page/embedded-playlist-player-lti-page';
import EmbeddedPlayerLtiPage from '../pages/embedded-player-lti-page/embedded-player-lti-page';
import CreatePlaylistPage from '../pages/create-playlist-page/create-playlist-page';
import CreatePlaylistLtiPage from '../pages/create-playlist-lti-page/create-playlist-lti-page';
import EditPlaylistPage from '../pages/edit-playlist-page/edit-playlist-page';
import SitesAdminPage from '../pages/sites-admin-page/sites-admin-page';
import SitesListPage from '../pages/sites-list-page/sites-list-page';
import HelpPage from '../pages/help-page/help-page';
import ReleaseNotesPage from '../pages/release-notes-page/release-notes-page';
import FAQPage from '../pages/faq-page/faq-page';
import SettingsPage from '../pages/settings-page/settings-page';
import ManageUsersPage from '../pages/manage-users-page/manage-users-page';
import EmbeddedPlayerPage from '../pages/embedded-player-page/embedded-player-page';
import HealthPage from '../pages/health-page/health-page';
import VersionPage from '../pages/version-page/version-page';
import AuthzListPage from '../pages/authz-list-page/authz-list-page';
import Error404 from '../pages/error404-page/error404-page';
import {
  LANDING_URL,
  MEDIA_URL,
  MEDIA_LTI_URL,
  MEDIA_UPLOAD_URL,
  MEDIA_UPLOAD_LTI_URL,
  MEDIA_EDIT_URL,
  MEDIA_PLAYER_URL,
  COLLECTIONS_URL,
  CREATE_COLLECTION_URL,
  EDIT_COLLECTION_URL,
  PLAYLISTS_URL,
  PLAYLISTS_LTI_URL,
  PLAYLIST_PLAYER_URL,
  EMBEDDED_PLAYLIST_PLAYER_URL,
  CREATE_PLAYLIST_URL,
  CREATE_PLAYLIST_LTI_URL,
  EDIT_PLAYLIST_URL,
  SITES_ADMIN_URL,
  SITES_LIST_URL,
  SITES_LIST_LTI_URL,
  HELP_URL,
  RELEASE_NOTES_URL,
  FAQ_URL,
  SETTINGS_URL,
  MANAGE_USERS_URL,
  EMBEDDED_PLAYER_URL,
  HEALTH_URL,
  VERSION_URL,
  AUTHZ_LIST_URL,
  ERROR_404_KEY_PAGE,
  EMBEDDED_PLAYLIST_PLAYER_LTI_URL,
  EMBEDDED_PLAYER_LTI_URL,
} from '../../utils/constants-utils';
import { PAGES_KEYS_BY_PATH } from '../../utils/jsons-constants-utils';

const routesConfig = [
  {
    path: LANDING_URL,
    component: LandingPage,
    requireAuthentication: false,
    exact: true,
  },
  { path: MEDIA_URL, component: MediaPage, requireAuthentication: true },
  { path: MEDIA_LTI_URL, component: MediaLTIPage, requireAuthentication: false },
  {
    path: MEDIA_UPLOAD_URL,
    component: MediaUploadPage,
    requireAuthentication: true,
  },
  {
    path: MEDIA_UPLOAD_LTI_URL,
    component: MediaUploadLTIPage,
    requireAuthentication: false,
  },
  {
    path: MEDIA_EDIT_URL,
    component: MediaEditPage,
    requireAuthentication: true,
  },
  {
    path: MEDIA_PLAYER_URL,
    component: MediaPlayerPage,
    requireAuthentication: true,
  },
  {
    path: COLLECTIONS_URL,
    component: CollectionsPage,
    requireAuthentication: true,
  },
  {
    path: CREATE_COLLECTION_URL,
    component: CreateCollectionPage,
    requireAuthentication: true,
  },
  {
    path: EDIT_COLLECTION_URL,
    component: EditCollectionPage,
    requireAuthentication: true,
  },
  {
    path: PLAYLISTS_URL,
    component: PlaylistsPage,
    requireAuthentication: true,
  },
  {
    path: PLAYLISTS_LTI_URL,
    component: PlaylistsLtiPage,
    requireAuthentication: false,
  },
  {
    path: PLAYLIST_PLAYER_URL,
    component: PlaylistPlayerPage,
    requireAuthentication: true,
  },
  {
    path: EMBEDDED_PLAYLIST_PLAYER_URL,
    component: EmbeddedPlaylistPlayerPage,
    requireAuthentication: false,
  },
  {
    path: EMBEDDED_PLAYLIST_PLAYER_LTI_URL,
    component: EmbeddedPlaylistPlayerLtiPage,
    requireAuthentication: false,
  },
  {
    path: EMBEDDED_PLAYER_LTI_URL,
    component: EmbeddedPlayerLtiPage,
    requireAuthentication: false,
  },
  {
    path: CREATE_PLAYLIST_URL,
    component: CreatePlaylistPage,
    requireAuthentication: true,
  },
  {
    path: CREATE_PLAYLIST_LTI_URL,
    component: CreatePlaylistLtiPage,
    requireAuthentication: false,
  },
  {
    path: EDIT_PLAYLIST_URL,
    component: EditPlaylistPage,
    requireAuthentication: true,
  },
  {
    path: SITES_ADMIN_URL,
    component: SitesAdminPage,
    requireAuthentication: true,
  },
  {
    path: SITES_LIST_URL,
    component: SitesListPage,
    requireAuthentication: true,
  },
  {
    path: SITES_LIST_LTI_URL,
    component: SitesListPage,
    requireAuthentication: false,
  },
  { path: HELP_URL, component: HelpPage, requireAuthentication: true },
  {
    path: RELEASE_NOTES_URL,
    component: ReleaseNotesPage,
    requireAuthentication: false,
  },
  {
    path: FAQ_URL,
    component: FAQPage,
    requireAuthentication: false,
  },
  {
    path: SETTINGS_URL,
    component: SettingsPage,
    requireAuthentication: true,
  },
  {
    path: MANAGE_USERS_URL,
    component: ManageUsersPage,
    requireAuthentication: true,
  },
  {
    path: EMBEDDED_PLAYER_URL,
    component: EmbeddedPlayerPage,
    requireAuthentication: false,
  },
  {
    path: HEALTH_URL,
    component: HealthPage,
    requireAuthentication: false,
  },
  {
    path: VERSION_URL,
    component: VersionPage,
    requireAuthentication: false,
  },
  {
    path: AUTHZ_LIST_URL,
    component: AuthzListPage,
    requireAuthentication: false,
  },
  { component: Error404, requireAuthentication: false },
];

export const Router = () => {
  const routes = routesConfig.map((route) => {
    const key = route.path ? PAGES_KEYS_BY_PATH[route.path] : ERROR_404_KEY_PAGE;
    return <PrivateRoute key={key} keyPage={key} {...route} />;
  });
  return (
    <div className="AppContainer">
      <div className="MainContent">
        <div>
          <BrowserRouter>
            <Switch>{routes}</Switch>
          </BrowserRouter>
        </div>
      </div>
    </div>
  );
};
