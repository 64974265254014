import React, { useContext } from 'react';
import { Divider, Button } from 'antd';
import ManageUsersContainer from '../../organisms/manage-users-container/manage-users-container';
import { UserContext } from '../../../services/providers/user-context';
import { SiteContext } from '../../../services/providers/site-context';
import { SETTINGS, VERSION_URL, HEALTH_URL } from '../../../utils/constants-utils';
import { useRedirect } from '../../router/redirect';
import './_style.scss';

const SettingsPage = () => {
  const { user } = useContext(UserContext);
  const { site } = useContext(SiteContext);
  const { redirect, setUrlToRedirect } = useRedirect();

  return (
    <div className="SettingsPageContainer">
      {redirect()}
      <div className="SettingsPageHeader">
        <p className="Title">{SETTINGS}</p>
      </div>
      <div className="SettingsPageHeader">
        <Divider />
        <ManageUsersContainer
          currentSite={{ id: site.SiteId, name: site.SiteName }}
          userRole={user.userRole}
        />
        <Divider />
        <div className="text">
          <Button type="link" onClick={() => setUrlToRedirect(VERSION_URL)}>
            View page version
          </Button>
        </div>
        <div className="text">
          <Button type="link" onClick={() => setUrlToRedirect(HEALTH_URL)}>
            View services health status
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SettingsPage;
