import React, { useState, useEffect } from 'react';
import CreateMediaStructure from '../../organisms/create-media-structure/create-media-structure';
import apiCalls from '../../../services/api-calls/all';
import { playlistTableColumns } from '../../molecules/table-headers/playlist-items.table-header';
import { CREATE_NEW_PLAYLIST, PLAYLIST_TITLE, PLAYLISTS_URL } from '../../../utils/constants-utils';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const CreatePlaylistPage = () => {
  const [userId, setUserId] = useState();
  const [siteId, setSiteId] = useState();
  const axiosPrivate = useAxiosPrivate();
  const { createPlaylist, getPlaylistEligibleMedia } = apiCalls(axiosPrivate);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setUserId(params.get('userId'));
    setSiteId(params.get('siteId'));
  }, []);

  const processFeedEndpointResults = ({ data: { Items } }) =>
    Items.map((item) => ({
      key: item.MediaId,
      media_name: item.title,
      id: item.MediaId,
      ...item,
    }));

  const getPlaylistEligibleMediaEndpoint = (mediaItemsIds) =>
    getPlaylistEligibleMedia({
      siteId,
      userId,
      ...mediaItemsIds,
    });

  const createPlaylistEndpoint = ({ selectedMediaKeys, mediaStructureTitle }) =>
    createPlaylist({
      playlistItems: selectedMediaKeys,
      playlistTitle: mediaStructureTitle,
      ownerId: userId,
      siteId,
    });

  return (
    <div className="CreatePlaylistPageContainer">
      <CreateMediaStructure
        title={CREATE_NEW_PLAYLIST}
        titleLabel={PLAYLIST_TITLE}
        returnUrl={PLAYLISTS_URL}
        endpoint={createPlaylistEndpoint}
        tableColumns={playlistTableColumns}
        feedEndpoint={getPlaylistEligibleMediaEndpoint}
        processFeedEndpointResults={processFeedEndpointResults}
      />
    </div>
  );
};

export default CreatePlaylistPage;
