import { get } from 'lodash';
import { addQueryString } from '../../utils/urls-utils';
import { axiosClientMultipart } from './http-client-multipart';
import {
  UNEXPECTED_ERROR,
  API_ERROR_401,
  API_ERROR_403,
  API_ERROR_500,
  STATUS_401,
  STATUS_403,
  STATUS_500,
} from './messages.constants.json';

const makeGetRequest = (axiosInstance, url, parameters, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return axiosInstance.get(completeUrl, {
    params: parameters,
  });
};

const makePostRequest = (axiosInstance, url, bodyParameters = {}, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return axiosInstance.post(completeUrl, { ...bodyParameters });
};

const makePostFileRequest = (url, file, queryParameters) => {
  const completeUrl = addQueryString(url, queryParameters);
  return axiosClientMultipart.post(completeUrl, file);
};

export default (axiosInstance) => ({
  makeGetRequest: (url, params, query) => makeGetRequest(axiosInstance, url, params, query),
  makePostRequest: (url, data, query) => makePostRequest(axiosInstance, url, data, query),
  makePostFileRequest,
});

export const processederrorLogger = (error, messageText) => {
  const status = get(error, 'response.status');
  if (!status) return UNEXPECTED_ERROR;
  switch (status) {
    case STATUS_401:
      return API_ERROR_401;
    case STATUS_403:
      return API_ERROR_403;
    case STATUS_500:
      return API_ERROR_500;
    default:
      return messageText || UNEXPECTED_ERROR;
  }
};
