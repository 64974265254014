import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { UPLOADING_MEDIA } from '../../utils/constants-utils';
import { addBeforeUnloadListener, removeBeforeUnloadListener } from '../../utils/eventListeners';

const initialState = [];

const reducer = (uploadingMedia, newUploadingMedia) => newUploadingMedia;

const MediaUploadContext = createContext({});

const MediaUploadProvider = ({ children }) => {
  const [uploadingMedia, setuploadingMedia] = useReducer(reducer, initialState);

  const addUploadingMedia = (media) => {
    const existingUploadingMedia = JSON.parse(localStorage.getItem(UPLOADING_MEDIA));
    if (!existingUploadingMedia.length) addBeforeUnloadListener();
    setuploadingMedia([...uploadingMedia, media]);
  };

  const removeUploadedMedia = (newMedia) => {
    const existingUploadingMedia = JSON.parse(localStorage.getItem(UPLOADING_MEDIA));
    const newUploadingMedia = existingUploadingMedia.filter(
      (media) => media.upload_id !== newMedia.upload_id
    );
    if (!newUploadingMedia.length) removeBeforeUnloadListener();
    setuploadingMedia(newUploadingMedia);
  };

  const updateUploadingMedia = (toUpdateMedia) => {
    const existingUploadingMedia = JSON.parse(localStorage.getItem(UPLOADING_MEDIA));
    const media = existingUploadingMedia.filter((m) => m.upload_id === toUpdateMedia.upload_id)[0];
    if (media) {
      const {
        upload_progress: { size, updatedParts },
      } = media;
      const newMedia = {
        ...media,
        upload_progress: {
          size,
          updatedParts: toUpdateMedia.failed ? -1 : updatedParts + 1,
        },
      };
      existingUploadingMedia[
        existingUploadingMedia.map((x, i) => [i, x]).filter((x) => x[1] === media)[0][0]
      ] = newMedia;
      setuploadingMedia(existingUploadingMedia);
    }
  };

  useEffect(() => {
    localStorage.setItem(UPLOADING_MEDIA, JSON.stringify(uploadingMedia));
  }, [uploadingMedia]);

  return (
    <MediaUploadContext.Provider
      value={{
        uploadingMedia,
        addUploadingMedia,
        removeUploadedMedia,
        updateUploadingMedia,
      }}
    >
      {children}
    </MediaUploadContext.Provider>
  );
};

MediaUploadProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { MediaUploadContext, MediaUploadProvider };
