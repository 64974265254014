import React from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import PropTypes from 'prop-types';
import { Table } from 'antd';

import './_style.scss';

const GenericTable = ({ columns, data, onClick, loading, rowSelection, endScrollFunc }) => {
  const TableColumns = columns.map((item) => ({ ...item, ellipsis: true }));

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage: true,
    onLoadMore: endScrollFunc,
  });

  return (
    <div className="genericTableContainer">
      <Table
        ref={rootRef}
        loading={loading}
        scroll={{ y: 570, scrollToFirstRowOnChange: false }}
        rowSelection={rowSelection}
        className="genericTable"
        columns={TableColumns}
        dataSource={data}
        pagination={false}
        tableLayout="fixed"
        onRow={(record, rowIndex) => {
          if (rowIndex === data.length - 2) {
            return {
              ref: sentryRef,
              onClick: () => {
                console.log(sentryRef.current);
                onClick(record, rowIndex);
              },
            };
          }
          return {
            onClick: () => onClick(record, rowIndex),
          };
        }}
      />
    </div>
  );
};

GenericTable.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object),
  data: PropTypes.arrayOf(PropTypes.object),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  rowSelection: PropTypes.shape(PropTypes.object),
  endScrollFunc: PropTypes.func,
};

GenericTable.defaultProps = {
  columns: [],
  data: undefined,
  onClick: () => {},
  loading: false,
  rowSelection: undefined,
  endScrollFunc: () => {},
};

export default GenericTable;
