import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Typography, Input, Radio, Space } from 'antd';
import { FileImageOutlined } from '@ant-design/icons';
import apiCalls from '../../../../services/api-calls/all';
import { UPLOAD_POSTER_HINT } from '../../../../utils/constants-utils';
import { errorLogger } from '../../../../utils/generic-utils';
import AssetDragger from '../../../atoms/asset-dragger/asset-dragger';
import useAxiosPrivate from '../../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const { Title } = Typography;

const MediaDetails = ({ currentMedia, siteId, updateCurrentMedia }) => {
  const [mediaTitle, setMediaTitle] = useState(currentMedia.title);
  const [mediaDescription, setMediaDescription] = useState(currentMedia.description || '');
  const [mediaPosters, setMediaPosters] = useState(currentMedia.posters || []);
  const [draggerDisabled, setDraggerDisabled] = useState(false);
  const [defaultPosterIndex, setDefaultPosterIndex] = useState(0);
  const axiosPrivate = useAxiosPrivate();
  const { getAssetUploadUrl, uploadFilesToS3 } = apiCalls(axiosPrivate);

  useEffect(() => {
    mediaPosters.forEach((item, index) => {
      if (item.isDefault) setDefaultPosterIndex(index);
    });
  }, [mediaPosters]);
  // Everytime something is changed, we update the current media
  // object on the parent component, so it persists even if user switches tab
  useEffect(() => {
    if (mediaTitle) {
      if (
        mediaPosters !== currentMedia.posters ||
        mediaTitle !== currentMedia.title ||
        mediaDescription !== currentMedia.description
      ) {
        updateCurrentMedia({
          ...currentMedia,
          title: mediaTitle,
          posters: mediaPosters,
          description: mediaDescription,
        });
      }
    }
  }, [mediaPosters, mediaTitle, mediaDescription]);
  // Everytime a new poster is uploaded, we store it on s3 and add it to posters state.
  // This updates media object on parent component.
  const onPosterDrop = async ({ file }) => {
    setDraggerDisabled(true);
    try {
      const { name } = file;
      const mediaFileKey = currentMedia.fileKey;
      const mediaFilePath = mediaFileKey.slice(0, mediaFileKey.indexOf('/'));
      const {
        data: { url, fields },
      } = await getAssetUploadUrl({
        filePath: `${mediaFilePath}/${name}`,
        siteId,
      });
      const formData = new FormData();
      Object.entries(fields).forEach(([k, v]) => {
        formData.append(k, v);
      });
      formData.append('file', file);
      await uploadFilesToS3(url, formData);
      const newMediaPosters = [
        ...mediaPosters,
        { fileName: name, isDefault: !mediaPosters.length },
      ];
      setMediaPosters(newMediaPosters);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while uploading poster:', error });
    }
    setDraggerDisabled(false);
  };
  const handleDefaultPosterChange = (e) => {
    setDefaultPosterIndex(e.target.value);
    const newMediaPosters = mediaPosters.map((item, index) => {
      if (index === e.target.value) {
        return { fileName: item.fileName, isDefault: true };
      }
      return { fileName: item.fileName, isDefault: false };
    });
    setMediaPosters(newMediaPosters);
  };
  return (
    <div className="MediaDetailsEditorContainer">
      <div className="MediaDetailsEditorField">
        <Title level={5}>Title</Title>
        <Input value={mediaTitle} onChange={(e) => setMediaTitle(e.target.value)} />
      </div>
      <div className="MediaDetailsEditorField">
        <Title level={5}>Description</Title>
        <Input.TextArea
          value={mediaDescription}
          onChange={(e) => setMediaDescription(e.target.value)}
        />
      </div>
      <div className="MediaDetailsEditorField">
        <Title level={5}>Upload poster</Title>
        <AssetDragger
          disabled={draggerDisabled}
          onChange={onPosterDrop}
          text={UPLOAD_POSTER_HINT}
          icon={<FileImageOutlined />}
        />
      </div>
      <div className="MediaDetailsEditorField">
        <Title level={5}>Select default poster</Title>
        <Radio.Group onChange={handleDefaultPosterChange} value={defaultPosterIndex}>
          <Space direction="vertical">
            {mediaPosters.map((item, index) => (
              <Radio value={index}>{item.fileName}</Radio>
            ))}
          </Space>
        </Radio.Group>
      </div>
    </div>
  );
};

MediaDetails.propTypes = {
  currentMedia: PropTypes.shape(PropTypes.object).isRequired,
  siteId: PropTypes.string.isRequired,
  updateCurrentMedia: PropTypes.func.isRequired,
};

export default MediaDetails;
