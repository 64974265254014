export const releaseNotesJson = [
  {
    title: 'Sprint 4',
    key: 'sprint-4',
    text: `
* [AMSP-678](https://asudev.jira.com/browse/AMSP-678) [testing blocked AMSP-687] deploy multiple lambdas
* [AMSP-628](https://asudev.jira.com/browse/AMSP-628) create presentation for migration planning for IS for Sept 20th
* [AMSP-531](https://asudev.jira.com/browse/AMSP-531) FAQ to landing page
* [AMSP-525](https://asudev.jira.com/browse/AMSP-525) get help: create dev/qa distribution list for landing page
* [AMSP-467](https://asudev.jira.com/browse/AMSP-467) React - sorry page for general errors
* [AMSP-702](https://asudev.jira.com/browse/AMSP-702) Create User for Chris Ludlow
* [AMSP-689](https://asudev.jira.com/browse/AMSP-689) Onboarding AWS: Create ASU Affiliate Account for Chad Elias
* [AMSP-688](https://asudev.jira.com/browse/AMSP-688) Onboarding AWS: Create ASU Affiliate Account for Roy McFadden
* [AMSP-686](https://asudev.jira.com/browse/AMSP-686) Onboarding AWS: Create ASU Affiliate Account for Chris Ludlow
* [AMSP-685](https://asudev.jira.com/browse/AMSP-685) Onboarding AWS: Create ASU Affiliate Account for Birant Akarslan
* [AMSP-607](https://asudev.jira.com/browse/AMSP-607) wireframes for sites
* [AMSP-570](https://asudev.jira.com/browse/AMSP-570) headers for accessibility and screen readers
* [AMSP-526](https://asudev.jira.com/browse/AMSP-526) wireframe mobile design for landing page and nav
* [AMSP-274](https://asudev.jira.com/browse/AMSP-274) UI Testing Framework Selected`,
  },
  {
    title: 'Sprint 3',
    key: 'sprint-3',
    text: `
* [AMSP-439](https://asudev.jira.com/browse/AMSP-439) Compare Wireframes to Stories and Add JIRAs for React
* [AMSP-679](https://asudev.jira.com/browse/AMSP-679) Reactivate Site Python Boto Lambda
* [AMSP-673](https://asudev.jira.com/browse/AMSP-673) merge to one code repo
* [AMSP-672](https://asudev.jira.com/browse/AMSP-672) VPC Endpoint for DynamoDB Needed
* [AMSP-666](https://asudev.jira.com/browse/AMSP-666) Slack Notifications from GH\\Jenkins
* [AMSP-664](https://asudev.jira.com/browse/AMSP-664) set up precommit hooks on meho-*-platform-app
* [AMSP-663](https://asudev.jira.com/browse/AMSP-663) set up precommit hooks on meho-*-platform
* [AMSP-661](https://asudev.jira.com/browse/AMSP-661) update CODEOWNERS on platform and platform-app to be the sprint team
* [AMSP-657](https://asudev.jira.com/browse/AMSP-657) how is more than 1 person supposed to do terraform development in the same AWS account as per the ASU setup?
* [AMSP-656](https://asudev.jira.com/browse/AMSP-656) DESIGN: As a user, i want to see failed uploads indicated
* [AMSP-647](https://asudev.jira.com/browse/AMSP-647) deploy first terraform to dev (logging bucket)
* [AMSP-644](https://asudev.jira.com/browse/AMSP-644) Pull list of LTI tool clicks
* [AMSP-642](https://asudev.jira.com/browse/AMSP-642) PRs are broken for platform repo (1 hour build time)
* [AMSP-640](https://asudev.jira.com/browse/AMSP-640) Add sam deploy plugin to jenkins
* [AMSP-639](https://asudev.jira.com/browse/AMSP-639) s3 bucket for sam to use in each enviroment
* [AMSP-638](https://asudev.jira.com/browse/AMSP-638) SAM lambda deployment strategy
* [AMSP-635](https://asudev.jira.com/browse/AMSP-635) add Birant Akarslan to AWS roles
* [AMSP-634](https://asudev.jira.com/browse/AMSP-634) setup Birant Akarslan on github meho
* [AMSP-621](https://asudev.jira.com/browse/AMSP-621) Sprint 2 - Styling Enhancements
* [AMSP-619](https://asudev.jira.com/browse/AMSP-619) create empty login lambda that returns true
* [AMSP-617](https://asudev.jira.com/browse/AMSP-617) Create sites (auth) DDB with terraform
* [AMSP-613](https://asudev.jira.com/browse/AMSP-613) Deactivate Site Python Boto Lambda
* [AMSP-612](https://asudev.jira.com/browse/AMSP-612) [blocked ui] Activate Site Python Boto Lambda
* [AMSP-605](https://asudev.jira.com/browse/AMSP-605) UI: as a user, i want to see failed uploads indicated
* [AMSP-604](https://asudev.jira.com/browse/AMSP-604) React: Move help and settings up into one navigation for accessibility
* [AMSP-603](https://asudev.jira.com/browse/AMSP-603) Decision: reading width for text
* [AMSP-571](https://asudev.jira.com/browse/AMSP-571) test current playlists to see if they will play in PLAYR or if we need to do conversions
* [AMSP-564](https://asudev.jira.com/browse/AMSP-564) Sprint 3 - Grooming - Roadmap with Dates
* [AMSP-536](https://asudev.jira.com/browse/AMSP-536) (nico) react: keyboard tab focus for landing page and navigation
* [AMSP-520](https://asudev.jira.com/browse/AMSP-520) Vendor - What is significance of files without MD5 hashes?
* [AMSP-507](https://asudev.jira.com/browse/AMSP-507) Identify a default ASUrite ID that will own content on import
* [AMSP-498](https://asudev.jira.com/browse/AMSP-498) react - spike: consider canvas user experience
* [AMSP-495](https://asudev.jira.com/browse/AMSP-495) sort columns title, owner, created
* [AMSP-493](https://asudev.jira.com/browse/AMSP-493) react for landing page
* [AMSP-492](https://asudev.jira.com/browse/AMSP-492) Wireframe: Welcome Page
* [AMSP-491](https://asudev.jira.com/browse/AMSP-491) release notes
* [AMSP-481](https://asudev.jira.com/browse/AMSP-481) Discussion - allow Ann Brady and Ryan King to merge PRs for GitHub Platform <ongoing>
* [AMSP-480](https://asudev.jira.com/browse/AMSP-480) UI Testing Resource Needed for Sprint 3
* [AMSP-452](https://asudev.jira.com/browse/AMSP-452) Decision Needed: for Inactive accounts, what do we want to do with the data?
* [AMSP-262](https://asudev.jira.com/browse/AMSP-262) Login page and function
* [AMSP-168](https://asudev.jira.com/browse/AMSP-168) (confirm playr feature) Turn close caption on/off as student
* [AMSP-161](https://asudev.jira.com/browse/AMSP-161) Audio content has an owner by default (creator)
* [AMSP-138](https://asudev.jira.com/browse/AMSP-138) Video content has an owner by default (creator)
* [AMSP-108](https://asudev.jira.com/browse/AMSP-108) [Blocked] - create collection`,
  },
  {
    title: 'Sprint 2',
    key: 'sprint-2',
    text: `
* [AMSP-380](https://asudev.jira.com/browse/AMSP-380) Terraform vars for dev, qa, prod
* [AMSP-625](https://asudev.jira.com/browse/AMSP-625) Demo - Sprint 2
* [AMSP-594](https://asudev.jira.com/browse/AMSP-594) Decision: WCAG 2.1 (A or AA) is the standard
* [AMSP-574](https://asudev.jira.com/browse/AMSP-574) SERVERLESS: Help function redirects to home page\\landing page
* [AMSP-573](https://asudev.jira.com/browse/AMSP-573) how to get playlists from media amp?
* [AMSP-566](https://asudev.jira.com/browse/AMSP-566) write stories for site for sprint 3
* [AMSP-565](https://asudev.jira.com/browse/AMSP-565) review ASU accessibility design guidelines
* [AMSP-543](https://asudev.jira.com/browse/AMSP-543) nico needs sandbox to use cloud9 IDE
* [AMSP-542](https://asudev.jira.com/browse/AMSP-542) font awesome or alternative icons for navigation
* [AMSP-541](https://asudev.jira.com/browse/AMSP-541) Create VPC & Subnets for Prod
* [AMSP-540](https://asudev.jira.com/browse/AMSP-540) Create VPC & Subnets for QA
* [AMSP-522](https://asudev.jira.com/browse/AMSP-522) UI: add Help link to bottom of left menu
* [AMSP-506](https://asudev.jira.com/browse/AMSP-506) SERVERLESS: can do other things while videos are uploading
* [AMSP-505](https://asudev.jira.com/browse/AMSP-505) Add DataSync VPC Endpoint to Sandbox Network
* [AMSP-500](https://asudev.jira.com/browse/AMSP-500) True Up Accounts based on Files from MediaAmp
* [AMSP-499](https://asudev.jira.com/browse/AMSP-499) categories= collections?
* [AMSP-497](https://asudev.jira.com/browse/AMSP-497) decision needed - public - privacy of videos
* [AMSP-483](https://asudev.jira.com/browse/AMSP-483) Enroll new Users with AMSPDev role for access to lower environments.
* [AMSP-478](https://asudev.jira.com/browse/AMSP-478) One or Many Accounts
* [AMSP-466](https://asudev.jira.com/browse/AMSP-466) Wireframes to Stories
* [AMSP-453](https://asudev.jira.com/browse/AMSP-453) add the tags to the serverless code
* [AMSP-440](https://asudev.jira.com/browse/AMSP-440) Spike - Amazon DataSync
* [AMSP-438](https://asudev.jira.com/browse/AMSP-438) Spike - what is PlayPosit
* [AMSP-420](https://asudev.jira.com/browse/AMSP-420) React - Add basic UI elements for Upload video
* [AMSP-387](https://asudev.jira.com/browse/AMSP-387) (Usage Statistics) Scaling Metrics for Content
* [AMSP-385](https://asudev.jira.com/browse/AMSP-385) Terraform Bucket Logging Configuration for ASU
* [AMSP-384](https://asudev.jira.com/browse/AMSP-384) Terraform object naming conventions
* [AMSP-365](https://asudev.jira.com/browse/AMSP-365) outline requirements for security review process
* [AMSP-239](https://asudev.jira.com/browse/AMSP-239) Happy Path - Audio Files
* [AMSP-238](https://asudev.jira.com/browse/AMSP-238) Service Account to Improve Speed
* [AMSP-237](https://asudev.jira.com/browse/AMSP-237) GCP Bucket Names for Each Account
* [AMSP-236](https://asudev.jira.com/browse/AMSP-236) Sprint 2 - Discovery - Roadmap with Dates
* [AMSP-195](https://asudev.jira.com/browse/AMSP-195) Networking Sizing
* [AMSP-190](https://asudev.jira.com/browse/AMSP-190) I need to understand canvas integration and access model
* [AMSP-98](https://asudev.jira.com/browse/AMSP-98) react - view list of all videos
* [AMSP-4](https://asudev.jira.com/browse/AMSP-4) Create VPC & Subnets for Dev`,
  },
  {
    title: 'Sprint 1',
    key: 'sprint-1',
    text: `
* [AMSP-234](https://asudev.jira.com/browse/AMSP-234)	Sandbox Network - Enterprise VPC
* [AMSP-229](https://asudev.jira.com/browse/AMSP-229)	Sprint 1 - Roadmap with Dates
* [AMSP-227](https://asudev.jira.com/browse/AMSP-227)	Terraform State Machine
* [AMSP-225](https://asudev.jira.com/browse/AMSP-225)	Happy Path - Video
* [AMSP-223](https://asudev.jira.com/browse/AMSP-223)	Identify all stakeholders
* [AMSP-208](https://asudev.jira.com/browse/AMSP-208)	Provision access to Github repo for Carolina Visentine (cvisenti )
* [AMSP-188](https://asudev.jira.com/browse/AMSP-188)	Spike - Integrated with ASU SSO - use ASURITE ID
* [AMSP-144](https://asudev.jira.com/browse/AMSP-144)	wireframes - sorry page for general errors
* [AMSP-22](https://asudev.jira.com/browse/AMSP-22)	Create Initial Infrastructure CI/CD Pipeline job`,
  },
];
