import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import apiCalls from '../../../services/api-calls/all';
import MediaPlayer from '../media-player/media-player';
import GenericTable from '../../atoms/generic-table/generic-table';
import { errorLogger } from '../../../utils/generic-utils';
import { playlistPlayerColumns } from '../../molecules/table-headers/playlist-player.table-header';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const PlaylistPlayer = ({ playlistId, siteId, isPrivate, isLti }) => {
  const [selectedMedia, setSelectedMedia] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [playlistTitle, setPlaylistTitle] = useState();
  const [publicPlaylistId, setPublicPlaylistId] = useState();
  const [dataSource, setDataSource] = useState();
  const [lastPlayed, setLastPlayed] = useState();

  const axiosPrivate = useAxiosPrivate();
  const { getPlaylistById } = apiCalls(axiosPrivate);

  useEffect(() => {
    if (playlistId) {
      getPlaylist(isPrivate);
      if (!isPrivate) setPublicPlaylistId(playlistId);
    }
  }, [playlistId]);

  const getPlaylist = async () => {
    try {
      const {
        data: {
          mediaItems: { Items },
          title,
        },
      } = await getPlaylistById({
        playlistId,
        siteId,
      });
      setPlaylistTitle(title);
      setSelectedMedia(Items[0]);
      setDataSource(Items);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while getting playlist:', error });
    }
  };

  const handleMediaChange = (item) => {
    setSelectedMedia(item);
    const mediasIds = dataSource.map((media) => media.MediaId);
    const itemIndex = mediasIds.indexOf(item.MediaId);
    setSelectedIndex(itemIndex);
  };

  const nextMedia = (id) => {
    const mediasIds = dataSource.map((media) => media.MediaId);
    const currentIndex = mediasIds.indexOf(selectedMedia.MediaId);
    if (selectedMedia.MediaId === id && lastPlayed !== id) {
      setLastPlayed(id);
      if (currentIndex + 1 < dataSource.length) {
        setSelectedIndex(currentIndex + 1);
        setSelectedMedia(dataSource[currentIndex + 1]);
      }
    }
  };

  return (
    <div className="PlayListsPlayerContainer">
      <div>
        <div className="PlayListsPlayer">
          {selectedMedia && (
            <MediaPlayer
              id={selectedMedia.MediaId}
              siteId={siteId}
              publicPlaylistId={publicPlaylistId}
              showTitle
              onEnded={nextMedia}
              isLti={isLti}
            />
          )}
        </div>
        <div className="PlayListsPlayerTitleContainer">
          <p className="Title">Playlist: {playlistTitle}</p>
          <GenericTable
            columns={playlistPlayerColumns}
            data={dataSource}
            onClick={handleMediaChange}
            selectedIndex={selectedIndex}
          />
        </div>
      </div>
    </div>
  );
};

PlaylistPlayer.propTypes = {
  playlistId: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  isPrivate: PropTypes.bool,
  isLti: PropTypes.bool,
};

PlaylistPlayer.defaultProps = {
  isPrivate: true,
  isLti: false,
};

export default PlaylistPlayer;
