import React, { useState, useEffect, useContext } from 'react';
import MediaPlayer from '../../organisms/media-player/media-player';
import { SiteContext } from '../../../services/providers/site-context';

import './_style.scss';

const MediaPlayerPage = () => {
  const [mediaId, setMediaId] = useState();
  const { site } = useContext(SiteContext);
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    setMediaId(params.get('id'));
  }, []);

  return (
    <div className="MediaPlayerPageContainer">
      <div className="MediaPlayerContainer">
        <MediaPlayer id={mediaId} siteId={site.SiteId} showTitle />
      </div>
    </div>
  );
};

export default MediaPlayerPage;
