import React from 'react';
import AuthzList from '../../organisms/authz-list/authz-list';
import './_style.scss';

const AuthzListPage = () => (
  <div className="AuthzListContainer">
    <AuthzList />
  </div>
);

export default AuthzListPage;
