import React from 'react';
import PropTypes from 'prop-types';
import {
  HELP_URL,
  LANDING_KEY_PAGE,
  RELEASE_NOTES_KEY_PAGE,
  FAQ_KEY_PAGE,
  EMBEDDED_PLAYER_KEY_PAGE,
  EMBEDDED_PLAYER_LTI_KEY_PAGE,
  ERROR_404_KEY_PAGE,
  HEALTH_KEY_PAGE,
  VERSION_KEY_PAGE,
  MEDIA_LTI_KEY_PAGE,
  MEDIA_UPLOAD_LTI_KEY_PAGE,
  PLAYLISTS_LTI_KEY_PAGE,
  EMBEDDED_PLAYLIST_PLAYER_KEY_PAGE,
  EMBEDDED_PLAYLIST_PLAYER_LTI_KEY_PAGE,
  CREATE_PLAYLIST_LTI_KEY_PAGE,
} from '../../../utils/constants-utils';
import { useRedirect } from '../../router/redirect';
import './_style.scss';
import LogoImage from '../../../images/ASU_LOGO.png';

const PAGES_WITHOUT_AUTHORIZATION = [
  LANDING_KEY_PAGE,
  RELEASE_NOTES_KEY_PAGE,
  FAQ_KEY_PAGE,
  EMBEDDED_PLAYER_KEY_PAGE,
  ERROR_404_KEY_PAGE,
  HEALTH_KEY_PAGE,
  VERSION_KEY_PAGE,
];
const PAGES_WITHOUT_HEADER = [
  MEDIA_LTI_KEY_PAGE,
  MEDIA_UPLOAD_LTI_KEY_PAGE,
  EMBEDDED_PLAYER_KEY_PAGE,
  VERSION_KEY_PAGE,
  EMBEDDED_PLAYER_LTI_KEY_PAGE,
  PLAYLISTS_LTI_KEY_PAGE,
  EMBEDDED_PLAYLIST_PLAYER_KEY_PAGE,
  EMBEDDED_PLAYLIST_PLAYER_LTI_KEY_PAGE,
  CREATE_PLAYLIST_LTI_KEY_PAGE,
];

const Header = ({ keyPage }) => {
  const { redirect, setUrlToRedirect } = useRedirect();

  const signOut = () => {
    window.location.replace('/?logout=true');
    // setUrlToRedirect(LOGOUT_URL);
  };

  return PAGES_WITHOUT_HEADER.includes(keyPage) ? (
    <div />
  ) : (
    <div>
      {redirect()}
      <div className="header">
        <button className="ASULogoButton" type="button" onClick={() => setUrlToRedirect(HELP_URL)}>
          <img src={LogoImage} alt="ASU_logo" />
        </button>
        <b className="HeaderTitle">ASU Media+</b>
        {PAGES_WITHOUT_AUTHORIZATION.includes(keyPage) ? (
          <div />
        ) : (
          <button className="SignOutButton" type="button" onClick={signOut}>
            <b>Sign Out</b>
          </button>
        )}
      </div>
    </div>
  );
};

Header.propTypes = {
  keyPage: PropTypes.string,
};

Header.defaultProps = {
  keyPage: undefined,
};

export default Header;
