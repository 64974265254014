const HEADERS = {
  CONTENT_TYPE: 'content-type',
  ACCEPT: 'accept',
  JSON: 'application/json; charset=utf-8',
  MULTIPART: 'multipart/form-data',
  REGISTRATION_TOKEN: 'registration-token',
  CREDENTIALS: 'same-origin',
};

module.exports = {
  HEADERS,
};
