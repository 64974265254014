import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Form, Button } from 'antd';
import './_style.scss';

const MEDIA_AMP_ACCOUNT_NUMBER_LENGTH = 10;

const SiteEditor = ({ siteName, siteTargetVersion, onFinishEdit, siteMediaAmpAccountNumber }) => {
  const [editSiteButtonDisabled, setEditSiteButtonDisabled] = useState(false);

  const handleConfirmEdit = ({ name, targetVersion, MediaAmpAccountNumber }) => {
    setEditSiteButtonDisabled(true);
    const newMediaAmpAccountNumber = MediaAmpAccountNumber
      ? parseInt(MediaAmpAccountNumber, MEDIA_AMP_ACCOUNT_NUMBER_LENGTH)
      : undefined;
    const newsiteTargetVersion = parseInt(targetVersion, targetVersion.length + 1);
    onFinishEdit({
      siteName: name,
      siteTargetVersion: newsiteTargetVersion,
      MediaAmpAccountNumber: newMediaAmpAccountNumber,
    });
  };

  return (
    <div className="SiteEditorFormBody">
      <div className="formTitle">
        <p className="title">Edit site:</p>
      </div>
      <div className="EditSiteForm">
        <Form
          name="EditSiteform"
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 16 }}
          initialValues={{
            name: siteName,
            targetVersion: siteTargetVersion,
            MediaAmpAccountNumber: siteMediaAmpAccountNumber,
          }}
          onFinish={handleConfirmEdit}
          autoComplete="off"
        >
          <Form.Item
            label="name"
            name="name"
            labelAlign="left"
            rules={[{ required: true, message: 'Site name is required' }]}
          >
            <Input style={{ width: '50%' }} />
          </Form.Item>
          <Form.Item
            label="Target version"
            name="targetVersion"
            labelAlign="left"
            rules={[{ required: true, message: 'Target version is required' }]}
          >
            <Input style={{ width: '50%' }} type="number" />
          </Form.Item>
          <Form.Item
            label="MediaAMP account number:"
            name="MediaAmpAccountNumber"
            labelAlign="left"
            rules={[
              {
                len: MEDIA_AMP_ACCOUNT_NUMBER_LENGTH,
                message: `Invalid account number (it should be ${MEDIA_AMP_ACCOUNT_NUMBER_LENGTH} digits long)`,
              },
            ]}
          >
            <Input
              style={{ width: '50%' }}
              placeholder="XXXXXXXXXX"
              data-testid="media-amp-account-input"
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 4, span: 16 }}>
            <Button type="primary" htmlType="submit" disabled={editSiteButtonDisabled}>
              Save site
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

SiteEditor.propTypes = {
  siteName: PropTypes.node.isRequired,
  siteTargetVersion: PropTypes.node.isRequired,
  onFinishEdit: PropTypes.func.isRequired,
  siteMediaAmpAccountNumber: PropTypes.number,
};

SiteEditor.defaultProps = {
  siteMediaAmpAccountNumber: undefined,
};

export default SiteEditor;
