import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import ButtonPrimary from '../../atoms/button-primary/button-primary';
import SortableTable from '../../atoms/sortable-table/sortable-table';
import MediaAdder from '../media-adder/media-adder';
import GenericActions from '../table-columns/generic-actions/generic-actions.table-columns';
import { useRedirect } from '../../router/redirect';
import {
  ADD_MEDIA,
  DELETE,
  EDIT,
  PLAY,
  MEDIA_PLAYER_URL,
  MEDIA_EDIT_URL,
} from '../../../utils/constants-utils';
import './_style.scss';

const MediaStructureEditor = ({
  tableColumns,
  MediaItems,
  onMediaAdd,
  feedEndpoint,
  removeMediaItem,
  processFeedEndpointResults,
  modifyDataSourceOrder,
  returnUrl,
  mediaStructureId,
}) => {
  const [addMedia, setAddMedia] = useState(false);
  const { redirect, setUrlToRedirect } = useRedirect();
  const [mediaSelected, setMediaSelected] = useState();

  const mediaAddHandler = (selectedMedia) => {
    setAddMedia(false);
    onMediaAdd(selectedMedia);
  };

  const goToMediaPlay = ({ id }) => {
    setMediaSelected(id);
    setUrlToRedirect(MEDIA_PLAYER_URL);
  };

  const goToMediaEdit = ({ id }) => {
    setMediaSelected(id);
    setUrlToRedirect(MEDIA_EDIT_URL);
  };

  const actionsWithDelete = [
    { key: EDIT, onClick: goToMediaEdit },
    { key: PLAY, onClick: goToMediaPlay },
    { key: DELETE, onClick: removeMediaItem },
  ];

  const actions = [
    { key: EDIT, onClick: goToMediaEdit },
    { key: PLAY, onClick: goToMediaPlay },
  ];

  const tableColumnsActionsWithDelete = [
    ...tableColumns,
    GenericActions({ actions: actionsWithDelete }),
  ];
  const tableColumnsActions = [...tableColumns, GenericActions({ actions })];

  return (
    <div className="MediaStructureEditorContainer">
      {mediaSelected ? redirect({ id: mediaSelected, returnUrl, mediaStructureId }) : redirect()}
      <SortableTable
        columns={tableColumnsActionsWithDelete}
        data={MediaItems.map((item, index) => ({ ...item, index }))}
        modifyDataSourceOrder={modifyDataSourceOrder}
      />

      {!addMedia ? (
        <ButtonPrimary
          theme="round"
          text={ADD_MEDIA}
          iconLeft={<PlusOutlined />}
          onClick={() => setAddMedia(true)}
        />
      ) : (
        <MediaAdder
          tableColumns={tableColumnsActions}
          MediaItems={MediaItems.map((item) => item.id)}
          onAddMedia={mediaAddHandler}
          feedEndpoint={feedEndpoint}
          processFeedEndpointResults={processFeedEndpointResults}
        />
      )}
    </div>
  );
};

MediaStructureEditor.propTypes = {
  tableColumns: PropTypes.arrayOf(PropTypes.object).isRequired,
  MediaItems: PropTypes.arrayOf(PropTypes.object),
  onMediaAdd: PropTypes.func.isRequired,
  feedEndpoint: PropTypes.func.isRequired,
  removeMediaItem: PropTypes.func.isRequired,
  modifyDataSourceOrder: PropTypes.func,
  processFeedEndpointResults: PropTypes.func,
  returnUrl: PropTypes.string,
  mediaStructureId: PropTypes.string,
};

MediaStructureEditor.defaultProps = {
  MediaItems: [],
  processFeedEndpointResults: (data) => data,
  modifyDataSourceOrder: () => {},
  returnUrl: undefined,
  mediaStructureId: undefined,
};
export default MediaStructureEditor;
