/* eslint-disable no-unused-vars */
const login = () => (data) => ({ data: { loginOk: true } });

const getMediaList = () => () => ({});

const getS3UrlToUploadByExtension = () => (extension) => ({});

const getMediaOnS3ById = () => (data) => ({});

const postFileMetaDataOnDynamo = () => (data) => ({});

const startMultiPartUpload = () => (data) => ({});

const completeMultiPartUpload = () => (data) => ({});

const listParts = () => (data) => ({
  data: {
    Parts: [
      {
        ETag: 'testEtag',
        PartNumber: 1,
      },
      {
        ETag: 'testEtag',
        PartNumber: 2,
      },
      {
        ETag: 'testEtag',
        PartNumber: 3,
      },
    ],
  },
});

const abortUpload = () => (data) => ({});

const resumeMultiPart = () => (data) => ({
  data: {
    NextPartNumberMarker: 7,
    signedUrls: ['url1', 'url2'],
  },
});

const getMediaDetailsById = () => async (data) => ({
  data: {
    MediaId: 'test-media-id',
    captions: [
      {
        fileName: 'testCaptions1.vtt',
        isDefault: true,
        captionsUrl: 'https://s3url.for.test.file.com/',
      },
      {
        fileName: 'testCaptions2.vtt',
        isDefault: false,
        captionsUrl: 'https://s3url.for.test.file.com/',
      },
      {
        fileName: 'testCaptions3.vtt',
        isDefault: false,
        captionsUrl: 'https://s3url.for.test.file.com/',
      },
    ],
    fileKey: 'test-s3-id/test-fileName.mp4',
    owner: 'John Doe',
    posters: [],
    timeStamp: 1634148776115,
    description: '',
    ownerId: 'John Doe',
    title: 'test title',
  },
});

const getCollectionsList = () => (data) => ({});

const getCollectionEligibleMedia = () => (data) => ({});

const createCollection = () => (data) => ({});

const getCollectionById = () => async (collectionId) => ({
  data: {
    title: 'Collection title',
    Items: [
      { id: 'abc1', title: 'media title 1', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc2', title: 'media title 2', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc3', title: 'media title 3', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc4', title: 'media title 4', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc5', title: 'media title 5', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc6', title: 'media title 6', owner: 'John Doe', created: 165451, duration: 150 },
    ],
  },
});

const getPlaylistsList = () => (data) => ({});

const getPlaylistEligibleMedia = () => (data) => ({});

const createPlaylist = () => (data) => ({});

const getPlaylistById = () => async (playlistId) => ({
  data: {
    title: 'Playlist title',
    Items: [
      { id: 'abc1', title: 'media title 1', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc2', title: 'media title 2', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc3', title: 'media title 3', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc4', title: 'media title 4', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc5', title: 'media title 5', owner: 'John Doe', created: 165451, duration: 150 },
      { id: 'abc6', title: 'media title 6', owner: 'John Doe', created: 165451, duration: 150 },
    ],
  },
});

export default () => ({
  login: login(),
  getMediaList: getMediaList(),
  getS3UrlToUploadByExtension: getS3UrlToUploadByExtension(),
  getMediaOnS3ById: getMediaOnS3ById(),
  postFileMetaDataOnDynamo: postFileMetaDataOnDynamo(),
  startMultiPartUpload: startMultiPartUpload(),
  completeMultiPartUpload: completeMultiPartUpload(),
  listParts: listParts(),
  abortUpload: abortUpload(),
  resumeMultiPart: resumeMultiPart(),
  getMediaDetailsById: getMediaDetailsById(),
  getCollectionsList: getCollectionsList(),
  getCollectionEligibleMedia: getCollectionEligibleMedia(),
  createCollection: createCollection(),
  getPlaylistById: getPlaylistById(),
  getPlaylistsList: getPlaylistsList(),
  getPlaylistEligibleMedia: getPlaylistEligibleMedia(),
  createPlaylist: createPlaylist(),
});
