import helpers from './helpers';

const uploadFilesToS3 = (makePostFileRequest) => (url, data) => makePostFileRequest(url, data);

export default () => {
  const { makePostFileRequest } = helpers();
  return {
    uploadFilesToS3: uploadFilesToS3(makePostFileRequest),
  };
};
