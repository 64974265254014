import React from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import './_style.scss';

const { Title } = Typography;

const TitleImage = ({ title, title2, img, imgAlt }) => (
  <div className="TitleImageContainer">
    <img src={img} alt={imgAlt} />
    <div className="overlay">
      <Title level={1} className="Title">
        <p>
          {title}
          <br />
          {title2}
        </p>
      </Title>
    </div>
  </div>
);

TitleImage.propTypes = {
  title: PropTypes.string.isRequired,
  title2: PropTypes.string,
  img: PropTypes.node.isRequired,
  imgAlt: PropTypes.string,
};

TitleImage.defaultProps = {
  imgAlt: '',
  title2: undefined,
};

export default TitleImage;
