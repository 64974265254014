import React, { createContext, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SITE } from '../../utils/constants-utils';

const localState = JSON.parse(localStorage.getItem(SITE));

const initialState = {};

const reducer = (site, newSite) => {
  if (!newSite) {
    localStorage.removeItem(SITE);
    return initialState;
  }
  return { ...newSite };
};

const SiteContext = createContext({});

const SiteProvider = ({ children }) => {
  const [site, setSite] = useReducer(reducer, localState || initialState);

  useEffect(() => {
    localStorage.setItem(SITE, JSON.stringify(site));
  }, [site]);

  return <SiteContext.Provider value={{ site, setSite }}>{children}</SiteContext.Provider>;
};

SiteContext.propTypes = {
  children: PropTypes.node.isRequired,
};

SiteProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { SiteContext, SiteProvider };
