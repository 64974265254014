import React, { useContext, useEffect } from 'react';
import { Spin } from 'antd';
import {
  HELP_URL,
  HELP_LTI_URL,
  SITES_ADMIN_URL,
  AUTHZ_LIST_URL,
  AUTHZ_LIST_LTI_URL,
} from '../../../utils/constants-utils';
import { errorLogger } from '../../../utils/generic-utils';
import { useRedirect } from '../../router/redirect';
import { UserContext } from '../../../services/providers/user-context';
import { SiteContext } from '../../../services/providers/site-context';
import apiCalls from '../../../services/api-calls/all';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const LoginForm = (parameters) => {
  const { params } = parameters;
  const { setUser } = useContext(UserContext);
  const { setSite } = useContext(SiteContext);
  const { redirect, setUrlToRedirect } = useRedirect();
  const axiosPrivate = useAxiosPrivate();
  const { getUsersSiteList, initializeSites } = apiCalls(axiosPrivate);

  let userObject = {};

  useEffect(async () => {
    // check for sso userProfile
    const userProfile = localStorage.getItem('userProfile');
    if (
      userProfile &&
      Object.keys(userProfile).length &&
      Object.getPrototypeOf(userProfile) !== Object.prototype
    ) {
      // get user sso info
      const { sub } = JSON.parse(userProfile); // user email from sso field
      userObject = { accessToken: 'accessToken', userId: sub, userRole: '' };
      redirectToApplication(userObject, params);
    }
  }, []);

  const redirectToApplication = async (userObj) => {
    try {
      const { userId } = userObj;
      const { data } = await getUsersSiteList({
        queryAttribute: 'AsuriteId',
        queryValue: userId,
      });
      const platformAdminAuthz = data.Items.find((authz) => authz.SiteId.S === 'ALL');
      if (platformAdminAuthz) return redirectPlatformAdmin(userId);
      const activeSites = data.Items.filter((authz) => authz.Active.BOOL);
      if (activeSites.length === 0) return redirectToErrorPage();
      if (activeSites.length === 1) return redirectSingleSite(activeSites[0]);
      redirectMultipleSites(activeSites);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while searching authorizations:', error });
    }
  };
  const redirectPlatformAdmin = (userId) => {
    setUser({ accessToken: 'accessToken', userId, userRole: 'platformAdmin' });
    setUrlToRedirect(SITES_ADMIN_URL);
  };
  const redirectToErrorPage = () => {
    initializeSites();
    setUrlToRedirect('ERROR');
  };
  const redirectSingleSite = (authzItem) => {
    setUser({
      accessToken: 'accessToken',
      userId: authzItem.AsuriteId.S,
      userRole: authzItem.PlatformRole.S,
    });
    setSite({ SiteId: authzItem.SiteId.S, SiteName: authzItem.SiteName.S });
    if (params.isDeepLink === 'true') {
      setUrlToRedirect(HELP_LTI_URL);
    } else {
      setUrlToRedirect(HELP_URL);
    }
  };
  const redirectMultipleSites = (authorizations) => {
    // Shall we move this to other context? Now reusing site bc no site is selected at this point
    setSite([authorizations]);
    if (params.isDeepLink === 'true' || params.isLti === 'true') {
      setUrlToRedirect(AUTHZ_LIST_LTI_URL);
    } else {
      setUrlToRedirect(AUTHZ_LIST_URL);
    }
  };

  return (
    <div className="LoginForm">
      {redirect(params)}
      <Spin className="center" />
    </div>
  );
};

LoginForm.propTypes = {};

LoginForm.defaultProps = {};

export default LoginForm;
