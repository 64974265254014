import React from 'react';
import './_style.scss';
import ReactMarkdown from 'react-markdown';
import { Divider } from 'antd';
import { releaseNotesJson } from '../../../release-notes/release-notes';

const ReleaseNotesPage = () => {
  const releaseNotes = releaseNotesJson.map(({ title, text, key }) => (
    <section id={key}>
      <Divider orientation="left">{title}</Divider>
      <ReactMarkdown>{text}</ReactMarkdown>
    </section>
  ));
  return <div className="ReleaseNotesPageContainer">{releaseNotes}</div>;
};

export default ReleaseNotesPage;
