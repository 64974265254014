import React from 'react';
import { useRecoilValue } from 'recoil';
import { loggedInSelector } from './authn/userAtom';
import Layout from './components/layout/Layout';
import { AuthnCAS } from './authn/AuthnCAS';
import config from './authn/sso-config';
import { isPublicRoute } from './authn/api';

import './App.less';

const App = () => {
  const loggedIn = useRecoilValue(loggedInSelector);

  let content;
  const url = window.location.href;
  console.log('url = ' + url);
  try {
    const pub = isPublicRoute(url);
    console.log('return value from isPublicRoute() = ' + pub);
  } catch (error) {
    console.log('Error occurred in isPublicRoute: ' + error);
  }

  if (isPublicRoute(url) || loggedIn || !config.enableAuth) {
    content = <Layout />;
  } else {
    content = <AuthnCAS />;
  }
  return <>{content}</>;
};

export default App;