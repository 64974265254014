import React, { useContext } from 'react';
import CreateMediaStructure from '../../organisms/create-media-structure/create-media-structure';
import apiCalls from '../../../services/api-calls/all';
import { SiteContext } from '../../../services/providers/site-context';
import { UserContext } from '../../../services/providers/user-context';
import { playlistTableColumns } from '../../molecules/table-headers/playlist-items.table-header';
import { CREATE_NEW_PLAYLIST, PLAYLIST_TITLE, PLAYLISTS_URL } from '../../../utils/constants-utils';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const CreatePlaylistPage = () => {
  const { site } = useContext(SiteContext);
  const { user } = useContext(UserContext);
  const axiosPrivate = useAxiosPrivate();
  const { createPlaylist, getPlaylistEligibleMedia } = apiCalls(axiosPrivate);

  const processFeedEndpointResults = ({ data: { Items } }) =>
    Items.map((item) => ({
      key: item.MediaId,
      media_name: item.title,
      id: item.MediaId,
      ...item,
    }));

  const getPlaylistEligibleMediaEndpoint = (mediaItemsIds) =>
    getPlaylistEligibleMedia({
      siteId: site.SiteId,
      userId: user.userId,
      ...mediaItemsIds,
    });

  const createPlaylistEndpoint = ({ selectedMediaKeys, mediaStructureTitle }) =>
    createPlaylist({
      playlistItems: selectedMediaKeys,
      playlistTitle: mediaStructureTitle,
      ownerId: user.userId,
      siteId: site.SiteId,
    });

  return (
    <div className="CreatePlaylistPageContainer" tabIndex={0} aria-label="Create playlist page">
      <CreateMediaStructure
        title={CREATE_NEW_PLAYLIST}
        titleLabel={PLAYLIST_TITLE}
        returnUrl={PLAYLISTS_URL}
        endpoint={createPlaylistEndpoint}
        tableColumns={playlistTableColumns}
        feedEndpoint={getPlaylistEligibleMediaEndpoint}
        processFeedEndpointResults={processFeedEndpointResults}
      />
    </div>
  );
};

export default CreatePlaylistPage;
