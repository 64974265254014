import React from 'react';
import { Button } from 'antd';
import TitleImage from '../../molecules/title-image/title-image';
import BlankSection from '../../molecules/blank-section/blank-section';
import GenericCollapse from '../../atoms/generic-collapse/generic-collapse';
import BackgroundImage from '../../../images/help_page_background_img.png';
import { RELEASE_NOTES_URL, FAQ_URL } from '../../../utils/constants-utils';
import { releaseNotesJson } from '../../../release-notes/release-notes';
import { faqsJson } from '../../../faqs/faqs';
import { useRedirect } from '../../router/redirect';
import './_style.scss';

const HelpPage = () => {
  const { redirect, setUrlToRedirect } = useRedirect();
  return (
    <div className="HelpPageContainer" aria-label="Help page" tabIndex={0}>
      {redirect()}
      <div className="TitleSection">
        <TitleImage title="Welcome to" title2="ASU Media+" img={BackgroundImage} />
      </div>
      <div className="ContentSection">
        <div className="InfoSection">
          <BlankSection title="Release notes" theme="card">
            <GenericCollapse panels={releaseNotesJson} />
            <div className="text">
              <Button type="link" onClick={() => setUrlToRedirect(RELEASE_NOTES_URL)}>
                View all release notes
              </Button>
            </div>
          </BlankSection>
          <BlankSection title="Need help?" theme="card">
            <div className="filler">
              <p>
                Email: <br />
                <Button type="link" href="mailto:mediaplus@asu.edu" target="_blank">
                  mediaplus@asu.edu
                </Button>
              </p>
            </div>
          </BlankSection>
        </div>
        <div className="FAQSection">
          <BlankSection title="FAQ" theme="card">
            <GenericCollapse panels={faqsJson} />
            <div className="text">
              <Button type="link" onClick={() => setUrlToRedirect(FAQ_URL)}>
                View all FAQs
              </Button>
            </div>
          </BlankSection>
        </div>
      </div>
    </div>
  );
};

export default HelpPage;
