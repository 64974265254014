import React, { useEffect, useState, useContext } from 'react';
import { Typography, Input, Form, Button, message } from 'antd';
import GenericTable from '../../atoms/generic-table/generic-table';
import apiCalls from '../../../services/api-calls/all';
import { sitesColumns } from '../../molecules/table-headers/sites.table-header';
import GenericActions from '../../molecules/table-columns/generic-actions/generic-actions.table-columns';
import { useRedirect } from '../../router/redirect';
import { EditModeContext } from '../../../services/providers/edit-mode-context';
import {
  MANAGE_USERS_URL,
  EDIT,
  MANAGE_USERS,
  ACTIVATE,
  DEACTIVATE,
  UPDATE,
} from '../../../utils/constants-utils';
import { generateKeyFromString, errorLogger } from '../../../utils/generic-utils';
import SiteEditor from '../../molecules/site-editor/site-editor';
import useAxiosPrivate from '../../../services/api-calls/jwt-interceptor';
import './_style.scss';

const { Title } = Typography;
const SitesAdmin = () => {
  const [dataSource, setDataSource] = useState([]);
  const [tableLoading, setTableLoading] = useState();
  const [siteToEdit, setSiteToEdit] = useState(undefined);
  const [createSiteButtonDisabled, setCreateSiteButtonDisabled] = useState(false);
  const [siteId, setSiteId] = useState();
  const { redirect, setUrlToRedirect } = useRedirect();
  const { setEditMode } = useContext(EditModeContext);
  const axiosPrivate = useAxiosPrivate();
  const { getSitesList, updateSite, activateSite, deactivateSite, createSite, updateSiteSam } =
    apiCalls(axiosPrivate);

  useEffect(() => (siteToEdit ? setEditMode(true) : setEditMode(false)), [siteToEdit]);

  const searchSites = async () => {
    setTableLoading(true);
    try {
      const result = await getSitesList();
      const processedResult = result.data.Items.map((item) => ({
        site_name: item.SiteName,
        key: item.SiteId,
        active: item.Active,
        ...item,
      }));
      setDataSource(processedResult);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while geting sites list:', error });
    }
    setTableLoading(false);
  };

  useEffect(async () => {
    searchSites();
  }, []);

  const handleActivateSite = async (record) => {
    setTableLoading(true);
    try {
      await activateSite(record.key);
      await searchSites();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while activating site:', error });
      setTableLoading(false);
    }
  };

  const handleDeactivateSite = async (record) => {
    setTableLoading(true);
    try {
      await deactivateSite(record.key);
      await searchSites();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while deactivating site:', error });
      setTableLoading(false);
    }
  };

  const handleUpdateSite = async (record) => {
    setTableLoading(true);
    try {
      await updateSiteSam(record.key);
      await searchSites();
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while updating site:', error });
      setTableLoading(false);
    }
    message.success('Site updated successfully');
  };

  const handleCreateSite = async ({ name }) => {
    setTableLoading(true);
    setCreateSiteButtonDisabled(true);
    message.info('Your Site is being created and will be ready momentarily');
    try {
      await createSite(name);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while creating site:', error, notifyUser: false });
      setTableLoading(false);
    }
    message.success('Site created successfully');
    await searchSites();
    setCreateSiteButtonDisabled(false);
  };

  const handleFinishEdit = async (editedSite) => {
    setTableLoading(true);
    const updateSiteParameters = {
      siteId: siteToEdit.key,
      ...editedSite,
    };
    try {
      await updateSite(updateSiteParameters);
      await searchSites();
      setSiteToEdit(undefined);
    } catch (error) {
      errorLogger({ loggedMessage: 'Error while editing site:', error });
    }
    setTableLoading(false);
  };

  const manageUsers = (record) => {
    setSiteId(record);
    setUrlToRedirect(MANAGE_USERS_URL);
  };

  const actions = [
    {
      key: ACTIVATE,
      onClick: handleActivateSite,
      showField: 'active',
      showFunc: (value) => !value,
    },
    {
      key: DEACTIVATE,
      onClick: handleDeactivateSite,
      showField: 'active',
      showFunc: (value) => value,
    },
    {
      key: UPDATE,
      onClick: handleUpdateSite,
    },
    { key: EDIT, onClick: (site) => setSiteToEdit(site) },
    { key: generateKeyFromString(MANAGE_USERS), onClick: manageUsers },
  ];

  const tableColumns = [...sitesColumns, GenericActions({ actions })];

  return (
    <div>
      {siteId ? redirect({ id: siteId.key, name: siteId.site_name }) : redirect()}
      <div>
        <Title>Sites</Title>
      </div>
      <div>
        <GenericTable columns={tableColumns} data={dataSource} loading={tableLoading} />
      </div>
      {!siteToEdit ? (
        <div className="createSiteFormBody">
          <div className="formTitle">
            <p className="title">Create new site:</p>
          </div>
          <div className="createSiteForm">
            <Form
              name="createSiteform"
              labelCol={{ span: 2 }}
              wrapperCol={{ span: 16 }}
              onFinish={handleCreateSite}
              autoComplete="off"
            >
              <Form.Item
                label="name"
                name="name"
                labelAlign="left"
                rules={[{ required: true, message: 'Site name is required' }]}
              >
                <Input style={{ width: '50%' }} placeholder="Site name" />
              </Form.Item>
              <Form.Item wrapperCol={{ offset: 2, span: 16 }}>
                <Button type="primary" htmlType="submit" disabled={createSiteButtonDisabled}>
                  Create site
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <SiteEditor
          siteName={siteToEdit.SiteName}
          siteTargetVersion={siteToEdit.TargetSiteVersion}
          siteMediaAmpAccountNumber={siteToEdit.MediaAmpAccountNumber}
          onFinishEdit={(updatedSite) => handleFinishEdit(updatedSite)}
        />
      )}
    </div>
  );
};

export default SitesAdmin;
