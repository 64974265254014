import React, { useContext } from 'react';
import MediaUploader from '../../organisms/media-uploader/media-uploader';
import { UserContext } from '../../../services/providers/user-context';
import { SiteContext } from '../../../services/providers/site-context';
import './_style.scss';

const MediaUploadPage = () => {
  const { user } = useContext(UserContext);
  const { site } = useContext(SiteContext);
  return (
    <div className="UploadPageContainer">
      <div className="UploadPageTitleContainer">
        <p className="UploadPageTitle">Upload new media</p>
      </div>
      <div className="MediaUploaderContainer">
        <MediaUploader userId={user.userId} siteId={site.SiteId} />
      </div>
    </div>
  );
};

export default MediaUploadPage;
